export const RESOURCE_DETAILS = {
  url: 'resourceApi/resources/:resourceId',
  name: 'resourceDetails',
  method: 'GET',
  params: ['resourceId'],
  requiredFor: ['main.resources.resourceDetailEdit', 'main.resources.resourcesDetails'],
  forcedBackFor: {
    'main.resources.resourcesDetails': ['main.resources.resources'],
    'main.resources.resourceDetailEdit': ['main.resources.resourcesDetails']
  }
};

export const RESOURCE_GOOGLE_SYNC = {
  url: 'resourceApi/resource/googleSync/:resourceId',
  name: 'RESOURCE_GOOGLE_SYNC',
  method: 'GET',
  params: ['resourceId']
};

export const RESOURCE_ZOOM_SYNC = {
  url: 'zoomSettingApi/settings/:resourceId',
  name: 'RESOURCE_ZOOM_SYNC',
  method: 'GET',
  params: ['resourceId']
};

export const RESOURCE_OUTLOOK_SYNC = {
  url: 'resourceApi/resource/outLookSync/:resourceId',
  name: 'RESOURCE_OUTLOOK_SYNC',
  method: 'GET',
  params: ['resourceId']
};

export const SCHEDULE_RESOURCE_LIST = {
  url: 'resourceApi/resources?stats=yes&',
  name: 'resources',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    project: ["images",
      "avatar",
      "first_name",
      "middle_name",
      "last_name",
      "last_updated",
      "bz_add",
      "pri_phone",
      "org_details.manager_details",
      "org_details.organizations.primary",
      "org_details.organizations.secondary",
      "org_details.emp_type",
      "customers",
      "assignedDashboards",
      "totalDashboards",
      "assignedJobs",
      "totalJobs",
      "assignedOpportunities",
      "totalOpportunities",
      "assignedQuotes",
      "totalQuotes",
      "possibleAllocationServices",
      "assignedServices",
      "assignedVisits",
      "resourceUtilization"
    ],
    defaultSort: [{
      field: "firstName",
      order: 1
    }]
  },
  pagination: 'SCHEDULE_RESOURCE_LIST',
  requiredFor: ['main.resources.scheduling']
};

export const RESOURCE_LIST = {
  url: 'resourceApi/resources?stats=yes&',
  name: 'resources',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    project: ["images",
      "avatar",
      "email",
      "first_name",
      "middle_name",
      "last_name",
      "last_updated",
      "bz_add",
      "pri_phone",
      "org_details.manager_details",
      "org_details.organizations.primary",
      "org_details.organizations.secondary",
      "org_details.emp_type",
      "customers",
      "assignedDashboards",
      "totalDashboards",
      "assignedJobs",
      "totalJobs",
      "assignedOpportunities",
      "totalOpportunities",
      "assignedQuotes",
      "totalQuotes",
      "possibleAllocationServices",
      "assignedServices",
      "assignedVisits",
      "resourceUtilization"
    ],
    defaultSort: [{
      field: "firstName",
      order: 1
    }]
  },
  pagination: 'RESOURCE_LIST',
  requiredFor: ['main.resources.resources']
};

export const RESOURCE_LIST_F = {
  url: 'resourceApi/resources',
  name: 'resourcesF',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    sort: [{
      field: "firstName",
      order: 1
    }],
    project: ['images', 'avatar', 'first_name', 'middle_name', 'last_name', 'org_details.organizations.primary', 'org_details.organizations.secondary']
  }
};

export const SCHEDULING_LIST_QUOTE_SERVICE = {
  url: 'resourceApi/scheduleTask?type=tasks&',
  name: 'quoteScheduleService',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      }, {
        field: 'isMaster',
        operator: '!=',
        value: true
      }, {
        field: 'isJob',
        operator: '=',
        value: false
      }, {
        field: 'startTime',
        operator: 'dtrange',
        value: []
      },
      // {
      //   field: 'status',
      //   operator: 'in',
      //   value: [10, 11, 20, 25, 30, 42, 50, 55]
      // }
    ],
    defaultSort: [{
      field: "startTime",
      order: -1
    }],
  },
  pagination: 'SERVICE_SCHEDULING_LIST',
  requiredFor: ['main.resources.compare']
};

export const SCHEDULING_LIST_JOB_SERVICE = {
  url: 'resourceApi/scheduleTask?type=tasks&',
  name: 'jobScheduleService',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      }, {
        field: 'isMaster',
        operator: '!=',
        value: true
      }, {
        field: 'isJob',
        operator: '=',
        value: true
      },
      // {
      //   field: 'status',
      //   operator: 'in',
      //   value: [10, 11, 20, 25, 30, 40, 42, 45, 49, 50, 55, 75]
      // }
    ],
    defaultSort: [{
      field: "startTime",
      order: -1
    }],
  },
  pagination: 'SERVICE_SCHEDULING_LIST',
  requiredFor: ['main.resources.compare']
};
export const SCHEDULING_LIST_VISIT = {
  url: 'resourceApi/scheduleTask?type=onsites&',
  name: 'scheduleVisit',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  pagination: 'VISIT_SCHEDULING_LIST',
  requiredFor: ['main.resources.compare']
};

// export const FOLDER_LIST = {
//   url: 'actionFolderApi/actionFolder',
//   name: 'FOLDER_LIST',
//   method: 'GET',
//   filterCriteria: {
//     filters: [{
//       field: "hide",
//       operator: "=",
//       value: false
//     }],
//     sort: [{
//       field: "seqId",
//       order: 1
//     }]
//   }
// };
export const FOLDER_LIST = {
  url: 'actionFolderApi/actionFolder',
  name: 'FOLDER_LIST',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: "seqId",
      order: 1
    }]
  }
};

export const INACTIVE_FOLDER_LIST = {
  url: 'actionFolderApi/actionFolder',
  name: 'INACTIVE_FOLDER_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hide",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "lastUpdated",
      order: -1
    }]
  }
};

export const GET_FUNCTION = {
  url: 'actionsApi/action/getFunctions',
  name: 'getFunction',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'modelName',
      operator: '=',
      value: 20
    }, {
      field: 'location',
      operator: '=',
      value: 10000
    }]
  }
};

export const GET_FUNCTIONS = {
  url: 'actionsApi/action/getFunctions',
  name: 'getFunctions',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'modelName',
      operator: '=',
      value: 21
    }, {
      field: 'location',
      operator: '=',
      value: 10000
    }]
  },
  requiredFor: ['main.resources.action']
};

// Passed for nav
export const RESOURCE_HISTORY = {
  url: 'activityApi/activity',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'createdBy',
      operator: '='
    }, {
      field: 'activity_type',
      operator: '=',
      value: 'general'
    }],
    skip: 0,
    limit: 15
  },
  pagination: 'ACTIVITY_LIST',
  filterValue: ['resourceId'],
  requiredFor: ['main.resources.resourcesActivities']
};

export const RESOURCE_ACTION_LIST = {
  url: 'actionsApi/action/list/details?folder=:folderId&',
  name: 'actionActive',
  method: 'GET',
  params: ['folderId'],
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      },
      {
        field: "status",
        value: 1,
        operator: "="
      }, {
        field: "is_system",
        value: false,
        operator: "="
      }
    ],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'ACTION_LIST',
  requiredFor: ['main.resources.action']
};

export const RESOURCE_ACTION_LIST_PIPELINE = {
  url: 'actionsApi/action/list/details?folder=:folderId&',
  name: 'actionActive',
  method: 'GET',
  params: ['folderId'],
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      }, {
        field: "status",
        value: 1,
        operator: "="
      },
      {
        field: "is_system",
        value: false,
        operator: "="
      }
    ],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  requiredFor: ['main.resources.actionPipeline']
};

export const actionPipelineSettings = {
  url: 'actionFolderApi/action/items/position',
  name: 'actionPipelineSettings',
  method: 'GET'
};

export const WORKBOARD_ACTION_LIST = {
  url: 'actionsApi/action/list/details?',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      },
      {
        field: "status",
        value: 1,
        operator: "="
      }
    ],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'ACTION_LIST',
  requiredFor: ['main.resources.action']
};

export const ACTION_LIST_FOLDER_DATA = {
  url: 'actionsApi/action/list/detail/:id',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: "is_system",
      value: true,
      operator: "!="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'ACTION_LIST',
  // requiredFor: ['main.resources.action']
  params: ['id']
};

export const ACTION_LIST_COUNT = {
  url: 'actionsApi/action/folder/overall/stat?folder=:folderId&',
  name: 'ACTION_LIST_COUNT',
  method: 'GET',
  params: ['folderId']
};

export const ACTION_LIST_COUNT_PER_FOLDER = {
  url: 'actionsApi/action/folder/overall/stat/:id',
  name: 'ACTION_LIST_COUNT',
  method: 'GET',
  params: ['id']
};

export const FOLDER_DETAIL = {
  url: 'actionFolderApi/actionFolder/detail/:id',
  name: 'FOLDER_DETAIL',
  method: 'GET',
  params: ['id']
};

export const STATISTICS_DETAIL = {
  url: 'actionsApi/action/overall/stat',
  name: 'STATISTICS_DETAIL',
  method: 'GET'
};

export const PRIORITY_DISTRIBUTION = {
  url: 'actionsApi/action/pie/stat/priority',
  name: 'PRIORITY_DISTRIBUTION',
  method: 'GET'
};

export const QUOTE_DETAIL = {
  url: "quoteApi/quotes/:quoteId",
  name: "QUOTE_DETAIL",
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.resources.compareSquoteDetail', 'main.resources.compareSjobDetail']
};

export const ALL_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'ALL_ORGANIZATION',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: 'orgName',
      order: 1
    }]
  }
};
export const CUSTOM_FILTER_ACTION = {
  url: 'filterSettingsApi/filter/settings',
  name: 'CUSTOM_FILTER_ACTION',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'category',
      operator: '=',
      value: 40
    }]
  }
};

export const businessGroup = {
  url: 'businessGroupApi/business/group',
  method: 'GET',
  name: 'businessGroup',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15,
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  onlyPagination: true,
  getSavedSettings: true,
  requiredFor: ['main.customers.business']
};

export const allBusinessGroup = {
  url: 'businessGroupApi/business/group',
  method: 'GET',
  name: 'businessGroup',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
};

export const businessCategory = {
  url: 'businessCategoriesApi/business/contact/category?stats=true&',
  name: 'businessCategory',
  method: 'GET'
};

export const businessContactList = {
  url: 'businessContactApi/business/contacts/:groupId',
  method: 'GET',
  name: 'businessContactList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15,
    defaultSort: [{
      field: 'firstName',
      order: 1
    }]
  },
  pagination: 'BUSINESS_CONTACT_LIST',
  params: ['groupId']
};

export const groupDetail = {
  url: 'businessGroupApi/business/group/:groupId',
  name: 'groupDetail',
  method: 'GET',
  params: ['groupId']
};

export const businessContactDetail = {
  url: 'businessContactApi/business/contact/:groupId/:id',
  name: 'businessContactDetail',
  method: 'GET',
  params: ['groupId', 'id']
};

export const BUSINESS_CONTACT_CUSTOM_FORM = {
  url: 'customFieldsApi/customFields?',
  name: 'BUSINESS_CONTACT_CUSTOM_FORM',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      operator: "=",
      value: 'business_contact_form'
    }, {
      field: "status",
      value: 2,
      operator: "="
    }]
  }
};
export const businessDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'businessDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 5,
      operator: "="
    }]
  },
  filterValue: ['id'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const documentList = {
  url: 'assocDocApi/assoc/docs/list',
  name: 'documentList',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelName",
      value: 1,
      operator: "="
    }]
  },
  pagination: 'TEAMS_DOCUMENT_LIST'
};

export const documentDetail = {
  url: 'assocDocApi/assoc/doc/:docId',
  name: 'documentDetail',
  method: 'GET',
  params: ['docId']
};

export const businessContactHistory = {
  url: 'activityApi/activity',
  name: 'productivity',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'model_id',
      operator: '='
    }, {
      field: 'model',
      operator: '=',
      value: 'business_contact'
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id'],
};

export const businessContactCommunication = {
  url: 'businessContactApi/business/contacts/communication/:id?stats=yes&',
  method: 'GET',
  name: 'communications',
  params: ['id'],
  filterCriteria: {
    filters: [{
      field: 'active',
      operator: '=',
      value: true
    }],
    defaultSort: [{
      field: 'updatedAt',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const businesContactEmailTemplates = {
  url: 'emailTemplateApi/email/template',
  name: 'businesContactEmailTemplates',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelName",
      operator: "=",
      value: 33
    }, {
      field: "status",
      operator: "=",
      value: 2
    }],
    sort: [{
      field: "name",
      order: 1
    }]
  }
};

export const engageContactDetail = {
  url: 'businessContactApi/business/contacts/submit/:id',
  name: 'engageContactDetail',
  method: 'GET',
  params: ['id']
};

export const clockInDetail = {
  url: 'timeTrackingApi/track/clockIn/:addressId/:quoteId/:serviceId?resourceId=:resourceId&resourceType=:resourceType&',
  name: 'clockInDetail',
  method: 'GET',
  params: ['addressId', 'quoteId', 'serviceId', 'resourceId', 'resourceType']
};

export const clockTrackerDetail = {
  url: 'timeTrackingApi/agg/task/:quoteId/:serviceId',
  name: 'clockTrackerDetail',
  method: 'GET',
  params: ['quoteId', 'serviceId'],
  requiredFor: ['main.resources.compareTrackerDetail']
};

export const clockedServices = {
  url: 'timeTrackingApi/agg/view/tasks',
  name: 'clockedServices',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "startTime",
      order: -1
    }]
  },
  pagination: 'CLOCKED_TIME_PER_SERVICE_LIST',
  requiredFor: ['main.resources.compareTracker']
};

export const clockedResources = {
  url: 'timeTrackingApi/agg/view/resources',
  name: 'clockedServices',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "startTime",
      order: -1
    }]
  },
  pagination: 'RESOURCE_TRANSACTION_LIST',
  requiredFor: ['main.resources.compareResourcetracker']
};