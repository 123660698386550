import settingsFrameTemplate from './settings-frame/settings-frame.html';

import viewEntitiesTemplate from './setup/entities/view/view-entities.html';
import updateEntitiesTemplate from './setup/entities/update/update-entities.html';
import locationsListTemplate from './setup/locations/locations-list.html';
import viewLocationTemplate from './setup/locations/view/view-location.html';
import updateLocationTemplate from './setup/locations/update/update-location.html';
import regionsListTemplate from './setup/locations/regions/regions-list.html';
import viewInvoicesTemplate from './setup/invoices/view/view-invoices.html';
import updateInvoicesTemplate from './setup/invoices/update/update-invoices.html';
import viewWorkordersTemplate from './setup/workorders/view/view-workorders.html';
import updateWorkorderTemplate from './setup/workorders/update/update-workorder.html';
import detailsProfileTemplate from './setup/profile/profile-details.html';
import rolesTemplate from './setup/permissions/roles/roles.html';
import updateRoleTemplate from './setup/permissions/update-role/update-role.html'
import communicationTemplate from './structure/communication/communication.html';
import smsSetupTemplate from './structure/communication/sms-setup/sms-setup.html';
import smsTemplateSetupTemplate from './structure/communication/sms-template-setup/sms-template-setup.html';
import servicesTemplate from './structure/services/services.html';
import updateSowTemplate from './structure/services/update-sow/update-sow.html';
import serviceRatesTemplate from './structure/service-rates/serviceRates.html';
import globalLabelsTemplate from './structure/global-labels/global-labels.html';
import promotionsTemplate from './structure/promotions/promotions.html';
import promotionsListViewTemplate from './structure/promotions/list-view/promotionsListView.html';
import promotionViewTemplate from './structure/promotions/view-promotion/promotionView.html';
import updatePromotionTemplate from './structure/promotions/update-promotion/promotionUpdate.html';
import policyDetailTemplate from './structure/policies/policy-detail/PolicyDetail.html';
import policiesListViewTemplate from './structure/policies/policies-list/PoliciesListView.html';
import addPoliciesTemplate from './structure/policies/add-policies/PoliciesAdd.html';
import attributePolicyDetailTemplate from './structure/policies/attribute-policy/attribute-policy-detail/attribute-policy-detail.html'
import updateAttributePolicyTemplate from './structure/policies/attribute-policy/update-attribute-policy/update-attribute-policy.html';
import commonTagsManagement from "./structure/tag-management/tag-management.html";
import editTagManagement from "./structure/edit-tag-management/editTagManagement.html";
import customFormsTemplate from './structure/custom-forms-builder/custom-forms/custom-forms.html';
import updateCustomFormsTemplate from './structure/custom-forms-builder/update-custom-forms/update-custom-forms.html';
import freedomFormsTemplate from './structure/custom-forms-builder/freedom-forms/freedom-forms.html';
import addPaymentPolicyTemplate from './structure/policies/payment-policy/payment-policy.html';
import viewPaymentPolicyTemplate from './structure/policies/payment-details/payment-details.html';
import addTrackingPolicyTemplate from './structure/policies/add-tracking-policy/add-tracking-policy.html';
import viewTrackingPolicyTemplate from './structure/policies/view-tracking-policy/view-tracking-policy.html';
import addPayPolicyTemplate from './structure/policies/add-pay-policy/add-pay-policy.html';
import viewPayPolicyTemplate from './structure/policies/view-pay-policy/view-pay-policy.html';
import paymentGatewayListTemplate from './structure/banking/paymentGateway-list/paymentGateway-list.html';
import transactionListTemplate from './structure/banking/transactions/transactionsList.html';
import viewRegionTemplate from './structure/banking/viewRegion/viewRegion.html';
import updateRegionTemplate from './structure/banking/updateRegion/updateRegion.html';
import allTransactionsTemplate from './structure/banking/allTransactions/allTransaction.html';
import addBrainTreeTemplate from './structure/banking/addBrainTree/addBrainTree.html';
import addFirstDataTemplate from './structure/banking/addFirstData/addFirstData.html';
import updateWarrantyPolicyTemplate from './structure/policies/warranty-policy/updateWarrantyPolicy.html';
import warrantyPolicyDetailsTemplate from './structure/policies/warranty-details/warranty-details.html';
import deliveryZoneListTemplate from './structure/delivery-zones/deliveryZonesList.html';
import addDeliveryZoneTemplate from './structure/delivery-zones/update-zone/updateZone.html';
import detailZoneTemplate from './structure/delivery-zones/detail-zone/detailZone.html';
import addDeliveryMethodTemplate from './structure/delivery-zones/update-method/addMethod.html';
import detailMethodTemplate from './structure/delivery-zones/detail-method/detailMethod.html';
import deliveryCalculatorTemplate from './structure/delivery-zones/delivery-calculator/deliveryCalculator.html';
import addQuickBookAccountTemplate from './structure/banking/paymentGateway-list/add-quick-book-account/addQuickBookAccount.html';
import dataSetsTemplate from './structure/data-sets/data-sets.html';
import exportDataTemplate from './structure/export-data/export-data.html';
import updateExportDataSetupTemplate from './structure/export-data/update/update-export-data-setup.html';
import viewAliasesTemplate from './structure/aliases/view-aliases.html';
import updateAliasesTemplate from './structure/aliases/update/update-aliases.html';

import notificationsTemplate from './automation/notifications/list/notifications-list.html';
import updateNotificationTemplate from './automation/notifications/update/notification-update.html';
import viewNotificationTemplate from './automation/notifications/view/notification-view.html';
import guidedActionPlansTemplate from './automation/guided-actions/grid/guided-actions-grid.html';
import guidedActionListTemplate from './automation/guided-actions/list/guided-action-list.html';
import guidedActionPlanViewTemplate from './automation/guided-actions/view/guided-action-view.html'
import updateGuidedActionPlanTemplate from './automation/guided-actions/update/update-guided-action.html';
import guidedActionItemViewTemplate from './automation/guided-actions/action-item-view/guided-action-item-view.html';
import guidedActionItemUpdateTemplate from './automation/guided-actions/action-item-update/guided-action-item-update.html';
import applicationListTemplate from './automation/application-list/applicationList.html';
import updateWebConversionTemplate from './automation/application-list/web-conversion/update-web-conversion/updateWebConversion.html';
import webConversionDetailTemplate from './automation/application-list/web-conversion/webConversionDetail.html';

import updateTaxCategoryTemplate from './taxes/tax-category/update-category/updateCategory.html';
import updateClassificationTemplate from './taxes/tax-classification/update-classififcation/updateClassification.html';
import taxZoneListTemplate from './taxes/taxZoneList.html';
import updateTaxZoneTemplate from './taxes/tax-zones/update-tax-zone/updateTaxZone.html';
import taxZoneDetailTemplate from './taxes/tax-zones/detail-tax-zone/taxZoneDetail.html';
import taxZoneMethodUpdateTemplate from './taxes/tax-zones/detail-tax-zone/method/update-method/updateMethod.html';
import taxZoneMethodDetailTemplate from './taxes/tax-zones/detail-tax-zone/method/taxZoneMethodDetail.html';
import quickbookApiIntegrationTemplate from './structure/banking/paymentGateway-list/add-quick-book-account/quick-book-constent/quick-book-integration.html';
import maintenanceTemplate from './setup/maintenance/maintenance.html';
import apiKeyListTemplate from './automation/api-key-setup/apiKeyList.html';
import updateApiKeyTemplate from './automation/api-key-setup/update-api-key/updateApiKey.html';
import detailApiKeyTemplate from './automation/api-key-setup/detail-api-key/detailApiKey.html';
import apiKeyAccessLogTemplate from './automation/api-key-setup/api-key-activity/apiKeyActivity.html';
import updateSerialNumberTemplate from './structure/policies/serial-number/update-serial-number/update-serial-number.html';
import serialNumberDetailTemplate from './structure/policies/serial-number/serial-number-detail/serial-number-detail.html';

import {
  SALES_ORGANIZATION
} from '../quotes/quote.resolve.obj';

import {
  owner,
  entities,
  LOCATIONS_LIST,
  LOCATION,
  REGIONS_LIST,
  invoices,
  workorders,
  workorder,
  emailSetups,
  emailBrandings,
  EMAIL_TEMPLATES,
  ASSIGNMENT_NOTIFICATIONS,
  emailProviders,
  calSettings,
  // bizProfile,
  fetchTemplates,
  listBizServices,
  listScopeOfWork,
  scopeOfWork,
  listReasonTypes,
  exportDataSets,
  exportDataSet,
  aliases,
  behaviourSettings,
  listServiceRates,
  promotionListNotArchived,
  fetchPromotion,
  policiesList,
  fetchPolicy,
  POLICY_PRODUCTS,
  fetchAttributePolicy,
  mediaTagsList,
  regions,
  GUIDED_ACTION_LIST,
  NOTIFICATIONS_LIST,
  NOTIFICATION,
  GUIDED_ACTION,
  GUIDED_ACTION_PLAN_HISTORY,
  GUIDED_ACTION_RULES,
  GUIDED_ACTION_RULE,
  RESOURCE_LIST,
  ROLE,
  CLONE_ROLE,
  GUIDED_ACTION_RULE_HISTORY,
  EXPOSED_KEYS,
  EXPOSED_KEYS_DUE_DATE,
  EXPOSED_KEYS_TOKEN,
  ALL_ORGANIZATION,
  // RES_TYPE,
  PaymentPolicys,
  TrackingPolicy,
  PayPolicy,
  serviceRatesHistory,
  LIST_CUSTOM_FORM,
  CUSTOM_FORM_DETAIL,
  CUSTOM_FORM_TYPE_LIST,
  LIST_FREEDOM_FORM,
  attributeHistory,
  recurringPlanHistory,
  promotionHistory,
  paymentGatewayList,
  warrantyDetails,
  transactionLists,
  detailsRegion,
  allTransaction,
  DELIVERY_ZONE_LIST,
  DELIVERY_ZONE_DETAIL,
  DELIVERY_METHOD_LIST,
  DELIVERY_METHOD_DETAIL,
  ACTIVE_DELIVERY_METHOD_LIST,
  TAX_CATEGORY,
  CATEGORY_DETAILS,
  TAX_CLASSIFICATION,
  CLASSIFICATION_DETAILS,
  TAX_ZONE_LIST,
  TAX_ZONE_DETAILS,
  TAX_CODE_LIST,
  ZONE_METHOD_DETAIL,
  ZONE_METHOD_LIST,
  EXEMPT_CUSTOMER_LIST,
  METHOD_CLASSIFICATION_DETAIL,
  METHOD_CALCULATION_DETAIL,
  FETCH_INBOX_ID,
  OPPORTUNITY_BOARD,
  webConversionList,
  webConversionDetail,
  webConversionActivity,
  accountListQB,
  QB_ACCOUNT_DETAIL,
  ACCOUNT_AUTHENTICATE_DETAIL,
  SETTING_LOCATION,
  NEW_ACCOUNT_SETTING_LOCATION,
  REMOTE_LOGIN_DETAIL,
  exposedApiKeyList,
  apiKeyDetail,
  apiSetup,
  accessLog,
  smsSetup,
  smsTemplate,
  SERIAL_NUMBER_ATTACH_PRODUCTS,
  fetchSerialNumberDetail

} from './settings.resolve.obj';

import {
  CUSTOMER_LIST
} from '../customers/customers.resolve.obj';

export default function settingsRoute($urlRouterProvider, $stateProvider, settingsResolverProvider, resolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.settings', resolver('settings', settingsFrameTemplate, 'SettingsFrameController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule]
    }, {
      cbac: 'settings'
    }))

    .state('main.settings.setupProfileDetails', resolver('profile-details', detailsProfileTemplate, 'DetailProfileController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [owner]
    }, {
      cbac: 'setting_profile'
    }))

    .state('main.settings.setupEntitiesView', resolver('view-entities', viewEntitiesTemplate, 'ViewEntitiesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [entities]
    }, {
      cbac: 'setting_profile_entities'
    }))

    .state('main.settings.updateEntities', resolver('update-entities', updateEntitiesTemplate, 'UpdateEntitiesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [entities]
    }, {
      cbac: 'setting_profile_entities',
      isWrite: true
    }))

    .state('main.settings.setupLocations', resolver('locations', locationsListTemplate, 'LocationsListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [LOCATIONS_LIST]
    }, {
      cbac: 'setting_profile_locations'
    }))

    .state('main.settings.setupLocationsView', resolver('locations/:id', viewLocationTemplate, 'ViewLocationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [LOCATION],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'setting_profile_locations'
    }))

    .state('main.settings.addLocation', resolver('add-location', updateLocationTemplate, 'UpdateLocationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [LOCATION.name],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'setting_profile_locations',
      isWrite: true
    }))

    .state('main.settings.updateLocation', resolver('update-location/:id', updateLocationTemplate, 'UpdateLocationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [LOCATION],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'setting_profile_locations',
      isWrite: true
    }))

    .state('main.settings.setupLocationsRegions', resolver('regions', regionsListTemplate, 'RegionsListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [REGIONS_LIST]
    }, {
      cbac: 'setting_profile_locations'
    }))

    .state('main.settings.setupInvoicesView', resolver('view-invoices', viewInvoicesTemplate, 'ViewInvoicesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [invoices]
    }, {
      cbac: 'setting_profile_invoices'
    }))

    .state('main.settings.updateInvoices', resolver('update-invoices', updateInvoicesTemplate, 'UpdateInvoicesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [invoices],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'setting_profile_invoices',
      isWrite: true
    }))

    .state('main.settings.setupWorkordersView', resolver('view-work-orders', viewWorkordersTemplate, 'ViewWorkordersController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [workorders]
    }, {
      cbac: 'setting_profile_work_order'
    }))

    .state('main.settings.updateWorkorder', resolver('update-work-order/:id', updateWorkorderTemplate, 'UpdateWorkorderController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [workorder],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'setting_profile_work_order',
      isWrite: true
    }))

    .state('main.settings.setupPermissions', resolver('roles', rolesTemplate, 'RolesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [RESOURCE_LIST]
    }, {
      cbac: 'setting_profile_permissions'
    }))

    .state('main.settings.cloneRole', resolver('clone-role/:businessId/:id', updateRoleTemplate, 'UpdateRoleController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [CLONE_ROLE]
    }, {
      cbac: 'setting_profile_permissions',
      isWrite: true
    }))

    .state('main.settings.updateRole', resolver('update-role/:businessId/:id', updateRoleTemplate, 'UpdateRoleController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [ROLE]
    }, {
      cbac: 'setting_profile_permissions'
      // isWrite: true
    }))

    .state('main.settings.setupMaintenance', resolver('maintenance', maintenanceTemplate, 'MaintenanceController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [REMOTE_LOGIN_DETAIL]
    }, {
      cbac: 'setting_profile_maintenance'
    }))

    .state('main.settings.structureCommunication', resolver('communication', communicationTemplate, 'CommunicationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [emailSetups, emailBrandings, EMAIL_TEMPLATES, ASSIGNMENT_NOTIFICATIONS, emailProviders, EXPOSED_KEYS_TOKEN, calSettings],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadQuillEditor, resolverProvider.loadClipboard]
    }, {
      cbac: 'setting_structure_communications'
    }))

    .state('main.settings.addSms', resolver('add-sms', smsSetupTemplate, 'SmsSetupController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [smsSetup.name]
    }, {
      cbac: 'setting_structure_communications',
      isWrite: true
    }))

    .state('main.settings.updateSms', resolver('update-sms/:id', smsSetupTemplate, 'SmsSetupController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [smsSetup]
    }, {
      cbac: 'setting_structure_communications',
      isWrite: true
    }))

    .state('main.settings.addSmsTemplate', resolver('add-sms-template', smsTemplateSetupTemplate, 'SmsTemplateSetupController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [smsTemplate.name],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'setting_structure_communications',
      isWrite: true
    }))

    .state('main.settings.updateSmsTemplate', resolver('update-sms-template/:id', smsTemplateSetupTemplate, 'SmsTemplateSetupController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [smsTemplate],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'setting_structure_communications',
      isWrite: true
    }))

    .state('main.settings.automationApplications', resolver('application-list', applicationListTemplate, 'ApplicationListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [FETCH_INBOX_ID, webConversionList],
      loadOnDemand: [resolverProvider.loadClipboard]
    }, {
      cbac: 'setting_automation_applications'
    }))

    .state('main.settings.automationAccess', resolver('api-key-list', apiKeyListTemplate, 'ApiKeyListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [exposedApiKeyList]
    }, {
      cbac: 'setting_automation_api_access'
    }))

    .state('main.settings.addApiKey', resolver('add-api-key', updateApiKeyTemplate, 'UpdateApiKeyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [apiKeyDetail.name, apiSetup.name]
    }, {
      cbac: 'setting_automation_api_access'
    }))

    .state('main.settings.automationAccessDetail', resolver('detail-api-key/:id/:apiKey', detailApiKeyTemplate, 'DetailApiKeyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [apiKeyDetail, apiSetup],
      loadOnDemand: [resolverProvider.loadClipboard]
    }, {
      cbac: 'setting_automation_api_access'
    }))

    .state('main.settings.automationAccessLogs', resolver('activity-api-key/:id/:apiKey', apiKeyAccessLogTemplate, 'ApiKeyAccessLogController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [accessLog]
    }, {
      cbac: 'setting_automation_api_access'
    }))

    .state('main.settings.updateApiKey', resolver('update-api-key/:id/:apiKey', updateApiKeyTemplate, 'UpdateApiKeyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [apiKeyDetail, apiSetup],
      loadOnDemand: [resolverProvider.loadClipboard]
    }, {
      cbac: 'setting_automation_api_access'
    }))

    .state('main.settings.addWebConversion', resolver('add-web-conversion', updateWebConversionTemplate, 'UpdateWebConversionController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [OPPORTUNITY_BOARD, SALES_ORGANIZATION, webConversionDetail.name]
    }, {
      cbac: 'setting_automation_applications',
      isWrite: true
    }))

    .state('main.settings.updateWebConversion', resolver('update-web-conversion/:id', updateWebConversionTemplate, 'UpdateWebConversionController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [OPPORTUNITY_BOARD, SALES_ORGANIZATION, webConversionDetail]
    }, {
      cbac: 'setting_automation_applications',
      isWrite: true
    }))

    .state('main.settings.automationApplicationsWebConversionDetail', resolver('web-conversion-detail/:id', webConversionDetailTemplate, 'WebConversionDetailController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [webConversionDetail, webConversionActivity]
    }, {
      cbac: 'setting_automation_applications'
    }))

    .state('main.settings.automationNotifications', resolver('notifications', notificationsTemplate, 'NotificationsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [NOTIFICATIONS_LIST]
    }, {
      cbac: 'setting_automation_notifications'
    }))

    .state('main.settings.addNotification', resolver('add-notification', updateNotificationTemplate, 'NotificationUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [NOTIFICATION.name, EXPOSED_KEYS, EXPOSED_KEYS_DUE_DATE, EXPOSED_KEYS_TOKEN],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadClipboard]
    }, {
      cbac: 'setting_automation_notifications',
      isWrite: true
    }))

    .state('main.settings.updateNotification', resolver('update-notification/:id', updateNotificationTemplate, 'NotificationUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [NOTIFICATION, EXPOSED_KEYS, EXPOSED_KEYS_DUE_DATE, EXPOSED_KEYS_TOKEN],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadClipboard]
    }, {
      cbac: 'setting_automation_notifications',
      isWrite: true
    }))

    .state('main.settings.automationNotificationsView', resolver('view-notification/:id', viewNotificationTemplate, 'NotificationViewController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [NOTIFICATION, EXPOSED_KEYS, EXPOSED_KEYS_DUE_DATE]
    }, {
      cbac: 'setting_automation_notifications'
    }))

    .state('main.settings.structureServices', resolver('services?tabId', servicesTemplate, 'ServicesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [listBizServices, listScopeOfWork, ALL_ORGANIZATION]
    }, {
      cbac: 'setting_structure_quote_services'
    }))

    .state('main.settings.addSow', resolver('add-sow', updateSowTemplate, 'UpdateSowController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [scopeOfWork.name]
    }, {
      cbac: 'setting_structure_quote_services',
      isWrite: true
    }))

    .state('main.settings.updateSow', resolver('update-sow/:id', updateSowTemplate, 'UpdateSowController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [scopeOfWork]
    }, {
      cbac: 'setting_structure_quote_services',
      isWrite: true
    }))

    .state('main.settings.structureServiceRates', resolver('service-rates?tabId', serviceRatesTemplate, 'ServiceRatesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [listServiceRates, serviceRatesHistory]
    }, {
      cbac: 'setting_structure_quote_service_rates'
    }))

    .state('main.settings.structureLabels', resolver('labels?tabId', globalLabelsTemplate, 'GlobalLabelsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [listBizServices]
    }, {
      cbac: 'setting_structure_quote_services'
    }))
    
    .state('main.settings.automationPromotions', resolver('promotions', promotionsTemplate, 'PromotionsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [promotionListNotArchived]
    }, {
      cbac: 'setting_structure_promotions'
    }))

    .state('main.settings.automationPromotionsListView', resolver('promotions-list-view', promotionsListViewTemplate, 'PromotionsListViewController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [promotionListNotArchived]
    }, {
      cbac: 'setting_structure_promotions'
    }))

    .state('main.settings.automationPromotionsView', resolver(':lastScreen/view-promotion/:id?secondLastScreen', promotionViewTemplate, 'PromotionViewController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchPromotion, promotionHistory]
    }, {
      cbac: 'setting_structure_promotions'
    }))

    .state('main.settings.addPromotion', resolver(':lastScreen/add-promotion', updatePromotionTemplate, 'UpdatePromotionController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchPromotion.name]
    }, {
      cbac: 'setting_structure_promotions',
      isWrite: true
    }))

    .state('main.settings.updatePromotion', resolver(':lastScreen/update-promotion/:id?secondLastScreen', updatePromotionTemplate, 'UpdatePromotionController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchPromotion]
    }, {
      cbac: 'setting_structure_promotions',
      isWrite: true
    }))

    .state('main.settings.structurePolicies', resolver('policies', policiesListViewTemplate, 'PoliciesListViewController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [policiesList]
    }, {
      cbac: 'setting_structure_policy_list'
    }))

    .state('main.settings.structurePoliciesDetails', resolver('policies/:id?secondLastScreen', policyDetailTemplate, 'PolicyDetailController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchPolicy, recurringPlanHistory]
    }, {
      cbac: 'setting_structure_policy_rrp'
    }))

    .state('main.settings.addPolicy', resolver('add-policy', addPoliciesTemplate, 'AddPoliciesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchPolicy.name]
    }, {
      cbac: 'setting_structure_policy_rrp',
      isWrite: true
    }))

    .state('main.settings.updatePolicy', resolver('update-policy/:id', addPoliciesTemplate, 'AddPoliciesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchPolicy]
    }, {
      cbac: 'setting_structure_policy_rrp',
      isWrite: true
    }))

    .state('main.settings.structurePoliciesAttributeDetails', resolver('attribute-policy-detail/:id', attributePolicyDetailTemplate, 'AttributePolicyDetailController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchAttributePolicy, attributeHistory, POLICY_PRODUCTS]
    }, {
      cbac: 'setting_structure_policy_attribute'
    }))

    .state('main.settings.addAttributePolicy', resolver('add-attribute-policy', updateAttributePolicyTemplate, 'UpdateAttributePolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchAttributePolicy.name, POLICY_PRODUCTS.name]
    }, {
      cbac: 'setting_structure_policy_attribute',
      isWrite: true
    }))
    .state('main.settings.updateAttributePolicy', resolver('update-attribute-policy/:id', updateAttributePolicyTemplate, 'UpdateAttributePolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchAttributePolicy, POLICY_PRODUCTS]
    }, {
      cbac: 'setting_structure_policy_attribute',
      isWrite: true
    }))

    .state('main.settings.structurePoliciesSerialDetails', resolver('serial-number-detail/:id', serialNumberDetailTemplate, 'SerialNumberDetailController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchSerialNumberDetail, attributeHistory.name, SERIAL_NUMBER_ATTACH_PRODUCTS]
    }, {
      cbac: 'setting_structure_policy_attribute'
    }))

    .state('main.settings.addSerialNumber', resolver('add-serial-number', updateSerialNumberTemplate, 'UpdateSerialNumberController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchSerialNumberDetail.name, SERIAL_NUMBER_ATTACH_PRODUCTS.name]
    }, {
      cbac: 'setting_structure_policy_attribute',
      isWrite: true
    }))

    .state('main.settings.updateSerialNumber', resolver('update-serial-number/:id', updateSerialNumberTemplate, 'UpdateSerialNumberController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [fetchSerialNumberDetail, SERIAL_NUMBER_ATTACH_PRODUCTS]
    }, {
      cbac: 'setting_structure_policy_attribute',
      isWrite: true
    }))

    .state('main.settings.dataTagManagement', resolver('tag-management/:category', commonTagsManagement, 'CommonTagsManagement', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [mediaTagsList]
    }, {
      cbac: 'setting_structure_tag_management'
    }))

    .state('main.settings.updateCommonTag', resolver('update-tag/:category', editTagManagement, 'EditTagManagement', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [mediaTagsList]
    }, {
      cbac: 'setting_structure_tag_management',
      isWrite: true
    }))

    .state('main.settings.dataCustomForms', resolver('custom-forms', customFormsTemplate, 'CustomFormsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      // loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [LIST_CUSTOM_FORM]
    }, {
      cbac: 'setting_structure_custom_forms'
    }))

    .state('main.settings.addCustomForm', resolver('add-custom-form', updateCustomFormsTemplate, 'UpdateCustomFormsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      // loadOnDemand: [resolverProvider.loadDragDrop],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_DETAIL.name, CUSTOM_FORM_TYPE_LIST]
    }, {
      cbac: 'setting_structure_custom_forms',
      isWrite: true
    }))

    .state('main.settings.updateCustomForm', resolver('update-custom-form/:id', updateCustomFormsTemplate, 'UpdateCustomFormsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      // loadOnDemand: [resolverProvider.loadDragDrop],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      // CUSTOM_FORM_TYPE_LIST
      preData: [CUSTOM_FORM_DETAIL]
    }, {
      cbac: 'setting_structure_custom_forms'
    }))

    .state('main.settings.addCustomFreedomForm', resolver('add-custom-form/:formCategory/:formTarget', updateCustomFormsTemplate, 'UpdateCustomFormsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      // loadOnDemand: [resolverProvider.loadDragDrop],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_DETAIL.name, CUSTOM_FORM_TYPE_LIST]
    }, {
      cbac: 'setting_structure_custom_forms',
      isWrite: true
    }))

    .state('main.settings.addFreedomCustomForm', resolver('add-custom-form/:formCategory', updateCustomFormsTemplate, 'UpdateCustomFormsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      // loadOnDemand: [resolverProvider.loadDragDrop],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_DETAIL.name, CUSTOM_FORM_TYPE_LIST]
    }, {
      cbac: 'setting_structure_custom_forms',
      isWrite: true
    }))

    .state('main.settings.dataCustomFormsFreedomforms', resolver('freedom-forms', freedomFormsTemplate, 'FreedomFormsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [LIST_FREEDOM_FORM]
    }, {
      cbac: 'setting_structure_custom_forms'
    }))

    .state('main.settings.dataDataSets', resolver('data-sets', dataSetsTemplate, 'DataSetsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [listReasonTypes]
    }, {
      cbac: 'setting_structure_data_sets'
    }))

    .state('main.settings.dataExportData', resolver('export-data', exportDataTemplate, 'ExportDataController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [exportDataSets]
    }, {
      cbac: 'setting_structure_data_sets'
    }))

    .state('main.settings.addExportDataSetup', resolver('export-data-setup/:modelId', updateExportDataSetupTemplate, 'UpdateExportDataSetupController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [exportDataSet.name]
    }, {
      cbac: 'setting_structure_data_sets'
    }))

    .state('main.settings.updateExportDataSetup', resolver('export-data-setup/:modelId/:id', updateExportDataSetupTemplate, 'UpdateExportDataSetupController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [exportDataSet]
    }, {
      cbac: 'setting_structure_data_sets'
    }))

    .state('main.settings.structureAlias', resolver('aliases', viewAliasesTemplate, 'ViewAliasesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [aliases, behaviourSettings]
    }, {
      cbac: 'setting_structure_alias'
    }))

    .state('main.settings.updateAliases', resolver('update-aliases', updateAliasesTemplate, 'UpdateAliasesController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [aliases, behaviourSettings]
    }, {
      cbac: 'setting_structure_alias',
      isWrite: true
    }))

    .state('main.settings.automationGuidedActions', resolver('guided-actions', guidedActionPlansTemplate, 'GuidedActionPlansController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [GUIDED_ACTION_LIST]
    }, {
      cbac: 'setting_automation_guided_actions'
    }))

    .state('main.settings.automationGuidedActionsList', resolver('guided-actions-list', guidedActionListTemplate, 'GuidedActionListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [GUIDED_ACTION_LIST]
    }, {
      cbac: 'setting_automation_guided_actions'
    }))

    .state('main.settings.automationGuidedActionsView', resolver('view-guided-action/:id', guidedActionPlanViewTemplate, 'GuidedActionPlanViewController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [GUIDED_ACTION, GUIDED_ACTION_RULES, GUIDED_ACTION_PLAN_HISTORY]
    }, {
      cbac: 'setting_automation_guided_actions'
    }))

    .state('main.settings.addGuidedAction', resolver('add-guided-action', updateGuidedActionPlanTemplate, 'GuidedActionPlanUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [GUIDED_ACTION.name, GUIDED_ACTION_RULES.name]
    }, {
      cbac: 'setting_automation_guided_actions',
      isWrite: true
    }))

    .state('main.settings.updateGuidedAction', resolver('update-guided-action/:id', updateGuidedActionPlanTemplate, 'GuidedActionPlanUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [GUIDED_ACTION, GUIDED_ACTION_RULES]
    }, {
      cbac: 'setting_automation_guided_actions',
      isWrite: true
    }))

    .state('main.settings.automationGuidedActionsItemview', resolver('view-guided-action-item/:id/:itemId', guidedActionItemViewTemplate, 'GuidedActionItemViewController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [GUIDED_ACTION_RULE, EXPOSED_KEYS, EXPOSED_KEYS_DUE_DATE, GUIDED_ACTION_RULE_HISTORY]
    }, {
      cbac: 'setting_automation_guided_actions'
    }))

    .state('main.settings.addGuidedActionItem', resolver('add-guided-action-item/:id', guidedActionItemUpdateTemplate, 'GuidedActionItemUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [GUIDED_ACTION, GUIDED_ACTION_RULE.name, EXPOSED_KEYS, EXPOSED_KEYS_DUE_DATE]
    }, {
      cbac: 'setting_automation_guided_actions'
    }))

    .state('main.settings.updateGuidedActionItem', resolver('update-guided-action-item/:id/:itemId', guidedActionItemUpdateTemplate, 'GuidedActionItemUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [GUIDED_ACTION, GUIDED_ACTION_RULE, EXPOSED_KEYS, EXPOSED_KEYS_DUE_DATE]
    }, {
      cbac: 'setting_automation_guided_actions'
    }))

    .state('main.settings.viewPayment', resolver('payment-policy-details/:id', viewPaymentPolicyTemplate, 'viewPaymentPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [PaymentPolicys]
    }, {
      cbac: 'setting_structure_policy_payment'
    }))

    .state('main.settings.addPayment', resolver('add-payment-policy', addPaymentPolicyTemplate, 'AddPaymentPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [PaymentPolicys.name]
    }, {
      cbac: 'setting_structure_policy_payment',
      isWrite: true
    }))

    .state('main.settings.updatePayment', resolver('update-payment-policy/:id', addPaymentPolicyTemplate, 'AddPaymentPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [PaymentPolicys]
    }, {
      cbac: 'setting_structure_policy_payment',
      isWrite: true
    }))

    .state('main.settings.addTrackingPolicy', resolver('add-tracking-policy', addTrackingPolicyTemplate, 'AddTrackingPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [TrackingPolicy.name]
    }, {
      cbac: 'setting_structure_policy_payment',
      isWrite: true
    }))

    .state('main.settings.updateTrackingPolicy', resolver('update-tracking-policy/:id', addTrackingPolicyTemplate, 'AddTrackingPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [TrackingPolicy]
    }, {
      cbac: 'setting_structure_policy_payment',
      isWrite: true
    }))

    .state('main.settings.structurePoliciesTrackingPolicyDetails', resolver('tracking-policy-details/:id', viewTrackingPolicyTemplate, 'ViewTrackingPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [TrackingPolicy]
    }, {
      cbac: 'setting_structure_policy_payment'
    }))

    .state('main.settings.addPayPolicy', resolver('add-pay-policy', addPayPolicyTemplate, 'AddPayPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [PayPolicy.name]
    }, {
      cbac: 'setting_structure_policy_payment',
      isWrite: true
    }))

    .state('main.settings.updatePayPolicy', resolver('update-pay-policy/:id', addPayPolicyTemplate, 'AddPayPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [PayPolicy]
    }, {
      cbac: 'setting_structure_policy_payment',
      isWrite: true
    }))

    .state('main.settings.structurePoliciesPayPolicyDetails', resolver('pay-policy-details/:id', viewPayPolicyTemplate, 'ViewPayPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [PayPolicy]
    }, {
      cbac: 'setting_structure_policy_payment'
    }))

    .state('main.settings.structureBankingPaymentGatewayList', resolver('payment-gateway-list', paymentGatewayListTemplate, 'PaymentGatewayListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [paymentGatewayList, regions, accountListQB]
    }, {
      cbac: 'setting_structure_banking'
    }))

    // TODO:
    .state('main.settings.structureBankingTransactions', resolver('transaction-list/:regionId', transactionListTemplate, 'TransactionListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [transactionLists]
    }))

    .state('main.settings.structureBankingViewRegion', resolver('view-region/:id', viewRegionTemplate, 'ViewRegionController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [detailsRegion, regions]
    }, {
      cbac: 'setting_structure_banking'
    }))

    .state('main.settings.updateSetup', resolver('update-region/:id', updateRegionTemplate, 'UpdateRegionController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [detailsRegion, paymentGatewayList, regions]
    }, {
      cbac: 'setting_structure_banking',
      isWrite: true
    }))

    .state('main.settings.structureBankingAllTransactions', resolver('all-transactions', allTransactionsTemplate, 'AllTransactionsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [allTransaction, regions]
    }, {
      cbac: 'setting_structure_banking'
    }))

    .state('main.settings.bankingAddBrainTree', resolver('add-brain-tree', addBrainTreeTemplate, 'AddBrainTreeController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [regions]
    }, {
      cbac: 'setting_structure_banking',
      isWrite: true
    }))

    .state('main.settings.bankingAddFirstData', resolver('add-first-data', addFirstDataTemplate, 'AddFirstDataController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [regions]
    }, {
      cbac: 'setting_structure_banking',
      isWrite: true
    }))

    .state('main.settings.addWarrantyPolicy', resolver('add-warranty-policy', updateWarrantyPolicyTemplate, 'UpdateWarrantyPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [warrantyDetails.name]
    }, {
      cbac: 'setting_structure_policy_warranty',
      isWrite: true
    }))

    .state('main.settings.updateWarrantyPolicy', resolver('update-warranty-policy/:id', updateWarrantyPolicyTemplate, 'UpdateWarrantyPolicyController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [warrantyDetails]
    }, {
      cbac: 'setting_structure_policy_warranty',
      isWrite: true
    }))

    .state('main.settings.structurePoliciesWarrantyPolicyDetails', resolver('warranty-policy-details/:id', warrantyPolicyDetailsTemplate, 'WarrantyPolicyDetailsController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [warrantyDetails]
    }, {
      cbac: 'setting_structure_policy_warranty'
    }))

    .state('main.settings.structureDeliveryZone', resolver('delivery-zone', deliveryZoneListTemplate, 'DeliveryZoneListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadDragDrop]
    }, {
      cbac: 'setting_structure_delivery_zone'
    }))

    .state('main.settings.structureAddZone', resolver('add-zone', addDeliveryZoneTemplate, 'AddDeliveryZoneController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule, resolverProvider.loadAutoComplete],
      preData: [DELIVERY_ZONE_DETAIL.name, ACTIVE_DELIVERY_METHOD_LIST.name]
    }, {
      cbac: 'setting_structure_delivery_zone',
      isWrite: true
    }))

    .state('main.settings.structureUpdateZone', resolver('update-zone/:zoneId', addDeliveryZoneTemplate, 'AddDeliveryZoneController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule, resolverProvider.loadAutoComplete],
      preData: [DELIVERY_ZONE_DETAIL, ACTIVE_DELIVERY_METHOD_LIST]
    }, {
      cbac: 'setting_structure_delivery_zone',
      isWrite: true
    }))

    .state('main.settings.structureDeliveryZoneDetail', resolver('detail-zone/:zoneId', detailZoneTemplate, 'DetailZoneController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [DELIVERY_ZONE_DETAIL, DELIVERY_METHOD_LIST],
      loadOnDemand: [resolverProvider.loadDragDrop]
    }, {
      cbac: 'setting_structure_delivery_zone'
    }))

    .state('main.settings.structureAddMethod', resolver('add-method/:zoneId', addDeliveryMethodTemplate, 'AddDeliveryMethodController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [DELIVERY_METHOD_DETAIL.name]
    }, {
      cbac: 'setting_structure_delivery_zone',
      isWrite: true
    }))

    .state('main.settings.structureUpdateMethod', resolver('add-method/:zoneId/:methodId', addDeliveryMethodTemplate, 'AddDeliveryMethodController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [DELIVERY_METHOD_DETAIL]
    }, {
      cbac: 'setting_structure_delivery_zone',
      isWrite: true
    }))
    // structureDeliveryZoneMethoddetail
    //structureDeliveryZoneMethoddetail
    .state('main.settings.structureDeliveryZoneMethoddetail', resolver('detail-method/:zoneId/:methodId', detailMethodTemplate, 'DetailMethodController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [DELIVERY_METHOD_DETAIL]
    }, {
      cbac: 'setting_structure_delivery_zone'
    }))

    .state('main.settings.structureDeliveryCalculator', resolver('delivery-calculator', deliveryCalculatorTemplate, 'DeliveryCalculator', {
      lazyModule: [settingsResolverProvider.loadSettingsModule, resolverProvider.loadAutoComplete]
    }, {
      cbac: 'setting_structure_delivery_zone'
    }))

    // TAXES
    .state('main.settings.structureTaxesZoneCategoryAdd', resolver('tax-categories-add', updateTaxCategoryTemplate, 'UpdateTaxCategoryController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [CATEGORY_DETAILS.name]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZoneCategoryUpdate', resolver('tax-categories-update/:id', updateTaxCategoryTemplate, 'UpdateTaxCategoryController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [CATEGORY_DETAILS]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZoneClassificationAdd', resolver('tax-classification-add', updateClassificationTemplate, 'UpdateClassificationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [CLASSIFICATION_DETAILS.name]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZoneClassificationUpdate', resolver('tax-classification-update/:id', updateClassificationTemplate, 'UpdateClassificationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [CLASSIFICATION_DETAILS]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZone', resolver('tax-zone', taxZoneListTemplate, 'TaxZoneListController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [TAX_ZONE_LIST, TAX_CLASSIFICATION, TAX_CATEGORY]
    }, {
      cbac: 'setting_structure_sales_tax'
    }))

    .state('main.settings.structureTaxesZoneAdd', resolver('tax-zone-add', updateTaxZoneTemplate, 'UpdateTaxZoneController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [TAX_ZONE_DETAILS.name]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZoneUpdate', resolver('tax-zone-update/:taxZoneId', updateTaxZoneTemplate, 'UpdateTaxZoneController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [TAX_ZONE_DETAILS]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZoneDetail', resolver('tax-zone-detail/:taxZoneId', taxZoneDetailTemplate, 'TaxZoneDetailController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [TAX_ZONE_DETAILS, ZONE_METHOD_LIST, CUSTOMER_LIST, EXEMPT_CUSTOMER_LIST]
    }, {
      cbac: 'setting_structure_sales_tax'
    }))

    .state('main.settings.structureTaxesZoneMethodAdd', resolver('tax-zone-method-add/:taxZoneId', taxZoneMethodUpdateTemplate, 'TaxZoneMethodUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [TAX_CODE_LIST, ZONE_METHOD_DETAIL.name, METHOD_CLASSIFICATION_DETAIL.name, METHOD_CALCULATION_DETAIL.name]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureTaxesZoneMethoddetail', resolver('tax-zone-method-detail/:taxZoneId/:methodId', taxZoneMethodDetailTemplate, 'TaxZoneMethodDetailController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [ZONE_METHOD_DETAIL, METHOD_CLASSIFICATION_DETAIL, METHOD_CALCULATION_DETAIL]
    }, {
      cbac: 'setting_structure_sales_tax'
    }))

    .state('main.settings.structureTaxesZoneMethodUpdate', resolver('tax-zone-method-update/:taxZoneId/:methodId', taxZoneMethodUpdateTemplate, 'TaxZoneMethodUpdateController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [TAX_CODE_LIST, ZONE_METHOD_DETAIL, METHOD_CLASSIFICATION_DETAIL, METHOD_CALCULATION_DETAIL]
    }, {
      cbac: 'setting_structure_sales_tax',
      isWrite: true
    }))

    .state('main.settings.structureBankingQuickBookAdd', resolver('quick-book', addQuickBookAccountTemplate, 'AddQuickBookAccountController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [QB_ACCOUNT_DETAIL.name, ACCOUNT_AUTHENTICATE_DETAIL.name, NEW_ACCOUNT_SETTING_LOCATION]
    }, {
      cbac: 'setting_structure_banking_accounting',
      isWrite: true
    }))

    .state('main.settings.structureBankingQuickBookUpdate', resolver('quick-book/:accountId', addQuickBookAccountTemplate, 'AddQuickBookAccountController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule],
      preData: [QB_ACCOUNT_DETAIL, ACCOUNT_AUTHENTICATE_DETAIL, SETTING_LOCATION]
    }, {
      cbac: 'setting_structure_banking_accounting',
      isWrite: true
    }))

    .state('main.settings.structureBankingQuickBookApiIntegration', resolver('quick-book-api-integration?code&state&realmId&error', quickbookApiIntegrationTemplate, 'QuickBookAPIIntegrationController', {
      lazyModule: [settingsResolverProvider.loadSettingsModule]
    }, {
      cbac: 'setting_structure_banking_accounting',
      isWrite: true
    }));
}