export default function(app) {

  const business = 1,
    calendar = 1,
    customer = 1,
    jacktrade = 1,
    jtCommon = 1,
    quotes = 1,
    resources = 1,
    promotion = 1,
    invoice = 1,
    virusCheck = 1,
    deliveryZone = 1,
    deliveryMethod = 1,
    dashboardApi = 1,
    blocksApi = 1,
    dashboardPosition = 1,
    blockPosition = 1,
    quoteReport = 1,
    jobReport = 1,
    jobTrendCharts = 1,
    opportunityTrendChart = 1,
    jobsBarChart = 1,
    customerReport = 1,
    businessSetup = 1,
    taxClassification = 1,
    taxCategory = 1,
    taxZone = 1,
    dataExport = 1,
    taxMethod = 1,
    taxExempt = 1,
    taxMethodClassification = 1,
    taxMethodCalculation = 1,
    taxMethodPosition = 1,
    rental = 1,
    estimateTemplate = 1,
    lineItemTemplate = 1,
    mailBox = 1,
    webConversionSetting = 1,
    webConversionStatus = 1,
    activity = 1,
    filterSettings = 1,
    quickBookSettings = 1,
    quickBook = 1,
    company = 1,
    zoomSetting = 1,
    campaignCategory = 1,
    campaign = 1,
    campaignDrip = 1,
    campaignUnsubscribe = 1,
    exposed = 1,
    campaignReport = 1,
    assocDoc = 1,
    businessGroup = 1,
    businessCategories = 1,
    businessContact = 1,
    businessContactBulkImport = 1,
    warrantyTracking = 1,
    additionalAddress = 1,
    drawing = 1,
    serialNumbers = 1,
    kits = 1,
    personalMembers = 1,
    serialNumbersImport = 1,
    appBehaviour = 1,
    orderImportSetup = 1;

  app.constant('apiVersions', {
    businessOwner: business,
    businessEntity: business,
    address: business,
    attributePolicy: business,
    business: business,
    bzAddress: business,
    expense: business,
    inventory: business,
    inventorySetting: business,
    promotion: business,
    recurringPlan: business,
    region: business,
    procurement: business,
    supplier: business,
    PaymentPolicy: business,
    procurementPayment: business,
    fulfilment: business,
    inventoryImport: business,
    quotesPayment: business,
    businessInvoice: business,
    businessEntity: business,
    businessRegion: business,
    businessLocation: business,
    emailSetup: business,
    emailStatistics: business,
    emailProvider: business,
    notificationSettings: business,
    emailBranding: business,
    smsSetup: business,
    smsTemplate: business,
    sowCats: business,
    orderServiceLanes: business,

    calendar: calendar,
    event: calendar,
    operationalEvent: calendar,

    contact: customer,
    draftContact: customer,
    customerCat: customer,
    conversation: customer,
    convTemplate: customer,
    convTemplateCategory: customer,
    convLabel: customer,
    conversationReply: customer,
    customerBulkImport: customer,
    customerSupport: customer,
    opportunity: customer,
    opportunityReport: customer,
    tag: customer,
    segmentation: customer,
    segChannelCategory: customer,
    segmentationCategory: customer,
    leadGeneration: customer,
    leadCollector: customer,
    conversationChannel: customer,
    convHistory: customer,

    application: jacktrade,
    geoLoc: jacktrade,
    places: jacktrade,
    util: jacktrade,
    login: jacktrade,
    searchHistory: jacktrade,
    reasonList: jacktrade,
    activityHistory: jacktrade,
    exposedKeys: jacktrade,
    guidedActionPlan: jacktrade,
    contentMgmt: jacktrade,
    quotesPayment: jacktrade,
    pamBusiness: jacktrade,
    errorManagement: jacktrade,
    country: jacktrade,
    templateBuilder: jacktrade,
    tours: jacktrade,
    term: jacktrade,
    emailTemplate: jacktrade,
    bzNotification: jacktrade,
    emailSpam: jacktrade,
    timezone: jacktrade,
    comment: jacktrade,
    stops: jacktrade,
    dataExport: jacktrade,
    exportSetup: jacktrade,
    lastVisited: jacktrade,

    importSettings: jtCommon,
    media: jtCommon,
    uiSetting: jtCommon,
    peval: jtCommon,
    adminTemBuilder: jtCommon,

    onsite: quotes,
    quote: quotes,
    task: quotes,
    taskLine: quotes,
    quoteInventory: quotes,
    jobsInventory: quotes,
    lineItemTemplateCat: quotes,
    quotesTrendChart: quotes,
    quotesBarChart: quotes,

    resource: resources,
    actions: resources,
    actionItemLabel: resources,
    actionItemComment: resources,
    actionFolder: resources,
    resourceType: resources,
    user: resources,
    actionFolder: resources,
    ganttChart: resources,
    timeTracking: resources,
    resourcePayment: resources,
    approvedHours: resources,
    manuallyAddedTime: resources,
    locationTracking: resources,
    actionItemPositions: resources,

    aliases: appBehaviour,
    behaviourFlags: appBehaviour,
    quotePromotion: promotion,
    customFields: business,
    freedomForms: business,
    invoice: invoice,
    workOrders: invoice,
    virusCheck: virusCheck,
    deliveryZone: deliveryZone,
    deliveryMethod: deliveryMethod,
    dashboard: dashboardApi,
    workBoard: dashboardApi,
    businessHighlight: dashboardApi,
    activityBoard: dashboardApi,
    referral: dashboardApi,
    blocks: blocksApi,
    dashboardPosition: dashboardPosition,
    blockPosition: blockPosition,
    quoteReport: quoteReport,
    jobReport: jobReport,
    jobTrendCharts: jobTrendCharts,
    jobsBarChart: jobsBarChart,
    customerReport: customerReport,
    customerTrendChart: customerReport,
    opportunityTrendChart: opportunityTrendChart,
    businessSetup: businessSetup,
    taxClassification: taxClassification,
    taxCategory: taxCategory,
    taxZone: taxZone,
    dataExport: dataExport,
    taxMethod: taxMethod,
    taxExempt: taxExempt,
    taxMethodClassification: taxMethodClassification,
    taxMethodCalculation: taxMethodCalculation,
    taxMethodPosition: taxMethodPosition,
    rental: rental,
    estimateTemplate: estimateTemplate,
    lineItemTemplate: lineItemTemplate,
    mailBox: mailBox,
    webConversionSetting: webConversionSetting,
    webConversionStatus: webConversionStatus,
    activity: activity,
    filterSettings: filterSettings,
    quickBookSettings: quickBookSettings,
    quickBook: quickBook,
    company: company,
    zoomSetting: zoomSetting,
    campaignCategory: campaignCategory,
    campaign: campaign,
    campaignDrip: campaignDrip,
    campaignUnsubscribe: campaignUnsubscribe,
    exposed: exposed,
    campaignReport: campaignReport,
    assocDoc: assocDoc,
    businessGroup: businessGroup,
    businessCategories: businessCategories,
    businessContact: businessContact,
    businessContactBulkImport: businessContactBulkImport,
    warrantyTracking: warrantyTracking,
    additionalAddress: additionalAddress,

    drawingTemplateCategories: drawing,
    drawingTemplate: drawing,
    drawings: drawing,
    documentBuilder: drawing,

    serialNumbers: serialNumbers,
    kits: kits,
    personalMembers: personalMembers,
    serialNumbersImport: serialNumbersImport,
    historicSerialsImport: serialNumbersImport,
    orderImportSetup: orderImportSetup
  });
}