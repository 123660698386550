import confirmCategoryDeleteTemplate from './confirm-delete-drawing-template.html';

export default function DrawingCategoriesController($scope, $mdDialog, $timeout, messages, maxlength, errorMessage, dataServices, serverUrl, validatorService, vm) {

  'ngInject';

  let dc = this;

  dc.maxlength = maxlength;
  dc.errorMessages = errorMessage;
  dc.save = validatorService.validateForm(dc, saveCategories);

  function getCategories() {
    dataServices.get({
        url: serverUrl.main + 'drawingTemplateCategoriesApi/drawing/template/categories?stats=true&',
        spinnerName: 'drawing-categories-spinner'
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          dc.categories = data.result;
        }
      });
  }

  $timeout(function() {
    getCategories();
  }, 0);

  function saveCategories() {
    dc.categoryData = {
      add: [],
      update: []
    };

    angular.forEach(dc.categories, function(category) {
      if (category.id) {
        dc.categoryData.update.push(dataServices.toSave(category, ['id', 'name']));
      } else {
        dc.categoryData.add.push(dataServices.toSave(category, ['name']));
      }
    });

    dataServices.post({
        url: serverUrl.main + "drawingTemplateCategoriesApi/drawing/template/category",
        data: {
          data: angular.toJson(dc.categoryData)
        },
        spinnerName: 'drawing-categories-spinner'
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          // dc.categories = data.result;
          // getCategories();
          messages.simpleToast('Categories updated.');
          dc.cancel();
        }
      });
  }

  dc.addCategory = function() {
    dc.categories.push({});
  };

  dc.deleteCategory = function(ev, index) {
    let category = dc.categories[index];
    if (category.hasOwnProperty('id')) {
      if (category.template_count) {
        dc.confirmCategoryDelete(ev, index);
      } else {
        messages.mdconfirm(ev, 'Deleting a drawing category is permanent. Are you sure you want to delete this category?')
        .then(function(flag) {
          if (flag) {
            dataServices.delete({
                url: serverUrl.main + 'drawingTemplateCategoriesApi/drawing/template/category/' + category.id,
                spinnerName: 'drawing-categories-spinner'
              })
              .then(function(response) {
                let data = response.data;
                if (data && data.response_code == 200) {
                  dc.categories = data.result;
                  // getCategories();
                  messages.simpleToast('Category Deleted Successfully.');
                }
              });
          }
        });
      }
    } else {
      dc.categories.splice(index, 1);
    }
  };

  dc.cancel = function() {
    $mdDialog.cancel();
  };

  dc.confirmCategoryDelete = confirmCategoryDelete;

  function confirmCategoryDelete(ev, index) {
    $mdDialog.show({
      controller: ConfirmCategoryDeleteController,
      templateUrl: confirmCategoryDeleteTemplate,
      locals: {
        dc: dc,
        index: index
      },
      parent: angular.element(document.body),
      targetEvent: ev,
      controllerAs: 'dt',
      multiple: true
    });
  }

  function ConfirmCategoryDeleteController($scope, $mdDialog, $mdSidenav, messages, errorMessage, dataServices, validatorService, dc, index) {

    'ngInject';

    let dt = this;
    dt.category = dc.categories[index];
    dt.categories = dc.categories;

    dt.errorMessages = errorMessage;
    dt.deleteCategory = validatorService.validateForm(dt, deleteCategory);

    function deleteCategory() {
      dataServices.delete({
          url: serverUrl.main + 'drawingTemplateCategoriesApi/drawing/template/category/' + dt.category.id + '?' + 'change_to_category=' + dt.moveToCategory,
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            dc.categories = data.result;
            vm.setFilters('new', true);
            dt.cancel();
            dc.cancel();
            messages.simpleToast('Category Deleted Successfully.');
          }
        });
    }

    dt.cancel = function() {
      $mdDialog.cancel();
    };
  }

}