export const CATALOGS = {
  url: 'inventoryApi/catalogs?prodType=["product","material"]&stats=yes&',
  method: 'GET',
  name: 'catalogs',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: 'in',
      value: [2, 4]
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
  },
  pagination: 'CATALOG_LIST'
};

export const ATTACHED_SERVICES = {
  url: 'inventoryApi/services?prodType=["product","material"]&stats=yes&',
  method: 'GET',
  name: 'services',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  pagination: 'ATTACHED_SERVICE_LIST'
};

export const PRODUCT_INSTRUCTIONS = {
  url: 'inventoryApi/instructions?prodType=["product","material"]&stats=yes&',
  method: 'GET',
  name: 'instructions',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: 'in',
      value: [2, 4]
    }],
    defaultSort: [{
      field: "name",
      order: 1
    }]
  },
  pagination: 'PRODUCT_INSTRUCTION_LIST'
};

export const regions = {
  url: 'businessRegionApi/business/region',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: 'name',
      order: 1
    }]
  },
  name: 'regions'
};

export const listCatalogs = {
  url: 'inventoryApi/catalogs?prodType=["product"]&',
  method: 'GET',
  name: 'catalogs',
  filterCriteria: {
    filters: [{
      field: 'hierarchyType',
      operator: '=',
      value: 'parent'
    },
    {
      field: 'status',
      operator: '=',
      value: 2
    }
    ]
  }
};

export const showCatalog = {
  url: 'inventoryApi/catalogs/:id',
  method: 'GET',
  name: 'catalog',
  params: ['id'],
  requiredFor: ['main.business.updateCatalog']
};

export const showParentCatalogs = {
  url: 'inventoryApi/catalogs?prodType=["product","material"]&',
  method: 'GET',
  name: 'parentCatalogs',
  filterCriteria: {
    filters: [{
      field: 'hierarchyType',
      operator: '=',
      value: 'parent'
    }]
  }
};

export const showServices = {
  url: 'inventoryApi/services?prodType=["product"]&',
  method: 'GET',
  name: 'services'
};

export const showServicesMaterial = {
  url: 'inventoryApi/services?prodType=["material"]&',
  method: 'GET',
  name: 'services'
};

export const showService = {
  url: 'inventoryApi/services/:id?products=true&',
  method: 'GET',
  name: 'service',
  params: ['id'],
  requiredFor: ['main.business.updateAttachedService'],
  forcedBackFor: {
    'main.business.updateAttachedService': ['main.business.inventoryCataloging']
  }
};

export const showInstruction = {
  url: 'inventoryApi/instructions/:id',
  method: 'GET',
  name: 'instruction',
  params: ['id'],
  requiredFor: ['main.business.updateProductInstruction'],
  forcedBackFor: {
    'main.business.updateProductInstruction': ['main.business.inventoryCataloging']
  }
};

export const showInstructionCategories = {
  url: 'inventoryApi/instructions/categories',
  method: 'GET',
  name: 'instructionCategories',
};

export const CATALOG_PRODUCTS = {
  url: 'inventoryApi/catalogs/:id/products?prodType=["product","material"]&need=product&',
  method: 'GET',
  name: 'cproducts',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    project: ['name', 'business_id', 'set', 'type', 'product_id', 'images', 'avatar', , 'user_last_updated', 'custom', 'status', 'location_id', 'userInfo', 'updated_by', 'variationCount']
  },
  params: ['id'],
  pagination: 'CATALOG_PRODUCTS_LIST',
  // requiredFor: ['main.business.inventoryProducts'],
  // forcedBackFor: {
  //   'main.business.inventoryProductsDetail': ['main.business.inventoryProducts'],
  //   'main.business.addProduct': ['main.business.inventoryProducts']
  // }
};

// Passed for nav
// export const PRODUCTS_TO_ASSIGN = {
//   url: 'inventoryApi/products?prodType=["product","material"]&',
//   method: 'GET',
//   name: 'products',
//   filterCriteria: {
//     filters: [{
//       "field": 'isArchived',
//       "operator": '=',
//       "value": false
//     }, {
//       "field": "parentId",
//       "operator": "!="
//     }, {
//       "field": "set",
//       "value": "package",
//       "operator": "!="
//     }]
//   },
//   filterValue: [false, 'id'],
//   pagination: 'PRODUCTS_LIST'
// };

export const products = {
  url: 'inventoryApi/products?prodType=["product"]&stats=yes&',
  method: 'GET',
  name: 'products',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    project: ['name', 'business_id', 'set', 'type', 'product_id', 'images', 'avatar', 'region_id', 'user_last_updated', 'seasonal_from', 'seasonal_to', 'attributes', 'custom', 'status', 'location_id', 'userInfo', 'updated_by', 'variationCount', 'parent_id', 'is_archived', 'serial_num_policy_id']
  },
  pagination: 'PRODUCTS_LIST',
  requiredFor: ['main.business.inventoryProducts'],
  forcedBackFor: {
    'main.business.inventoryProductsDetail': ['main.business.inventoryProducts'],
    'main.business.addProduct': ['main.business.inventoryProducts']
  }
};

export const materials = {
  url: 'inventoryApi/products?prodType=["material"]&stats=yes&',
  method: 'GET',
  name: 'products',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    project: ['name', 'business_id', 'set', 'type', 'product_id', 'images', 'avatar', 'region_id', 'user_last_updated', 'seasonal_from', 'seasonal_to', 'attributes', 'custom', 'status', 'location_id', 'userInfo', 'updated_by', 'variationCount', 'parent_id']
  },
  pagination: 'PRODUCTS_LIST',
  requiredFor: ['main.business.inventoryMaterials'],
  forcedBackFor: {
    'main.business.inventoryMaterialsDetail': ['main.business.inventoryMaterials'],
    'main.business.addProduct': ['main.business.inventoryProducts']
  }
};

export const showProduct = {
  url: 'inventoryApi/products/:id',
  method: 'GET',
  name: 'product',
  params: ['id'],
  requiredFor: ['main.business.inventoryProductsDetail', 'main.business.inventoryProductsView', 'main.business.updateProduct'],
  forcedBackFor: {
    'main.business.inventoryProductsView': ['main.business.inventoryProductsDetail'],
    'main.business.updateProduct': ['main.business.inventoryProductsView'],
    'main.business.addSku': ['main.business.inventoryProductsDetail']
  }
};

export const showBundle = {
  url: 'inventoryApi/products/bundles/:id',
  method: 'GET',
  name: 'bundle',
  params: ['id'],
  requiredFor: ['main.business.inventoryProductsBundleView', 'main.business.updateBundle'],
  forcedBackFor: {
    'main.business.updateBundle': ['main.business.inventoryProductsBundleView'],
    'main.business.inventoryProductsBundleView': ['main.business.inventoryProducts'],
    'main.business.addBundle': ['main.business.inventoryProducts']
  }
};

export const showPackage = {
  url: 'inventoryApi/products/packages/:id',
  method: 'GET',
  name: 'packageData',
  params: ['id'],
  requiredFor: ['main.business.inventoryProductsPackageView', 'main.business.updatePackage'],
  forcedBackFor: {
    'main.business.updatePackage': ['main.business.inventoryProductsPackageView'],
    'main.business.inventoryProductsPackageView': ['main.business.inventoryProducts'],
    'main.business.addPackage': ['main.business.inventoryProducts']
  }
};

export const showSku = {
  url: 'inventoryApi/products/:id/variations/:skuId',
  method: 'GET',
  name: 'sku',
  params: ['id', 'skuId'],
  requiredFor: ['main.business.inventoryProductsSkuDetail', 'main.business.updateSku', 'main.business.addCloneSku'],
  forcedBackFor: {
    'main.business.s': ['main.business.inventoryProductsSkuDetail'],
    'main.business.addCloneSku': ['main.business.inventoryProductsDetail'],
    'main.business.inventoryProductsSkuDetail': ['main.business.inventoryProductsDetail']
  }
};

export const showSkus = {
  url: 'inventoryApi/products/:id/variations',
  method: 'GET',
  name: 'skus',
  params: ['id']
};

export const listBizServices = {
  url: 'businessApi/business/services',
  method: 'GET',
  name: 'bizServices'
};

export const listScopeOfWork = {
  url: 'businessApi/business/scopeOfWork',
  method: 'GET',
  name: 'scopeOfWork'
};

export const listReasonTypes = {
  url: 'businessApi/business/serviceType',
  method: 'GET',
  name: 'reasonTypes'
};

//added
export const serialScan = {
  url: 'serialNumbersApi/serial/uid',
  method: 'GET',
  name: 'serialScan'
};
// export const serialScanHistory = {
//   url : 'serialNumbersApi/serial/scan',
//   method: 'GET',
//   name: 'serialScanHistory'
// };
export const listServiceRates = {
  url: 'businessApi/business/serviceRates',
  method: 'GET',
  name: 'serviceRates'
};

export const fetchAllPromotion = {
  url: 'promotionApi/promotions',
  method: 'GET',
  name: 'promotions'
};

export const promotionListNotArchived = {
  url: 'promotionApi/promotions',
  method: 'GET',
  name: 'promotionData',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'assgnPromoTo',
      operator: '=',
      value: 'product'
    }]
  },
  pagination: 'PROMOTION_LIST'
};

export const fetchPromotion = {
  url: "promotionApi/promotions/:id",
  method: 'GET',
  name: "promotion",
  params: ['id']
};

export const mediaRepoList = {
  url: 'mediaApi/mediaRepository',
  method: 'GET',
  name: 'mediaRepoList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "createdOn",
      order: -1
    }]
  },
  pagination: 'MEDIA_LIST',
  requiredFor: ['main.business.mediaAllMedia'],
};

// Passed for nav
export const mediaTagsList = {
  url: 'tagApi/tags/category/:category',
  name: 'mediaTags',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'category',
      operator: '='
    }, {
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  filterValue: ['category'],
  params: ['category'],
  paramTypes: ['int']
};

// Passed for nav
export const locationsByRegionId = {
  url: 'bzAddressApi/business/addresses',
  name: 'locationsByRegion',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'region_code',
      operator: '='
    }]
  },
  filterValue: ['regionId']
};

export const fetchAttributePolicy = {
  url: "attributePolicyApi/attributePolicy/:policyId",
  method: 'GET',
  name: "attributePolicy",
  params: ['policyId']
};

export const suppliers = {
  url: 'supplierApi/suppliers',
  method: 'GET',
  name: 'suppliers',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  pagination: 'SUPPLIER_LIST'
};

export const suppliersList = {
  url: 'supplierApi/suppliers?includePo=true&stats=yes&',
  method: 'GET',
  name: 'suppliersList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'SUPPLIER_LIST'
};

export const supplierss = {
  url: 'supplierApi/suppliers',
  method: 'GET',
  name: 'supplierss',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    },
    {
      field: 'status',
      operator: '=',
      value: 2
    },
    {
      field: 'type',
      operator: 'in',
      value: ["both", "product"]
    }
    ],
    sort: [{
      field: 'supplierName',
      order: 1
    }]
  }
};

export const supplierssMaterial = {
  url: 'supplierApi/suppliers',
  method: 'GET',
  name: 'supplierssMaterial',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    },
    {
      field: 'status',
      operator: '=',
      value: 2
    },
    {
      field: 'type',
      operator: 'in',
      value: ["material"]
    }
    ]
  }
};

export const supplierDetails = {
  url: 'supplierApi/supplier/:supplierId',
  method: 'GET',
  name: 'supplierDetails',
  params: ['supplierId']
};

export const catalogDetails = {
  url: 'supplierApi/supplier/catalog/:catalogId',
  method: 'GET',
  name: 'catalogDetails',
  params: ['catalogId']
};

export const purchaseOrder = {
  url: 'procurementApi/purchaseord/:id',
  method: 'GET',
  name: 'purchaseOrder',
  params: ['id']
};

export const purchaseOrders = {
  url: 'procurementApi/purchaseord?stats=yes&',
  method: 'GET',
  name: 'purchaseOrders',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'PURCHASE_ORDER_LIST'
};

export const catalogList = {
  url: 'supplierApi/supplier/:supplierId/catalogs?stats=yes&',
  method: 'GET',
  name: 'catalogList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  params: ['supplierId'],
  pagination: 'SUPPLIER_CATALOG_LIST'
};

export const reasonlistPo = {
  url: 'reasonListApi/reasonList',
  method: 'GET',
  name: 'reasonlistPo',
  filterCriteria: {
    filters: [{
      field: "functionType",
      value: 4,
      operator: "="
    },
    {
      field: "reasonList",
      value: 'additionalCost',
      operator: "="
    },
    {
      field: "enabled",
      value: true,
      operator: "="
    }
    ]
  }
};
export const reasonlistPoCancel = {
  url: 'reasonListApi/reasonList',
  method: 'GET',
  name: 'reasonlistPoCancel',
  filterCriteria: {
    filters: [{
      field: "functionType",
      value: 4,
      operator: "="
    },
    {
      field: "reasonList",
      value: 'PoCancelReason',
      operator: "="
    },
    {
      field: "enabled",
      value: true,
      operator: "="
    }
    ]
  }
};

export const paymentPolicy = {
  url: 'PaymentPolicyApi/payment_policies',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: 'planName',
      order: 1
    }]
  },
  name: 'paymentPolicy'
};

export const relatedOrder = {
  url: 'supplierApi/supplier/:supplierId/pos',
  method: 'GET',
  name: 'relatedOrder',
  params: ['supplierId']
};

export const autoRelatedOrder = {
  url: 'supplierApi/supplier/:supplierId/auto_orders',
  method: 'GET',
  name: 'autoRelatedOrder',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  params: ['supplierId']
};

export const receivePO = {
  url: 'procurementApi/purchaseorder/:id/po_history/receive',
  method: 'GET',
  name: 'receivePO',
  params: ['id']
};

export const poReturn = {
  url: 'procurementApi/purchaseorder/:id/po_history/poReturn',
  method: 'GET',
  name: 'poReturn',
  params: ['id']
};
export const poPdf = {
  url: 'procurementApi/purchaseorder/:id/invoice/pdf',
  name: 'poPdf',
  method: 'GET',
  params: ['id']
};
export const poPreview = {
  url: 'procurementApi/purchaseorder/:id/invoice/preview',
  name: 'poPreview',
  method: 'GET',
  params: ['id']
};
// Passed for nav
export const supplierHistory = {
  url: 'activityHistoryApi/history',
  name: 'supplierHistory',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "function_id",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['supplierId'],
  requiredFor: ['main.business.supplierSupplierDetail']
};

// Passed for nav
export const poHistory = {
  url: 'activityHistoryApi/history',
  name: 'poHistory',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "sub_function_id",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id'],
  requiredFor: ['main.business.purchaseOrderDetails']
};

export const receiveSku = {
  url: 'procurementApi/purchaseorder/:id/receivesku',
  method: 'GET',
  name: 'receiveSku',
  params: ['id']
};

// Passed for nav
export const publishItem = {
  url: 'procurementApi/purchaseorder/publish',
  method: 'GET',
  name: 'publishItem',
  filterCriteria: {
    filters: [{
      field: "id",
      operator: "=",
      type: 'id'
    }]
  },
  filterValue: ['id']
};

export const paymentList = {
  url: 'procurementPaymentApi/po/payment/list/:id',
  method: 'GET',
  name: 'paymentList',
  params: ['id'],
  filterCriteria: {
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  }
};

export const paymentDetails = {
  url: 'procurementPaymentApi/po/payment/details/:id',
  method: 'GET',
  name: 'paymentDetails',
  params: ['id']
};

export const preview = {
  url: 'procurementApi/po_invoice/:id/preview',
  method: 'GET',
  name: 'preview',
  params: ['id']
};

export const autoPoId = {
  url: 'procurementApi/purchaseorder/id',
  method: 'GET',
  name: 'autoPoId',
};

export const INVENTORY_VIEW = {
  url: 'fulfilmentApi/fulfilment/inventory/view',
  method: 'GET',
  name: 'inventoryView',
  filterCriteria: {
    filters: [
      {
        field: 'isArchived',
        operator: '=',
        value: false
      }
    ],
    skip: 0,
    limit: 15
  },
  // pagination: 'STOCK_REVIEW'
};

export const STOCK_VIEW = {
  url: 'fulfilmentApi//fulfilment/all/items/stock/history',
  method: 'GET',
  name: 'stockView',
  filterCriteria: {
    filters: [
      {
        field: 'isArchived',
        operator: '=',
        value: false
      }
    ],
    skip: 0,
    limit: 15
  },
  // pagination: 'REVIEW_STOCK_MOVEMENT'
};

export const SCRAPED_VIEW = {
  url: 'fulfilmentApi/fulfilment/all/scraped/items/history',
  method: 'GET',
  name: 'scrapedView',
  filterCriteria: {
    filters: [
      {
        field: 'isArchived',
        operator: '=',
        value: false
      },
      {
        field: "moveToScrap",
        operator: "=",
        value: true
      }
    ],
    skip: 0,
    limit: 15
  },
  // pagination: 'REVIEW_SCRAPPED_STOCK'
};

export const PRODUCT_SERIAL_VIEW = {
  url: 'serialNumbersApi/serial/list',
  method: 'GET',
  name: 'productSerialView',
  filterCriteria: {
    filters: [
      {
        field: 'isArchived',
        operator: '=',
        value: false
      }
    ],
    skip: 0,
    limit: 15
  },
  // pagination: 'REVIEW_PRODUCT_SERIALS'
};

export const FULL_ORDER_VIEW = {
  url: 'fulfilmentApi/fulfilment/tasks/view?prodType=["product", "material"]&',
  method: 'GET',
  name: 'fullOrderView',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  pagination: 'ACTIVE_ORDER_FULL_ORDER_VIEW'
};

// export const FULL_ORDER_VIEW_PIPELINE = {
//   url: 'fulfilmentApi/fulfilment/tasks/view?prodType=["product", "material"]&',
//   method: 'GET',
//   name: 'fullOrderView',
//   filterCriteria: {
//     filters: [{
//       field: 'isArchived',
//       operator: '=',
//       value: false
//     }],
//     defaultSort: [{
//       field: "lastUpdated",
//       order: -1
//     }],
//     skip: 0,
//     limit: 100
//   }
// };

export const ITEM_ORDER_VIEW = {
  url: 'fulfilmentApi/fulfilment/job/items/view?prodType=["product", "material", "rentalMaterial"]&',
  method: 'GET',
  name: 'itemPerOrder',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  pagination: 'ACTIVE_ORDER_PER_ITEM_VIEW'
};

export const PRODUCT_CUSTOM_FORM = {
  url: 'customFieldsApi/customFields?',
  name: 'PRODUCT_CUSTOM_FORM',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      value: 'product_form',
      operator: "="
    }, {
      field: 'status',
      value: 2,
      operator: '='
    }]
  }
};

export const MATERIAL_CUSTOM_FORM = {
  url: 'customFieldsApi/customFields?',
  name: 'PRODUCT_CUSTOM_FORM',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      value: 'material_form',
      operator: "="
    }, {
      field: 'status',
      value: 2,
      operator: '='
    }]
  }
};

export const CUSTOM_FORM_SUPPLIER = {
  url: 'customFieldsApi/customFields?',
  name: 'CUSTOM_FORM_SUPPLIER',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'formTarget',
      value: 'supplier_form',
      operator: "="
    }, {
      field: 'status',
      value: 2,
      operator: '='
    }]
  }
};

export const PURCHASE_ORDER_CUSTOM_FORM = {
  url: 'customFieldsApi/customFields?',
  name: 'PURCHASE_ORDER_CUSTOM_FORM',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      value: 'purchase_order_form',
      operator: "="
    }, {
      field: 'status',
      value: 2,
      operator: '='
    }]
  }
};

export const attributePoliciesProduct = {
  url: 'attributePolicyApi/attributePolicy/list?',
  name: 'attributePolicies',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    },
    {
      field: 'type',
      operator: '=',
      value: 9
    },
    {
      field: 'policyType',
      operator: '=',
      value: 'product'
    }
    ]
  },
};

export const attributePoliciesMaterial = {
  url: 'attributePolicyApi/attributePolicy/list?',
  name: 'attributePolicies',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    },
    {
      field: 'type',
      operator: '=',
      value: 9
    },
    {
      field: 'policyType',
      operator: '=',
      value: 'material'
    }
    ]
  },
};

export const isValidRowProductImport = {
  url: 'inventoryImportApi/inventory/import?importType=70&',
  name: 'fetchInventoryImportDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isValidRow",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const hasWarningsProductImport = {
  url: 'inventoryImportApi/inventory/import?importType=70&',
  name: 'fetchInventoryImportDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasWarnings",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const hasErrorsProductImport = {
  url: 'inventoryImportApi/inventory/import?importType=70&',
  name: 'fetchInventoryImportDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasErrors",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const isValidRowMaterialImport = {
  url: 'inventoryImportApi/inventory/import?importType=71&',
  name: 'fetchInventoryImportDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isValidRow",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const hasWarningsMaterialImport = {
  url: 'inventoryImportApi/inventory/import?importType=71&',
  name: 'fetchInventoryImportDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasWarnings",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const hasErrorsMaterialImport = {
  url: 'inventoryImportApi/inventory/import?importType=71&',
  name: 'fetchInventoryImportDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasErrors",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const getInventoryProductSerial = {
  url: 'serialNumbersImportApi/serials/import',
  name: 'getInventoryProductSerial',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      value: 80,
      operator: "="
    }],
    sort: [],
    skip: 0
  }
};

export const getInventoryMaterialImport = {
  url: 'inventoryImportApi/inventory/import?importType=71&',
  name: 'getInventoryImport',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const getInventoryProductImport = {
  url: 'inventoryImportApi/inventory/import?importType=70&',
  name: 'getInventoryImport',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const QUOTE_LIST = {
  url: 'quoteApi/quotes/list?type=quote&',
  name: 'quotes',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  pagination: 'QUOTE_LIST'
};

export const CUSTOMER_LIST = {
  url: 'contactApi/customers?type=list&',
  method: 'GET',
  name: 'customers',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      "field": "firstName",
      "order": 1
    }]
  },
  pagination: 'CONTACT_LIST'
};

export const RESOURCE_LIST = {
  url: 'resourceApi/resources',
  name: 'resources',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    },
    {
      field: 'status',
      operator: '=',
      value: 2
    }
    ]
  },
  pagination: 'RESOURCE_LIST'
};

export const SKU_SUPPLIER = {
  url: 'supplierApi/skuid/:skuId/suppliers/catalogs',
  name: 'SKU_SUPPLIER',
  method: 'GET',
  params: ['skuId']
};

export const suppliersListOnly = {
  url: 'supplierApi/suppliers?includePo=true&',
  method: 'GET',
  name: 'suppliersListOnly',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'supplierType',
      operator: '=',
      value: 5
    }]
  },
  pagination: 'SUPPLIER_LIST'
};

export const manufacturerList = {
  url: 'supplierApi/suppliers?includePo=true&',
  method: 'GET',
  name: 'manufacturerList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'supplierType',
      operator: '=',
      value: 1
    }]
  },
  pagination: 'SUPPLIER_LIST'
};

export const fetchEmailTemplate = {
  url: 'procurementApi/po/templates',
  name: 'fetchEmailTemplate',
  method: 'GET'
  // filterCriteria: {
  //   "filters": [{
  //     "field": "mdl",
  //     "value": 44,
  //     "operator": "="
  //   }]
  // }
};

export const allTransaction = {
  url: 'quotesPaymentApi/gateway/transactions',
  name: 'allTransaction',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      "field": "transDate",
      "order": -1
    }]
  },
  pagination: 'GATEWAY_TRANSACTIONS'
};

export const payOut = {
  url: 'procurementPaymentApi/po/payment/transaction/list',
  name: 'payOut',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
  },
  pagination: 'PAYOUT_TRANSACTIONS'
};

export const DELIVERY_USAGE = {
  url: 'deliveryZoneApi/delivery/usage',
  name: 'deliveryUsage',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isJob',
      operator: '=',
      value: true
    }],
    skip: 0,
    limit: 15
  },
  pagination: 'DELIVERY_USAGE'
};

export const PRODUCT_STOCK_HISTORY = {
  url: 'fulfilmentApi/fulfilment/items/stock/history?prodType=["product"]&',
  name: 'stockHistory',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "productId",
      operator: "="
    }, {
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "action",
      operator: "in",
      value: ["delivery", "return", "publish", "manual_update"]
    }],
    defaultSort: [{
      field: "createdOn",
      order: -1
    }]
  },
  filterValue: ['id'],
};

export const MATERIAL_STOCK_HISTORY = {
  url: 'fulfilmentApi/fulfilment/items/stock/history?prodType=["material"]&',
  name: 'stockHistory',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "productId",
      operator: "="
    }, {
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "action",
      operator: "in",
      value: ["delivery", "return", "publish", "manual_update"]
    }],
    defaultSort: [{
      field: "createdOn",
      order: -1
    }]
  },
  filterValue: ['id'],
};

export const getInventoryImportPriceProduct = {
  url: 'inventoryImportApi/inventory/import?importType=75&',
  name: 'getInventoryImportPrice',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};
export const getInventoryImportPriceMaterial = {
  url: 'inventoryImportApi/inventory/import?importType=76&',
  name: 'getInventoryImportPrice',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const getInventoryBulkSkuErrorDataSummaryProduct = {
  url: 'inventoryImportApi/inventory/import?importType=75&',
  name: 'getInventoryBulkSkuDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasErrors",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkSkuSuccessDataSummaryProduct = {
  url: 'inventoryImportApi/inventory/import?importType=75&',
  name: 'getInventoryBulkSkuDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isValidRow",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkSkuWarningDataSummaryProduct = {
  url: 'inventoryImportApi/inventory/import?importType=75&',
  name: 'getInventoryBulkSkuDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasWarnings",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkSkuErrorDataSummaryMaterial = {
  url: 'inventoryImportApi/inventory/import?importType=76&',
  name: 'getInventoryBulkSkuDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasErrors",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkSkuSuccessDataSummaryMaterial = {
  url: 'inventoryImportApi/inventory/import?importType=76&',
  name: 'getInventoryBulkSkuDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isValidRow",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkSkuWarningDataSummaryMaterial = {
  url: 'inventoryImportApi/inventory/import?importType=76&',
  name: 'getInventoryBulkSkuDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasWarnings",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryImportProductUpdate = {
  url: 'inventoryImportApi/inventory/import?importType=77&',
  name: 'getInventoryImportUpdate',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const getInventoryImportMaterialUpdate = {
  url: 'inventoryImportApi/inventory/import?importType=78&',
  name: 'getInventoryImportUpdate',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [{
      field: "created_on",
      order: 1
    }],
    skip: 0
  }
};

export const getInventoryBulkProductUpdateErrorDataSummary = {
  url: 'inventoryImportApi/inventory/import?importType=77&',
  name: 'getInventoryBulkUpdateDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasErrors",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkMaterialUpdateErrorDataSummary = {
  url: 'inventoryImportApi/inventory/import?importType=78&',
  name: 'getInventoryBulkUpdateDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasErrors",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkProductUpdateSuccessDataSummary = {
  url: 'inventoryImportApi/inventory/import?importType=77&',
  name: 'getInventoryBulkUpdateDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isValidRow",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkMaterialUpdateSuccessDataSummary = {
  url: 'inventoryImportApi/inventory/import?importType=78&',
  name: 'getInventoryBulkUpdateDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isValidRow",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkProductUpdateWarningDataSummary = {
  url: 'inventoryImportApi/inventory/import?importType=77&',
  name: 'getInventoryBulkUpdateDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasWarnings",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const getInventoryBulkMaterialUpdateWarningDataSummary = {
  url: 'inventoryImportApi/inventory/import?importType=78&',
  name: 'getInventoryBulkUpdateDataSummary',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "hasWarnings",
      operator: "=",
      value: true
    }],
    sort: [{
      field: "created_on",
      order: 1
    }]
  }
};

export const PO_INVOICE_TEMPLATE_LIST = {
  url: 'invoiceApi/po/:id/invoice/template',
  name: 'PO_INVOICE_TEMPLATE_LIST',
  method: 'GET',
  params: ['id']
};

export const PRICE_TYPE_DEPENDANCY_PACKAGE = {
  url: 'inventoryApi/products/:id/priceType?',
  name: 'PRICE_TYPE_DEPENDANCY_PACKAGE',
  method: 'GET',
  params: ['id']
};

export const MATERIAL_RETURN = {
  url: 'rentalApi/fulfilment/rental?prodType=["material"]&stats=yes&',
  method: 'GET',
  name: 'materialReturn',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isRental',
      operator: '!=',
      value: true
    }],
    project: ["name", "sku_id", "customer", "quote_id", "serviceType", "serviceStartDate", "rental_qty", "ffled_number", "total_price", "business_addr_id", "created_on", "rentalQty", "created_by", "images", "avatar", "currency_symbol", "rental_status", "total_tax"],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'MATERIAL_RETURN'
};

export const MATERIAL_RETURN_DETAIL = {
  url: 'rentalApi/fulfilment/rental/:id?prodType=["material"]&',
  name: 'MATERIAL_RETURN_DETAIL',
  method: 'GET',
  params: ['id']
};

export const PACKAGE_PRODUCT_DEPENDANCY = {
  url: 'inventoryApi/product/:id/package/flag/check',
  name: 'PACKAGE_PRODUCT_DEPENDANCY',
  method: 'GET',
  params: ['id']
};

export const PRODUCT_TAX_CLASSIFICATION = {
  url: 'taxClassificationApi/tax/classification?',
  method: 'GET',
  name: 'TAX_CLASSIFICATION',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'classType',
      operator: '=',
      value: 'product'
    }],
    sort: [{
      field: 'label',
      order: 1
    }]
  }
};

export const MATERIAL_TAX_CLASSIFICATION = {
  url: 'taxClassificationApi/tax/classification?',
  method: 'GET',
  name: 'TAX_CLASSIFICATION',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'classType',
      operator: '=',
      value: 'material'
    }]
  }
};

export const accountListQB = {
  url: 'quickBookSettingsApi/settings',
  method: 'GET',
  name: 'accountListQB',
};
export const ACCOUNT_ISSUES = {
  url: 'quickBookSettingsApi/settings/account/issues?',
  method: 'GET',
  name: 'ACCOUNT_ISSUES',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    project: ["name", "currencyMismatch", "depositAccountIssue", "incomeAccountIssue", "multiCurrencyIssue"]
  }
};

export const RETURNED_PRODUCTS = {
  url: 'fulfilmentApi/fulfilment/item/return/history',
  name: 'returnedProduct',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  pagination: 'RETURNED_PRODUCTS'
};

export const supplierDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'supplierDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 7,
      operator: "="
    }]
  },
  filterValue: ['supplierId'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const poDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'poDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 8,
      operator: "="
    }]
  },
  filterValue: ['id'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const supplierDocumentDetail = {
  url: 'assocDocApi/assoc/doc/:docId',
  name: 'documentDetail',
  method: 'GET',
  params: ['docId']
};

export const isInventoryImportDataAvailableMaterial = {
  url: 'inventoryImportApi/inventory/import/has/records?importType=71&',
  name: 'isInventoryImportDataAvailable',
  method: 'GET'
};
export const isInventoryImportDataAvailableProduct = {
  url: 'inventoryImportApi/inventory/import/has/records?importType=70&',
  name: 'isInventoryImportDataAvailable',
  method: 'GET'
};

export const WARRANTY_LIST = {
  url: 'warrantyTrackingApi/warranties?type=quotes&',
  name: 'warrantyList',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'WARRANTY_TRACKING_LIST'
};

export const warrantyTrackingDetail = {
  url: 'warrantyTrackingApi/warranty/purchase/:id?serial=true&',
  method: 'GET',
  name: 'warrantyTrackingDetail',
  params: ['id']
}

export const ALL_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'ALL_ORGANIZATION',
  method: 'GET'
};

export const orderPipelineSettings = {
  url: 'orderServiceLanesApi/order/services/position',
  name: 'orderPipelineSettings',
  method: 'GET'
};

// export const serialPolicies = {
//   url: 'inventoryApi/serialNumAttributePolicy/product/:id',
//   method: 'GET',
//   name: 'serialPolicies',
//   params: ['id']
// }