export default function(app) {
  app.constant('footerMenu', {
      mainboard: {
        cbac: 'main_dashboards',
        target: 'main.dashboard.mydashboard',
        cbacTarget: 'main_dashboard_my_dashboard'
        // mainEntry: true
      },
      // store: 'main.store',
      customers: {
        cbac: 'customers',
        dashBoard: 'main.customers.customersDashboard',
        cbacDashboard: 'customer_dashboards',
        target: 'main.customers.customers',
        cbacTarget: 'customer_list'
      },
      quotes: {
        cbac: 'quotes',
        dashBoard: 'main.quotes.editQuoteDashboard',
        cbacDashboard: 'quote_dashboards',
        target: 'main.quoteList',
        cbacTarget: 'quote_list'
      },
      jobs: {
        cbac: 'jobs',
        dashBoard: 'main.jobs.editQuoteDashboard',
        cbacDashboard: 'job_dashboards',
        target: 'main.jobList',
        cbacTarget: 'job_list',
        salesPage: 'main.jobsSales'
      },
      calendar: {
        cbac: 'calendar',
        target: 'main.calendarEvents',
        cbacTarget: 'calendar_view'
        // mainEntry: true
      },
      teams: {
        cbac: 'teams',
        target: 'main.resources.resources',
        cbacTarget: 'team_resources'
      },
      operations: {
        cbac: 'operations',
        target: 'main.business.inventoryCataloging',
        cbacTarget: 'operation_inventory_catalogs',
        salesPage: 'main.business.inventoryMaterialsSales'
      },
      settings: {
        cbac: 'settings',
        target: 'main.settings.setupProfileDetails',
        cbacTarget: 'setting_profile'
      }
    })

    .constant('sidenavMenu', {
      calendar: {
        activeMenu: false,
        routePrefix: 'main.calendar.',
        modules: [{
          name: 'WORKORDER',
          icon: 'ico-nav-inventory',
          cbac: 'calendar_personal_work_order'
        }]
      },
      operations: {
        activeMenu: false,
        routePrefix: 'main.business.',
        modules: [{
            name: 'INVENTORY',
            icon: 'ico-nav-inventory',
            cbac: 'operation_inventory',
            subModules: [{
              name: 'CATALOGING',
              click: 'inventoryCataloging',
              cbac: 'operation_inventory_catalogs'
            }, {
              name: 'PRODUCTS',
              click: 'inventoryProducts',
              cbac: 'operation_inventory_products'
            }, {
              name: 'MATERIALS',
              click: 'inventoryMaterials',
              cbac: 'operation_inventory_materials',
              salesPage: 'inventoryMaterialsSales'
            }, {
              name: 'CURRENTSTOCK',
              click: 'inventoryCurrentstock',
              cbac: 'operation_inventory_stock_review'
            }]
          },
          {
            name: 'FULFILLMENT',
            icon: 'ico-nav-contact',
            cbac: 'operation_fulfillment',
            subModules: [{
              name: 'SERVICEORDERVIEW',
              click: 'fulfillmentServiceorderview',
              cbac: 'operation_fulfillment'
            }, {
              name: 'ITEMORDERVIEW',
              click: 'fulfillmentItemorderview',
              cbac: 'operation_fulfillment'
            }, {
              name: 'RETURNPRODUCT',
              click: 'fulfillmentReturnproduct',
              cbac: 'operation_fulfillment'
            }, {
              name: 'RENTALRETURN',
              click: 'fulfillmentRentalreturn',
              cbac: 'operation_fulfillment'
            }, {
              name: 'DELIVERYUSAGE',
              click: 'fulfillmentDeliveryusage',
              cbac: 'operation_fulfillment'
            }]
          },
          {
            name: 'WARRANTY',
            displayName: 'Warranty Tracking',
            icon: 'ico-nav-warranty',
            click: 'warranty',
            cbac: 'operation_fulfillment',
            subModules: [{
              name: 'PURCHASE',
              click: 'warrantyPurchase'
            }]
          },
          {
            name: 'OPERATIONS',
            icon: 'ico-nav-financials',
            cbac: 'operation_financials',
            subModules: [{
                name: 'PAYOUT',
                click: 'operationsPayout',
                statsKey: 'payOutTransactions',
                cbac: 'operation_financial_pay_out'
              }, {
                name: 'PAYIN',
                click: 'operationsPayin',
                statsKey: 'payInTransactions',
                cbac: 'operation_financial_pay_in'
              }, {
                name: 'QUICKBOOK',
                click: 'operationsQuickbook',
                statsKey: 'quickBooks',
                cbac: 'operation_financial_quick_book'
              }
              // TODO: Commented temp. Uncomment in next releases when this is done
              // ,
              // {
              //   name: 'EXPENSES',
              //   click: 'operationsExpenses'
              // }, {
              //   name: 'REPORTS',
              //   click: 'operationsReports'
              // }
            ]
          },
          {
            name: 'MEDIA',
            icon: 'ico-nav-media',
            cbac: 'operation_media',
            subModules: [{
              name: 'ALL_MEDIA',
              click: 'mediaAllMedia',
              cbac: 'operation_media'
            }, {
              name: 'ASSOCIATION',
              click: 'mediaAssociation',
              cbac: 'operation_media'
            }, {
              name: 'UPLOAD',
              click: 'mediaUpload',
              cbac: 'operation_media_upload'
            }]
          },
          {
            name: 'SUPPLIERS',
            icon: 'ico-nav-supplier',
            click: 'suppliers',
            cbac: 'operation_suppliers',
            statsKey: 'suppliers',
            subModules: [{
                name: 'DETAIL',
                click: 'suppliersDetail',
                cbac: 'operation_suppliers'
              }, {
                name: 'CATALOG',
                click: 'suppliersCatalog',
                cbac: 'operation_suppliers'
              }, {
                name: 'RELATED_ORDERS',
                click: 'suppliersRelatedOrders',
                cbac: 'operation_supplier_related_orders'
              },
              {
                name: 'DOCUMENTS',
                click: 'suppliersDocuments',
                cbac: 'operation_suppliers_documents'
              },
              // {
              //   name: 'AUTO_ORDERS',
              //   click: 'suppliersAutoOrders'
              // }
            ]
          },
          {
            name: 'PURCHASEORDER',
            icon: 'ico-nav-purchase-order',
            click: 'purchaseorder',
            cbac: 'operation_purchase_orders',
            statsKey: 'purchaseOrders',
            subModules: [{
              name: 'DETAILS',
              click: 'purchaseorderDetails',
              cbac: 'operation_purchase_orders'
            }, {
              name: 'MANAGE_ITEMS',
              click: 'purchaseorderManageItems',
              cbac: 'operation_purchase_order_manage_items'
            }, {
              name: 'PAYMENTPO',
              click: 'purchaseorderPaymentpo',
              cbac: 'operation_purchase_order_payments'
            }, {
              name: 'PUBLISH',
              click: 'purchaseorderPublish',
              cbac: 'operation_purchase_order_publish_items'
            }, {
              name: 'SUBMITPO',
              click: 'purchaseorderSubmitpo',
              cbac: 'operation_purchase_order_submit'
            }, {
              name: 'DOCUMENTS',
              click: 'purchaseorderDocuments',
              cbac: 'operation_purchase_document'
            }]
          }
        ]
      },
      settings: {
        activeMenu: false,
        routePrefix: 'main.settings.',
        modules: [{
            name: 'SETUP',
            displayName: 'Profile',
            icon: 'ico-nav-profile',
            cbac: 'setting_profile',
            subModules: [{
                name: 'PROFILE',
                displayName: 'Owner Profile',
                click: 'setupProfileDetails',
                cbac: 'setting_profile'
              }, {
                name: 'ENTITIES',
                click: 'setupEntitiesView',
                cbac: 'setting_profile_entities'
              }, {
                name: 'LOCATIONS',
                click: 'setupLocations',
                cbac: 'setting_profile_locations'
              }, {
                name: 'INVOICES',
                click: 'setupInvoicesView',
                cbac: 'setting_profile_invoices'
              }, {
                name: 'WORKORDERS',
                click: 'setupWorkordersView',
                cbac: 'setting_profile_work_order'
              }, {
                name: 'PERMISSIONS',
                click: 'setupPermissions',
                cbac: 'setting_profile_permissions'
              }, {
                name: 'MAINTENANCE',
                click: 'setupMaintenance',
                cbac: 'setting_profile_maintenance'
              }
              // TODO: Commented temp. Uncomment in next releases when this is done
              //  {
              //   name: 'ACCOUNT',
              //   click: 'setupAccount'
              // }
              // , {
              //   name: 'TIMER_SETTINGS',
              //   click: 'setupTimerSettings'
              // }
              // , {
              //   name: 'DATA',
              //   click: 'setupData'
              // },
            ]
          }, {
            name: 'STRUCTURE',
            icon: 'ico-nav-structure',
            cbac: 'setting_structure',
            subModules: [{
                name: 'SERVICES',
                click: 'structureServices',
                cbac: 'setting_structure_quote_services'
              }, {
                name: 'SERVICE_RATES',
                click: 'structureServiceRates',
                cbac: 'setting_structure_quote_service_rates'
              }, {
                name: 'LABELS',
                click: 'structureLabels',
                cbac: 'setting_structure_quote_services'
              },{
                name: 'TAXES_ZONE',
                click: 'structureTaxesZone',
                cbac: 'setting_structure_sales_tax'
              }, {
                name: 'BANKING',
                click: 'structureBankingPaymentGatewayList',
                cbac: 'setting_structure_banking'
              }, {
                name: 'COMMUNICATION',
                click: 'structureCommunication',
                cbac: 'setting_structure_communications'
              }, {
                name: 'POLICIES',
                click: 'structurePolicies',
                cbac: 'setting_structure_policy_list'
              }, {
                name: 'DELIVERY_ZONE',
                click: 'structureDeliveryZone',
                cbac: 'setting_structure_delivery_zone'
              }, {
                displayName: 'App Behavior',
                name: 'ALIAS',
                click: 'structureAlias',
                cbac: 'setting_structure_alias'
              }
              //  {
              //   name: 'SERVICES_TYPES',
              //   click: 'structureServicesTypes'
              // },
              //  {
              //   name: 'SUPPORT',
              //   click: 'setupSupport'
              // },
            ]
          },
          {
            name: 'AUTOMATION',
            icon: 'ico-nav-automation',
            cbac: 'setting_automation',
            subModules: [{
              name: 'ACCESS',
              click: 'automationAccess',
              cbac: 'setting_automation_api_access'
            }, {
              name: 'APPLICATIONS',
              click: 'automationApplications',
              cbac: 'setting_automation_applications'
            }, {
              name: 'PROMOTIONS',
              displayName: 'Smart Promotions',
              click: 'automationPromotions',
              cbac: 'setting_structure_promotions'
            }, {
              name: 'NOTIFICATIONS',
              displayName: 'Smart Notifications',
              click: 'automationNotifications',
              cbac: 'setting_automation_notifications'
            }, {
              name: 'GUIDED_ACTIONS',
              displayName: 'Smart Actions',
              click: 'automationGuidedActions',
              cbac: 'setting_automation_guided_actions'
            }]
          }, {
            name: 'DATA',
            icon: 'ico-nav-automation',
            cbac: 'setting_automation',
            subModules: [{
              name: 'DATA_SETS',
              click: 'dataDataSets',
              cbac: 'setting_structure_data_sets'
            }, {
              name: 'EXPORT_DATA',
              click: 'dataExportData',
              cbac: 'setting_structure_data_sets'
            }, {
              name: 'CUSTOM_FORMS',
              displayName: 'Forms',
              click: 'dataCustomForms',
              cbac: 'setting_structure_custom_forms'
            }, {
              name: 'TAG_MANAGEMENT',
              click: 'dataTagManagement',
              cbac: 'setting_structure_tag_management'
            }]
          }
        ]
      },
      customers: {
        activeMenu: false,
        routePrefix: 'main.customers.',
        modules: [{
            name: 'COMPANIES',
            displayName: 'Accounts',
            click: 'companies',
            icon: 'ico-nav-company',
            statsKey: 'companies',
            cbac: 'customer_companies',
            subModules: [{
                name: 'CPROFILE',
                click: 'companiesCprofile',
                cbac: 'customer_companies'
              },
              {
                name: 'ASSOCIATES',
                click: 'companiesAssociates',
                cbac: 'customer_company_associates'
              }, {
                name: 'ADRESSES',
                click: 'companiesAdresses',
                cbac: 'customer_company_associates'
              }, {
                name: 'ACCOUNT',
                displayName: 'Revenue',
                click: 'companiesAccount',
                cbac: 'customer_company_history'
              }
            ]
          },
          {
            name: 'CUSTOMERS',
            displayName: 'Contacts',
            click: 'customers',
            icon: 'ico-nav-customer',
            statsKey: 'customers',
            cbac: 'customer_list',
            subModules: [{
              name: 'DETAIL',
              displayName: 'Details',
              click: 'customersDetail',
              cbac: 'customer_list'
            }, {
              name: 'ASSOCIATES',
              click: 'customersAssociates',
              cbac: 'customer_associates'
            }, {
              name: 'CONVERSATION',
              displayName: 'Notes',
              click: 'customersConversation',
              statsKey: 'conversation',
              cbac: 'customer_conversations'
            }, {
              name: 'ACTION_LIST',
              click: 'customersActionList',
              cbac: 'customer_conversations'
            }, {
              name: 'COMMUNICATE',
              click: 'customersCommunicate',
              cbac: 'customer_communications'
            }, {
              name: 'NURTURING',
              click: 'customersNurturing',
              cbac: 'customer_list'
            }, {
              name: 'HISTORY',
              displayName: 'Revenue',
              click: 'customersHistory',
              cbac: 'customer_history'
            }, {
              name: 'PRODUCTS',
              click: 'customersProducts',
              cbac: 'operation_inventory_products'
            }, {
              name: 'MATERIALS',
              click: 'customersMaterials',
              cbac: 'operation_inventory_materials'
            }, {
              name: 'DOCUMENT',
              click: 'customersDocument',
              cbac: 'customer_history'
            }]
          },
          {
            name: 'LEADGENERATION',
            // click: 'leadgeneration',
            icon: 'ico-nav-leadGen',
            // statsKey: 'leadGeneration',
            cbac: 'customer_lead_generation',
            subModules: [
              // {
              //   name: 'BUSINESSSEARCHDETAILS',
              //   click: 'leadgenerationBusinesssearchdetails',
              //   cbac: 'customer_lead_generation'
              // }
              {
                name: 'SMART_DISPLAY',
                click: 'leadgenerationSmartDisplay',
                cbac: 'customer_lead_generation_smart_display'
              }
            ]
          },
          {
            name: 'SEGMENTATION',
            click: 'segmentation',
            icon: 'ico-nav-segmentation',
            statsKey: 'segmentations',
            cbac: 'customer_segmentations',
            subModules: [{
              name: 'DETAIL',
              displayName: 'Details',
              click: 'segmentationDetail',
              cbac: 'customer_segmentations'
            }, {
              name: 'MANAGE',
              displayName: 'Contacts',
              click: 'segmentationManage',
              cbac: 'customer_segmentation_manage'
            }]
          },
          {
            name: 'CHANNELS',
            click: 'channels',
            icon: 'ico-nav-channel',
            statsKey: 'segChannel',
            cbac: 'customer_conversation_channels',
            subModules: [{
              name: 'POSTS',
              click: 'channelsPosts',
              cbac: 'customer_conversation_channels'
            }, {
              name: 'CONTACTS',
              click: 'channelsCustomers',
              cbac: 'customer_conversation_channels'
            }, {
              name: 'REPORTS',
              click: 'channelsReports',
              cbac: 'customer_conversation_channels'
            }]
          },
          {
            name: 'OPPORTUNITIES',
            icon: 'ico-nav-opportunities',
            statsKey: 'opportunities',
            click: 'opportunities',
            cbac: 'customer_opportunity_boards',
            dashBoard: 'main.customers.opportunitiesDashboard',
            cbacDashboard: 'customer_opportunity_dashboards',
            subModules: [{
                name: 'DETAIL',
                displayName: 'Details',
                click: 'opportunitiesDetail',
                cbac: 'customer_opportunity_cards'
              }, {
                name: 'DOCUMENT',
                click: 'opportunitiesDocument',
                cbac: 'customer_opportunity_documents'
              }, {
                name: 'ACTION_LIST',
                click: 'opportunitiesActionList',
                cbac: 'customer_opportunity_action_items'
              }, {
                name: 'NOTES',
                click: 'opportunitiesNotes',
                cbac: 'customer_opportunity_action_items'
              },
              // {
              //   name: 'TRACKING',
              //   click: 'opportunitiesTracking'
              // }, {
              //   name: 'PROPOSAL',
              //   click: 'opportunitiesProposal'
              // },
              {
                name: 'COMMUNICATE',
                click: 'opportunitiesCommunicate',
                cbac: 'customer_opportunity_communicate'
              }, {
                name: 'HISTORY',
                click: 'opportunitiesHistory',
                cbac: 'customer_opportunity_history'
              }
            ]
          },
          {
            name: 'NURTURING',
            displayName: 'Design Templates',
            icon: 'ico-nav-nurture',
            cbac: 'customer_nurturing',
            subModules: [{
                name: 'DESIGNS',
                click: 'nurturingDesigns',
                cbac: 'customer_nurturing',
                salesPage: 'nurturingDesignsNoAccess'
              }
              // {
              //   name: 'CAMPAIGNS',
              //   click: 'nurturingCampaigns',
              //   cbac: 'customer_nurturing_campaigns'
              // }
            ]
          },
          {
            name: 'CAMPAIGNS',
            icon: 'ico-nav-compaign',
            click: 'campaigns',
            cbac: 'customer_campaigns',
            subModules: [{
                name: 'DETAIL',
                displayName: 'Details',
                click: 'campaignsDetail',
                cbac: 'customer_campaigns'
              },
              {
                name: 'DRIP',
                displayName: 'Drips',
                click: 'campaignsDrip',
                cbac: 'customer_campaigns'
              },
              {
                name: 'ACTION_LIST',
                click: 'campaignsActionList',
                cbac: 'customer_campaign_action_items'
              },
              {
                name: 'EMAIL_REPORT',
                click: 'campaignsEmailReport',
                cbac: 'customer_campaign_action_items'
              }
            ]
          }
          // {
          //   name: 'SUPPORT',
          //   click: 'support',
          //   icon: 'ico-nav-support'
          // },
        ]
      },
      mainboard: {
        routePrefix: 'main.dashboard.',
        modules: [{
          name: 'GETTINGSTARTED',
          icon: 'ico-getting-started',
          click: 'gettingstarted',
          isNoCbac: true
        }, {
          name: 'MYDASHBOARD',
          icon: 'ico-my-dashboard',
          click: 'mydashboard',
          cbac: 'main_dashboard_my_dashboard'
        }, {
          name: 'HIGHLIGHTS',
          icon: 'ico-my-highlights',
          click: 'highlights',
          cbac: 'main_dashboard_highlights'
        }, {
          name: 'MYWORKBOARD',
          icon: 'ico-my-workboard',
          click: 'myworkboard',
          cbac: 'main_dashboard_my_work_board'
        }, {
          name: 'ACTIVITIES',
          icon: 'ico-activities',
          click: 'activities',
          cbac: 'main_dashboard_activities'
        }, {
          name: 'GETSUPPORT',
          icon: 'ico-get-support',
          click: 'getsupport',
          isNoCbac: true
        }]
      },
      teams: {
        activeMenu: false,
        routePrefix: 'main.resources.',
        modules: [{
            name: 'RESOURCES',
            icon: 'ico-nav-resource',
            click: 'resources',
            statsKey: 'resources',
            cbac: 'team_resources',
            subModules: [{
                name: 'DETAILS',
                click: 'resourcesDetails',
                cbac: 'team_resources'
              },
              {
                name: 'INTEGRATION',
                click: 'resourcesIntegration',
                cbac: 'team_integrations'
              }, {
                name: 'ACTIVITIES',
                click: 'resourcesActivities',
                cbac: 'team_history'
              }, {
                name: 'CLOCKED_TIME',
                click: 'resourcesClockedTime',
                cbac: 'team_resources'
              }
            ]
          }, {
            name: 'MASSIGNMENT',
            icon: 'ico-nav-assignment',
            cbac: 'my_assignments',
            subModules: [{
                name: 'USERCLOCK',
                click: 'massignmentUserclock',
                cbac: 'my_assignments_active_clock_in'
              },
              {
                name: 'ALLASSIGNED',
                click: 'massignmentAllassigned',
                cbac: 'my_assignments'
              }
            ]
          }, {
            name: 'SCHEDULING',
            icon: 'ico-nav-scheduling',
            click: 'scheduling',
            statsKey: 'schedule',
            cbac: 'team_scheduling',
            subModules: [{
              name: 'ASSIGNED',
              click: 'schedulingAssigned',
              cbac: 'team_scheduling'
            }, {
              name: 'ALLOCATE',
              click: 'schedulingAllocate',
              cbac: 'team_scheduling_allocated'
            }]
          }, {
            name: 'COMPARE',
            displayName: 'Team Management',
            icon: 'ico-nav-compare',
            cbac: 'team_compare',
            subModules: [
              //   {
              //   name: 'SQUOTE',
              //   click: 'compareSquote',
              //   cbac: 'team_compare_quote_services'
              // }, {
              //   name: 'SJOB',
              //   click: 'compareSjob',
              //   cbac: 'team_compare_job_services'
              // }, {
              //   name: 'GANTT',
              //   displayName: 'Gantt Chart',
              //   click: 'compareGantt',
              //   cbac: 'team_compare_gantt_chart'
              // },
              {
                name: 'TRACKER',
                displayName: 'Service Clock',
                click: 'compareTracker',
                cbac: 'team_compare_service_clock_tracker'
              }, {
                name: 'RESOURCETRACKER',
                displayName: 'Team Timer',
                click: 'compareResourcetracker',
                cbac: 'team_compare_resource_clock_tracker'
              }
            ]
          }, {
            name: 'ACTION',
            icon: 'ico-nav-action',
            click: 'action',
            // statsKey: 'actionWorkboard',
            cbac: 'team_action_items'
          },
          {
            name: 'BCONTACT',
            icon: 'ico-nav-business-contacts',
            click: 'bcontactGroup',
            cbac: 'team_business_contacts',
            subModules: [{
              name: 'GROUP_CONTACT_DETAIL',
              click: 'bcontactGroupContactDetail',
              cbac: 'team_business_contacts'
            }, {
              name: 'GROUP_CONTACT_COMMUNICATE',
              click: 'bcontactGroupContactCommunicate',
              cbac: 'team_business_contacts_communicate'
            }, {
              name: 'GROUP_CONTACT_HISTORY',
              click: 'bcontactGroupContactHistory',
              cbac: 'team_business_contacts_history'
            }, {
              name: 'GROUP_CONTACT_DOCUMENTS',
              click: 'bcontactGroupContactDocuments',
              cbac: 'team_business_contacts_documents'
            }]
          },
          {
            name: 'DOCUMENTS',
            icon: 'ico-nav-documents',
            click: 'documents',
            cbac: 'team_documents'
          },
          {
            name: 'MY_INBOX',
            icon: 'my-inbox',
            click: 'myInbox',
            isBeta: true,
            cbac: 'team_documents'
          },
          {
            name: 'SHARED_INBOX',
            icon: 'shared-inbox',
            click: 'sharedInbox',
            isBeta: true,
            cbac: 'team_documents'
          }
        ]
      },
      quotes: {
        activeMenu: false,
        modules: [{
          name: 'CONTACT',
          icon: 'ico-nav-contact',
          click: 'contact',
          cbac: 'contact'
        }, {
          name: 'GUIDER',
          icon: 'ico-nav-guider',
          click: 'guider',
          cbac: 'guider'
        }, {
          name: 'SERVICES',
          icon: 'ico-nav-services',
          click: 'services',
          statsKey: 'numberOfTasks',
          cbac: 'services',
          subModules: [{
            displayName: 'Schedule',
            name: 'DETAIL',
            click: 'servicesDetail',
            cbac: 'services'
          }, {
            name: 'ESTIMATE',
            click: 'servicesEstimateDetail',
            cbac: 'services'
          }, {
            name: 'PRODUCT',
            click: 'servicesProductDashboard',
            cbac: 'service_products'
          }, {
            name: 'MATERIAL',
            click: 'servicesMaterialDashboard',
            cbac: 'service_materials'
          }, {
            name: 'PROMOTIONS',
            click: 'editServicesPromotion',
            cbac: 'service_promotions'
          }, {
            name: 'WORKORDER',
            click: 'servicesWorkorder',
            cbac: 'service_work_order'
          }]
        }, {
          name: 'MEETINGS',
          icon: 'ico-nav-meeting',
          click: 'meetings',
          statsKey: 'numberOfVisits',
          cbac: 'meetings'
        }, {
          name: 'NOTES',
          icon: 'ico-nav-guider',
          click: 'notes',
          cbac: 'guider'
        }, {
          name: 'ACTION',
          icon: 'ico-nav-action',
          click: 'action',
          // statsKey: 'action',
          cbac: 'action_items'
        }, {
          name: 'DOCUMENT',
          icon: 'ico-nav-contact',
          click: 'document',
          cbac: 'documents'
        }, {
          name: 'SUMMARY',
          icon: 'ico-nav-summary',
          click: 'summary',
          cbac: 'summary'
        }, {
          name: 'SUBMIT',
          icon: 'ico-nav-submit',
          click: 'submit',
          cbac: 'submit'
        }, {
          name: 'PAYMENT',
          icon: 'ico-nav-payment',
          click: 'payment',
          cbac: 'payments'
        }, {
          name: 'HISTORY',
          icon: 'ico-nav-history',
          click: 'history',
          cbac: 'history'
        }]
      }
    });
}