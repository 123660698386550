import remove from 'lodash/remove';
import forEach from 'lodash/forEach';

import columnSettingTemplate from './../../components/popup-view/list-column-setting.html';
import confirmArchiveTemplate from './../../components/popup-view/confirm-dialog.html';

export default function(app) {

  app.factory('ListService', listService);

  // Global variables used efficiently accross the methods
  let currentStateName, listName, primaryKey = {},
    screenConstName = {},
    screenConstants = {},
    listPagesFC,
    listPagesPgn,
    listPagesFilter, switchListCallback;

  function listService(dataServices, serverUrl, $rootScope, localStorageService, messages, $q, $timeout, $mdDialog, $mdSidenav, $state, $stateParams) {

    "ngInject";

    return {
      changeIndivisual: changeIndivisual,
      enableAll: enableAll,
      sortValue: sortValue,
      clearSort: clearSort,
      sortingColumn: sortingColumn,
      removeFilter: removeFilter,
      addFilter: addFilter,
      postList: postList,
      rangeVal: rangeVal,
      filterIndivisual: filterIndivisual,
      doInverseEvent: doInverseEvent,
      preListLoad: preListLoad,
      postListLoad: postListLoad,
      columnSetting: columnSetting,
      assignedDataCopy: assignedDataCopy,
      setPrimaryDataCopy: setPrimaryDataCopy,
      changePageType: changePageType,
      cleanObject: cleanObject,
      switchList: switchList,
      toggleSearch: toggleSearch,
      hideSearch: hideSearch,
      searchOnEnter: searchOnEnter,
      getSearchKeys: getSearchKeys,
      attachTag: attachTag,
      sortObjectKeys: sortObjectKeys,
      init: init,
      openSidenav: openSidenav,
      closeSidenav: closeSidenav,
      finishInitialLoading: finishInitialLoading,
      confirmArchive: confirmArchive
    };

    /**
     *  Open sidenav having list experience
     *
     *  vm: Reference of current controller instance
     *  parentVm: Reference of parent(frame) controller instance,
     *  navID: Unique id of right sidenav
     *  sectionId: Section identifier on whose scroll pagination works
     *  onlySidenav: if there is only one sidenav having list experience in a screen then there is no need to switchlist
     */
    function openSidenav(vm, parentVm, navID, sectionId, onlySidenav, listToSwitchOnClose, onCloseCallback) {
      console.log(listToSwitchOnClose); // remove
      switchList(vm, parentVm, navID, false, true);
      $mdSidenav(navID)
        .open()
        .then(function() {
          $('#scroll-top').addClass('sidenav');
          if ($(sectionId + '[scroll-id=' + navID + ']').scrollTop() > 300) {
            $('#scroll-top').addClass('active');
          }
          $mdSidenav(navID).onClose(function() {
            if (!onlySidenav) {
              if (vm.isListLoading[listName]) {
                switchListCallback = () => {
                  switchList(vm, parentVm, listToSwitchOnClose, false, true);
                };
              } else {
                switchList(vm, parentVm, listToSwitchOnClose, false, true);
              }
            }

            if (angular.isFunction(onCloseCallback)) {
              onCloseCallback();
            }

            $('#scroll-top').removeClass('sidenav');
            $('#scroll-top').removeClass('active');
          });
        });
    }

    /**
     *  Close sidenav having list experience
     *
     *  navID: Unique id of right sidenav
     */
    function closeSidenav(navID) {
      return $mdSidenav(navID).close();
    }

    /**
     *  Activate or deactivate menu icons and dropdown menus
     *
     *  vm: Reference of current controller instance
     */
    function updateHeaderMenuStatus(vm) {
      if (vm.rightSidenavs && vm.rightSidenavs.includes(listName)) {
        return;
      }

      if (vm.dropdownMenu && !vm.doNotUpdateMenu) {
        if (vm.dropdownMenu.items.ACTIVE && vm.dropdownMenu.items.ARCHIVE) {
          vm.dropdownMenu.items[(vm.selectedPageType[listName] == 'active' ? 'ARCHIVE' : 'ACTIVE')].class = '';
          vm.dropdownMenu.items[(vm.selectedPageType[listName] == 'archive' ? 'ARCHIVE' : 'ACTIVE')].class = 'active-menu';
        }
        if (vm.dropdownMenu.items.FILTER) {
          vm.dropdownMenu.items.FILTER.iconClass = vm.filters[listName].length > vm.defaultFc[listName].filters.length;
          vm.dropdownMenu.items.FILTER.class = vm.dropdownMenu.items.FILTER.iconClass ? 'hide-gt-xs active-menu' : 'hide-gt-xs';
        }
      }
    }

    /**
     *  Perform necessary operations on switching tab
     *    1. Save tab name in current screen's and parent's scope
     *    2. Update search variables to respective value or empty string
     *    3. Activate or deactivate menu icons and dropdown menus
     *
     *  vm: Reference of current controller instance
     *  parentVm: Reference of parent(frame) controller instance
     *  _listName: Name of list currently displayed in UI
     *  callback: Callback function to call in various scenarios
     */

    function switchList(vm, parentVm, _listName, callback, setListNameOnly) {
      console.log(_listName, vm.lists[vm.activeTabIndex]); // remove
      parentVm.listName = vm.listName = listName = _listName || vm.lists[vm.activeTabIndex];
      if (!setListNameOnly) {
        updateHeaderMenuStatus(vm);
        if (angular.isFunction(callback)) {
          callback();
        }
      }
    }

    /**
     *  Initialise list screen variables(for storing config of multiple lists) and functions
     *
     *  vm: Reference of current controller instance
     */
    function init(vm) {

      // TODO: Need to test for all scenarios
      if (angular.isUndefined(vm.activeTabIndex)) {
        vm.activeTabIndex = 0;
      }

      // Initialise variables for further processing
      vm.disablePagination = vm.disablePagination || {};
      vm.rowSetting = vm.rowSetting || {};
      vm.sortingColumn = vm.sortingColumn || {};
      vm.sort = vm.sort || {};
      vm.titleSortingOrder = vm.titleSortingOrder || {};
      vm.limitRecords = vm.limitRecords || {};
      vm.filter = vm.filter || {};
      vm.selectedPageType = vm.selectedPageType || {};
      vm.searchText = vm.searchText || {};
      vm.textToSearch = vm.textToSearch || {};
      vm.filters = vm.filters || {};
      vm.isListLoading = vm.isListLoading || {};
      vm.skipRecords = vm.skipRecords || {};
      vm.listCheck = vm.listCheck || {};
      vm.checkedIds = vm.checkedIds || {};
      vm.enableList = vm.enableList || {};

      // Assign necessary list functions to vm
      vm.sortValue = sortValue;
      vm.enableAll = enableAll;
      vm.changeIndivisual = changeIndivisual;
      vm.getMoreList = getMoreList;
      vm.clearSort = clearSort;
      vm.clearFilters = clearFilters;
      vm.switchList = switchList;
    }

    /**
     *  Activate or deactivate header menus and dropdownMenu
     *
     *  vm: Reference of current controller instance
     *  _listName: Name of list to be saved in vm - current instance
     *  initDropdownMenu: function to Initialise dropdown menu
     */
    function finishInitialLoading(vm, _listName, initDropdownMenu) {

      listName = _listName;
      if (vm.isRequiredApiFailed[listName]) {
        init(vm);
        getSavedSettings();
        clearFilters(vm, true);
        let filterCriteria = listPagesFC[currentStateName][listName] || {};
        vm.filters[listName] = filterCriteria.filters && filterCriteria.filters.length ? filterCriteria.filters : vm.filters[listName];
        if (vm.filters[listName][0].field == 'isArchived') {
          vm.selectedPageType[listName] = vm.filters[listName][0].value ? 'archive' : 'active';
        }
        if (angular.isFunction(initDropdownMenu)) {
          initDropdownMenu();
        }
        updateHeaderMenuStatus(vm);
      } else {
        if (angular.isFunction(initDropdownMenu)) {
          initDropdownMenu();
        }
        updateHeaderMenuStatus(vm);
      }
    }

    /**
     *  Get saved settings for retaining sorting, filters, pagination, search and pagetype
     *
     */
    function getSavedSettings() {
      // Criteria for sorting and filtering on page refresh and navigation
      listPagesFC = localStorageService.get('listPagesFC') || {};
      listPagesFC[currentStateName] = listPagesFC[currentStateName] || {};
      listPagesFC[currentStateName][listName] = listPagesFC[currentStateName][listName] || {};

      listPagesFilter = localStorageService.get('listPagesFilter') || {};
      listPagesFilter[currentStateName] = listPagesFilter[currentStateName] || {};
      listPagesFilter[currentStateName][listName] = listPagesFilter[currentStateName][listName] || {};

      listPagesPgn = localStorageService.get('listPagesPgn') || {};
      listPagesPgn[currentStateName] = listPagesPgn[currentStateName] || {};
      listPagesPgn[currentStateName][listName] = listPagesPgn[currentStateName][listName] || {};
    }

    /**
     *  Initialise list screens with sorting, pagination and filters
     *
     *  vm: Reference of current controller instance
     *  _screenConstName: Screen constant name recieved from backend
     *  list: Records loaded by resolver provider during state change.
              Records are loaded before screen load to avoid list rendering after loader disappears
     *  _listName: Name of list to be saved in vm - current instance
     *  _primaryKey: Key that's used for selection of records. Generally it's id
     *  spinnerName: Name of spinner to be started while loading list
     *  callback: Callback function to call in various scenarios
     */
    function assignedDataCopy(vm, _screenConstName, list, _listName, _primaryKey, spinnerName, callback) {

      init(vm);

      // Assign values to global variables
      listName = _listName;
      primaryKey[listName] = _primaryKey;
      currentStateName = $rootScope.currentStateName;

      if (_screenConstName) {
        screenConstName[listName] = _screenConstName;
        screenConstants[listName] = $rootScope.screenConst[screenConstName[listName]];

        // Default values for sorting
        vm.rowSetting[listName] = screenConstants[listName].screenColumns;

        // Sorting columns for internal use
        vm.sortingColumn[listName] = sortingColumn(vm.rowSetting[listName]);
        vm.limitRecords[listName] = screenConstants[listName].screenPagination;

        // Sorting order for showing labels like 1, 2, 3 in tables
        vm.titleSortingOrder[listName] = {};
      } else {
        vm.limitRecords[listName] = vm.defaultFc[listName].limit || 15;
      }

      // Default value for filters
      clearFilters(vm, true);

      // Get saved settings for retaining sorting, filter, pagination, search and pagetype
      getSavedSettings();

      // if (!$stateParams.noRetainFC) {
      let filterCriteria = listPagesFC[currentStateName][listName] || {};

      vm.sort[listName] = filterCriteria.sort || [];
      forEach(vm.sort[listName], function(sortingColumn, index) {
        if (vm.sortingColumn[listName] && vm.sortingColumn[listName][sortingColumn.field]) {
          vm.sortingColumn[listName][sortingColumn.field].select = true;
          vm.titleSortingOrder[listName][sortingColumn.field] = index + 1;
          vm.sortingColumn[listName][sortingColumn.field].order = sortingColumn.order == 1;
        }
      });

      vm.filters[listName] = filterCriteria.filters && filterCriteria.filters.length ? filterCriteria.filters : vm.filters[listName];
      vm.filter[listName] = listPagesFilter[currentStateName][listName] || {};
      if (filterCriteria.search) {
        vm.searchText[listName] = filterCriteria.search;
      }
      // }

      // Page Type - Archive or Active
      if (vm.filters[listName][0] && vm.filters[listName][0].field == 'isArchived') {
        vm.selectedPageType[listName] = vm.filters[listName][0].value ? 'archive' : 'active';
      }

      vm.disablePagination[listName] = false;

      // Initialise list checking, filterCriteria and call API
      setPrimaryDataCopy(vm, 'new');
      preListLoad(vm);
      postListLoad(vm, list, vm.enableList[listName], callback);
    }

    /**
     *  Prepare and return primary data(filterCriteria) needed to call list api.
     *
     *  vm: reference of current controller instance
     *  type: new - load list with skip 0 to limit recieved by screen constant,
              add - load list with skip recordsLength to limit recieved by screen constant
     *  retainLimit: true - load list with skip 0 to limit recieved by user object
                     (no of records already loaded by paginating),
                     false - load list with skip 0 to limit recieved by screen constant
     *  switchPageType: change limit for the current page type based on it's value. true or false
     *                  true - set limit to number recieved by user object (no of records already loaded by paginating)
     */
    function setPrimaryDataCopy(vm, type, retainLimit, switchPageType, noJSON) {

      // Set list variable to empty array if it is not defined
      if (!vm[listName]) {
        vm[listName] = [];
      }

      // Initialise variables needed for record selection and skip(for pagination) for loading list for first time
      if (type == 'new') {
        vm.checkedIds[listName] = {};

        vm.enableList[listName] = [];
        vm.listCheck[listName] = {
          selectEnableAll: false,
          totalEnable: 0
        };

        vm.skipRecords[listName] = 0;
      }

      // Set limit for sorting and filter operations retaining no. of records
      let noOfRecords = vm[listName].length;
      let limitRecords = retainLimit ? (noOfRecords < vm.limitRecords[listName] ? vm.limitRecords[listName] : noOfRecords) : vm.limitRecords[listName];

      // Take limit from localStorage if available while switching page type
      if (switchPageType) {
        limitRecords = listPagesPgn[currentStateName][listName][vm.selectedPageType[listName]] || limitRecords;
      }

      if (vm.filters[listName].length && vm.filters[listName][0].field == 'isArchived') {
        vm.filters[listName][0].value = vm.selectedPageType[listName] == 'archive';
      }

      // Take default sort if user has not sorted records manually
      // vm.sort[listName] - contains sorting criteria done by user
      // vm.defaultFc[listName] - default sort to apply which is received by resolver object
      let sort = vm.sort[listName] && vm.sort[listName].length ? vm.sort[listName] : (vm.defaultFc[listName] && angular.isArray(vm.defaultFc[listName].defaultSort) && vm.defaultFc[listName].defaultSort.length ? vm.defaultFc[listName].defaultSort : []);

      let filterCriteria = {
        filters: vm.filters[listName],
        sort: sort,
        skip: retainLimit ? 0 : vm.skipRecords[listName],
        limit: limitRecords
      };

      // Getting projection if exists
      if (vm.defaultFc[listName] && vm.defaultFc[listName].project) {
        filterCriteria.project = vm.defaultFc[listName].project;
      }

      // Set filterCriteria for saving after API call
      listPagesFC[currentStateName][listName] = filterCriteria;

      return noJSON ? filterCriteria : angular.toJson(filterCriteria);
    }

    // Call list api if it's not failed from resolver provider
    function setFilters(vm, type, retainLimit, switchPageType) {

      if (!vm.isRequiredApiFailed[listName]) {
        vm.setFilters(type, retainLimit, switchPageType);
      }
    }

    /**
     *  Paginate on scroll. Get next records when user scrolls to bottom edge
     *
     *  vm: reference of current controller instance
     */
    function getMoreList(vm) {
      if (listName && vm.lists.indexOf(listName) != -1 && !vm.isListLoading[listName]) {
        vm.disablePagination[listName] = true;
        setFilters(vm, 'add');
      }
    }

    /**
     *  Switch page type from Active to Archive and vice-versa
     *
     *  vm: reference of current controller instance
     *  type: page type selected by user. archive or active
     */
    function changePageType(vm, type) {
      return function() {
        vm.selectedPageType[listName] = type;
        setFilters(vm, 'new', false, true);
      };
    }

    /**
     *  Clear filters other than default filter isArchived
     *
     *  vm: Reference of current controller instance
     *  isNotFetch: Fetch new list if false
     */
    function clearFilters(vm, isNotFetch = false) {
      vm.filters[listName] = vm.defaultFc[listName] ? angular.copy(vm.defaultFc[listName].filters) : [{
        field: 'isArchived',
        operator: '=',
        value: false
      }];

      // Clear filter variable
      vm.filter[listName] = {};

      if (!isNotFetch) {
        setFilters(vm, 'new', true);
      }
    }

    /**
     *  Toggle(hide or show) search toolbar
     *
     *  isSidenav: Boolean value based on from where this function called
     */
    function toggleSearch(isSidenav) {
      if (isSidenav) {
        $('.search-toolbar.sidenav, .search-backdrop.sidenav').fadeToggle();
      } else {
        $('.search-toolbar, .search-backdrop').fadeToggle();
      }
    }

    /**
     *  Hide search toolbar
     *
     *  isSidenav: Boolean value based on from where this function called
     */
    function hideSearch(isSidenav) {
      if (isSidenav) {
        $('.search-toolbar.sidenav, .search-backdrop.sidenav').fadeOut();
      } else {
        $('.search-toolbar, .search-backdrop').fadeOut();
      }
    }

    /**
     *  Perform necessary operations on enter key press in search toolbar
     *  switch icon for search history or new results
     *  set text typed in search toolbar to variable for use in controller
     *
     *  ev: event that provides key typed
     *  vm: reference of current controller instance
     */
    function searchOnEnter(ev, vm, _listName = listName) {
      vm.searchIcon = vm.textToSearch[_listName] ? 'set-a:icon-ico-list-view' : 'set-i:icon-ico-rental';
      if (ev.which == 13 && vm.textToSearch[_listName]) {
        vm.searchText[_listName] = vm.textToSearch[_listName];
        console.log(vm.searchText);
      }
    }

    /**
     *  Get search history or suggestions based on keys typed by user
     *
     *  vm: reference of current controller instance
     */
    function getSearchKeys(vm) {
      return function(_listName = listName) {
        // Convert query to lowercase before api call
        // let textToSearch = vm.textToSearch[listName] ? vm.textToSearch[listName].toLowerCase() : vm.textToSearch[listName];

        console.log(vm.suggestionApi, _listName);
        // call API iff suggestionApi and searchHistoryApiKey exists
        if (vm.suggestionApi && vm.suggestionApi[_listName] && vm.searchHistoryApiKey && vm.searchHistoryApiKey[_listName]) {

          let url;
          if (vm.textToSearch[_listName]) {
            url = vm.suggestionApi[_listName] + '/suggestion?search=' + vm.textToSearch[_listName] + (vm.suggestionApiParams && vm.suggestionApiParams[_listName] ? ('&' + vm.suggestionApiParams[_listName]) : '') + '&';
          } else {
            url = 'searchHistoryApi/history/' + vm.searchHistoryApiKey[_listName] + '?'
          }

          return dataServices.get({
            url: serverUrl.main + url,
            spinner: false
          }).then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.searchIcon = vm.textToSearch[_listName] ? 'set-a:icon-ico-list-view' : 'set-i:icon-ico-rental';
              return data.result.slice(0, 5);
            }
          });

        } else {
          return [];
        }
      };
    }

    /**
     *  Open dialog for configuring table setting of the list
     *
     *  vm: reference of current controller instance,
     *  getList: reference of function to fetch list on changing table settings
     *  scrNameToShowForPgn: used to show a string in popup like one time 8 quotes from server
     *  ev: event that provides location from where pop should open
     */
    function columnSetting(vm, getList, ev) {
      // Open mdDialog
      return function() {
        $mdDialog.show({
          controller: ColumnSettingController, //Controller function name "ColumnSetting"
          controllerAs: 'cs',
          templateUrl: columnSettingTemplate, //Url name
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          //Local variable pass to controller as dependency injection.
          locals: {
            vm: vm,
            screenConstantName: screenConstName[listName],
            getList: getList
          }
        });
      };
    }

    /**
     *  Controller for configuring table settings.
     *  Select columns to be shown and change no. of records to be fetched at a time
     *
     *  $rootScope, $log, serverUrl: dependancies
     *  vm: reference of current controller instance,
     *  screenConstantName: screen constant name recieved from backend
     *  scrNameToShowForPgn: used to show a string in popup like one time 8 quotes from server
     *  getList: reference of function to fetch list on changing table settings
     */
    function ColumnSettingController($rootScope, $log, serverUrl, vm, screenConstantName, getList) {

      "ngInject";

      let cs = this;
      console.log('Screen Constant Name:', screenConstantName);

      cs.isQuoteList = screenConstantName === 'QUOTE_LIST';
      cs.isJob = $rootScope.currentStateName === 'main.jobList';

      // Assign Columns in rowSetting val.
      cs.rowSetting = angular.copy(vm.rowSetting[listName]);
      // Fetch number of chunk data.
      cs.limitRecords = vm.limitRecords[listName] = screenConstants[listName].screenPagination;
      // Save event bind with saveRowSetting.
      cs.saveRowSetting = saveRowSetting;

      function saveRowSetting() {
        let data = $rootScope.screenConst[screenConstantName];
        data.flow = $stateParams.flow ? $stateParams.flow : 'active';
        data.screenColumns = cs.rowSetting;
        data.screenPagination = cs.limitRecords;

        // getList function reference of getAllRecord function, which will call after success save Row setting.
        // xhr call for save column table setting.
        dataServices.put({
            url: serverUrl.common + 'uiSettingApi/uiscreens/' + $rootScope.screenConst[screenConstantName].screenName,
            data: {
              data: angular.toJson(data)
            },
            spinner: false
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              let uiConst = data.result;
              $rootScope.screenConst[uiConst.screenName].screenColumns = uiConst.screenColumns;
              localStorageService.set("uiScreenConst", $rootScope.screenConst);
              vm.rowSetting[listName] = uiConst.screenColumns;
              vm.limitRecords[listName] = uiConst.screenPagination;
              if (getList) {
                getList('new');
              }
              // setFilters(vm, 'add');
            }
          });
      }

      // Cancel event.
      cs.cancel = function() {
        $mdDialog.cancel();
      };
    }

    /**
     *  Controller for configuring table settings.
     *  Select columns to be shown and change no. of records to be fetched at a time
     *
     *  ev: event that provides location from where pop should open
     *  vm: reference of current controller instance,
     *  url: api url to be called to archive or unarchive
     *  result: result of archive api containing dependancies to be shown in popup
     *  isListPage: is the current screen contains list
     *              true - fetch list and clean checkedIds
     *  isListPage: true - clean checkedIds and fetch new list,
     *              false - no list actions(used to archive in non-list pages)
     *  callback: callback function to call in various scenarios
     *  execCallbackInCancel: function to call when user cancels popup
     */
    function confirmArchive(ev, vm, url, result, isListPage, callback, execCallbackInCancel, method, data) {
      $mdDialog.show({
        locals: {
          vm: vm,
          url: url,
          result: result,
          isListPage: isListPage,
          callback: callback,
          execCallbackInCancel: execCallbackInCancel,
          method: method,
          data: data
        },
        controller: confirmArchiveController,
        templateUrl: confirmArchiveTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'cd'
      });
    };

    /**
     *  Archive or unarchive records
     *
     *  ev: event that provides location from where pop should open
     *  vm: reference of current controller instance
     *  url: api url to be called to archive or unarchive
     *  result: result of archive api containing dependancies to be shown in popup
     *  isListPage: true - clean checkedIds and fetch new list,
     *              false - no list actions(used to archive in non-list pages)
     *  callback: callback function to call in various scenarios
     *  execCallbackInCancel: callback function to call when user cancels popup
     *  isCustomPopup: Don't open default confirmation popup. Dependencies are handled in controller
     */
    function confirmArchiveController($scope, $mdDialog, messages, vm, url, result, isListPage, callback, execCallbackInCancel, method, data) {

      "ngInject";

      let cd = this;

      cd.question = vm.question;
      cd.description = vm.description || 'This will effect the following attached: ';

      //   for (var i = 0; i < len; i++) {
      //     cd.description += result[i].count + ' ' + result[i].type.charAt(0).toUpperCase() + result[i].type.substr(1).toLowerCase() + (result[i].count > 1 ? 's' : '') + (i != len - 1 ? ', ' : '.');
      //   }

      angular.forEach(result, function(val, key) {
        if (val.count) {
          cd.description += val.count + ' ' + key.charAt(0).toUpperCase() + key.substr(1).toLowerCase() + (val.count > 1 ? 's, ' : ', ');
        }
      });
      cd.description = cd.description.substr(0, cd.description.length - 2) + '.';

      let dataToSend = {
        type: 'yes'
      };

      if (data) {
        dataToSend.data = data;
      }

      cd.confirm = function() {
        dataServices.post({
            url: url,
            method: method || 'PATCH',
            data: dataToSend,
            spinner: false
          })
          .then(function(response) {
            if (response.data && response.data.response_code == 200) {
              if (isListPage) {
                messages.simpleToast((vm.selectedPageType[listName] == 'active' ? 'A' : 'Una') + 'rchived successfully');
                cleanObject(vm.checkedIds[listName]);
                setFilters(vm, 'new', true);
              }
              if (angular.isFunction(callback)) {
                callback();
              }
              cd.cancel();
            }
            // else {
            //   if (isListPage) {
            //     messages.simpleToast((vm.selectedPageType[listName] == 'active' ? 'A' : 'Una') + 'rchiving failed!', 'danger');
            //   }
            // }
          });
      };

      cd.cancel = function() {
        if (execCallbackInCancel) {
          execCallbackInCancel();
        }
        $mdDialog.cancel();
      };
    }

    /**
     *  Archive or unarchive records
     *
     *  ev: event that provides location from where pop should open
     *  vm: reference of current controller instance
     *  api: api to call
     *  id: id of record to be archived
     *  isListPage: true - clean checkedIds and fetch new list,
     *              false - no list actions(used to archive in non-list pages)
     *  callback: callback function to call in various scenarios
     *  execCallbackInCancel: function to call when user cancels popup
     *  isCustomPopup: Don't open default confirmation popup. Dependencies are handled in controller
     */
    function doInverseEvent(ev, vm, api, id, isListPage = true, callback, execCallbackInCancel, isCustomPopup, data = {}, action, stateParam) {

      let recordId = id || (angular.isObject(vm.checkedIds[listName]) ? Object.keys(vm.checkedIds[listName])[0] : undefined);

      let actionToPerform = action || ((vm.selectedPageType[listName] == 'active' ? '' : 'un') + 'archive');
      let url = serverUrl.main + api + '/' + recordId + '/' + actionToPerform + (stateParam ? stateParam : '');

      if (!recordId) {
        return;
      }
      data.type = 'no';

      return dataServices.patch({
          url: url,
          data: data,
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            if (data.result === true) {
              if (isListPage) {
                cleanObject(vm.checkedIds[listName]);
                setFilters(vm, 'new', true);
                messages.simpleToast((vm.selectedPageType[listName] == 'active' ? 'A' : 'Una') + 'rchived successfully');
              }
              if (angular.isFunction(callback)) {
                callback();
              }
            } else if (isCustomPopup) {
              callback(ev, data.result);
            } else if (data.result) { // && response.result.length
              confirmArchive(ev, vm, url, data.result, isListPage, callback, execCallbackInCancel);
            }
          }
          // else {
          //   messages.simpleToast((vm.selectedPageType[listName] == 'active' ? 'A' : 'Una') + 'rchiving failed!', 'danger');
          // }
        });
    }

    /**
     *  Add or remove filter based on condition
     *
     *  vm: reference of current controller instance
     *  colIndex: Index of the column in screen constants recieved by BE
     *  filterName: Filter model value binded in UI
     *  operator: Operator for filter condition. eg. =, !=, nin, in etc
     */
    function filterIndivisual(vm, colIndex, filterName, operator) {
      if (vm.filter[listName][filterName] != undefined) {
        addFilter(vm, vm.rowSetting[listName][colIndex].columnKey, vm.filter[listName][filterName], operator);
      } else {
        removeFilter(vm, vm.rowSetting[listName][colIndex].columnKey);
      }
    }

    // Range filter will work, for start and end input.
    function rangeVal(vm, colIndex, startFilter, endFilter, rangeOperator, msg, paramType = 'int') {
      // Check input is number or not.
      let sFilter, eFilter;

      if (paramType == 'int') {
        sFilter = isNaN(vm.filter[listName][startFilter]) ? vm.filter[listName][startFilter] : parseInt(vm.filter[listName][startFilter]);
        eFilter = isNaN(vm.filter[listName][endFilter]) ? vm.filter[listName][endFilter] : parseInt(vm.filter[listName][endFilter]);
      } else {
        sFilter = vm.filter[listName][startFilter];
        eFilter = vm.filter[listName][endFilter];
      }

      // If input have only start filter, then make single filter.
      if (sFilter > -1 && eFilter === undefined) {
        addFilter(vm, vm.rowSetting[listName][colIndex].columnKey, sFilter, ">=");
        //  addFilter(vm, filterName, filterValue, operator, type)
      } else if (sFilter === undefined && eFilter > -1) {
        addFilter(vm, vm.rowSetting[listName][colIndex].columnKey, eFilter, "<=");
      }
      // If both input present then, pass 3rd params as array.(range filter is needed here - BE dependancy)
      else if (sFilter > -1 && eFilter > -1) {
        if (sFilter <= eFilter) {
          addFilter(vm, vm.rowSetting[listName][colIndex].columnKey, [sFilter, eFilter], rangeOperator);
        }
        // any start input is greater then
        else {
          messages.nativeAlert(msg);
          removeFilter(vm, vm.rowSetting[listName][colIndex].columnKey);
          vm.filter[listName][startFilter] = undefined;
          vm.filter[listName][endFilter] = undefined;
          return;
        }
      } else {
        removeFilter(vm, vm.rowSetting[listName][colIndex].columnKey);
      }

    }

    // Pre list load, Before fetch record this function call. Some initial formalities will happen.
    function preListLoad(vm) {
      vm.isListLoading[listName] = true;
      vm.skipRecords[listName] = vm.skipRecords[listName] || 0;
    }

    // PostListLoad function call after successful fetch list load.
    function postListLoad(vm, list, enableList, callback) {
      let noOfRecords;
      listPagesFC[currentStateName][listName].skip = 0;

      if (list && list.response_code == 200) {

        vm.data = vm.data || {};
        vm.data[listName] =  angular.isObject(list.result) && list.result.data ? list.result.data : (vm.data[listName] || {});
        // Append fetched list with previous one.
        let listResult = angular.isObject(list.result) && list.result.records ? list.result.records : list.result;

        if (angular.isArray(listResult)) {
          $.merge(vm[listName], listResult);
        }

        noOfRecords = vm[listName].length;

        // Top enable is true or false
        // If enableAll is true, then add as archive to remaining fetched list. Other wise checked box is blank.
        for (let i = vm.skipRecords[listName]; i < noOfRecords; i++) {
          vm.enableList[listName].push({
            id: vm[listName][i][primaryKey[listName]],
            enable: vm.listCheck[listName].selectEnableAll
          });
          if (vm.listCheck[listName].selectEnableAll) {
            vm.checkedIds[listName][vm[listName][i][primaryKey[listName]]] = true;
            vm.listCheck[listName].totalEnable = vm.listCheck[listName].selectEnableAll ? noOfRecords : 0;
          }
        }

        // How many record need to skip for next time.
        // Enable or disable pagination based on condition.
        if (angular.isArray(listResult)) {
          vm.skipRecords[listName] += listResult.length;
          vm.disablePagination[listName] = listResult.length < vm.limitRecords[listName];
        }

      } else {
        noOfRecords = vm[listName].length;
        vm.disablePagination[listName] = true;
      }

      // Save filterCriteria in localStorage to use in refresh and navigation
      listPagesFC[currentStateName][listName].limit = noOfRecords < vm.limitRecords[listName] ? vm.limitRecords[listName] : noOfRecords;

      listPagesFC[currentStateName][listName].search = vm.searchText[listName] || undefined;

      // Do not save default sort
      if (vm.defaultFc[listName] && vm.defaultFc[listName].defaultSort && angular.equals(listPagesFC[currentStateName][listName].sort, vm.defaultFc[listName].defaultSort)) {
        listPagesFC[currentStateName][listName].sort = [];
      }

      localStorageService.set('listPagesFC', listPagesFC);

      listPagesPgn[currentStateName][listName][vm.selectedPageType[listName]] = listPagesFC[currentStateName][listName].limit;
      localStorageService.set('listPagesPgn', listPagesPgn);

      listPagesFilter[currentStateName][listName] = vm.filter[listName];
      localStorageService.set('listPagesFilter', listPagesFilter);

      // Highlight current page type in menu
      updateHeaderMenuStatus(vm);

      // List loading finished. End Blur effect on ui
      vm.isListLoading[listName] = false;

      if (angular.isFunction(callback)) {
        callback();
      }
    }

    // API call from list controllers.
    function postList(vm, url, enableList, spinnerName, addType, callback) {
      preListLoad(vm);
      dataServices.get({
          url: url,
          spinner: !vm.skipRecords[listName],
          spinnerName: spinnerName
        })
        .then(function(list) {
          if (angular.isFunction(switchListCallback)) {
            switchListCallback();
            console.log(listName); // remove
            switchListCallback = undefined;
            vm.isListLoading[listName] = false;
            return;
          }

          if (!addType) {
            remove(vm[listName], function(el) {
              return true;
            });
          }
          console.log('merge', listName, vm.isListLoading[listName]);
          postListLoad(vm, list.data, enableList, callback);
        }, function(error) {
          vm.isRequiredApiFailed[listName] = true;
        });
    }

    // If change in single check box for active/archive.
    function changeIndivisual(vm, index) {
      // Calculate "totalEnable", because this number need to show in top bar.
      // checked event
      if (vm.enableList[listName][index].enable) {
        vm.listCheck[listName].totalEnable++;
        // enableQuote used for calculate keys, when list need to archive/active.
        vm.checkedIds[listName][vm.enableList[listName][index].id] = true;
      } else {
        // un-check event.
        vm.listCheck[listName].totalEnable--;
        delete vm.checkedIds[listName][vm.enableList[listName][index].id];
      }
      // If totalEnable count is equal to list length. then on selectEnable all check box.
      if (vm.enableList[listName] && vm.enableList[listName].length) {
        vm.listCheck[listName].selectEnableAll = vm.listCheck[listName].totalEnable == vm.enableList[listName].length;
      }
    }

    // Click event for enable all checkbox.
    function enableAll(vm) {

      // Loop for all records, they can be enable or disable.
      angular.forEach(vm.enableList[listName], function(val) {
        val.enable = vm.listCheck[listName].selectEnableAll;
        if (vm.listCheck[listName].selectEnableAll) {
          vm.checkedIds[listName][val.id] = true;
        }
      });

      // If select enable is false then clean ids object.
      if (!vm.listCheck[listName].selectEnableAll) {
        cleanObject(vm.checkedIds[listName]);
      }

      if (vm[listName] && vm[listName].length) {
        vm.listCheck[listName].totalEnable = vm.listCheck[listName].selectEnableAll ? vm[listName].length : 0;
      }
    }

    // TODO: Check it's usage and decide what to do
    function cleanObject(objName) {
      Object.keys(objName).forEach(function(key) {
        delete objName[key];
      });
    }

    // Sort records based on a particular key
    function sortValue(vm, title) {
      if (!vm.sortingColumn[listName][title].select && vm.sort[listName].length < 3) {
        vm.sort[listName].push({
          field: title,
          order: vm.sortingColumn[listName][title].order ? -1 : 1
        });
        vm.sortingColumn[listName][title].select = !vm.sortingColumn[listName][title].select;
        vm.titleSortingOrder[listName][title] = vm.sort[listName].length;
        vm.sortingColumn[listName][title].order = true;
        setFilters(vm, 'new', true);
      } else if (vm.sortingColumn[listName][title].select) {
        // this is using now. because have clear sort function.
        angular.forEach(vm.sort[listName], function(value, key) {
          if (title == value.field) {
            value.order = vm.sortingColumn[listName][title].order ? -1 : 1;
          }
        });
        vm.sortingColumn[listName][title].order = !vm.sortingColumn[listName][title].order;
        setFilters(vm, 'new', true);
      } else {
        messages.nativeAlert('MAX_3_COLUMN_CHOOSE_FOR_SORTING.');
      }
    }

    // Clear all sorting objects.
    function clearSort(vm, isFetch = false) {
      vm.sort[listName] = [];
      vm.titleSortingOrder[listName] = {};
      angular.forEach(vm.sortingColumn[listName], function(val) {
        val.select = false;
        val.order = false;
      });
      if (!isFetch) {
        setFilters(vm, 'new', true);
      }
    }

    // Initialize column settings recieved from backend for sorting
    function sortingColumn(columnSettings) {
      let columns = {};
      angular.forEach(columnSettings, function(column) {
        if (column.isSort) {
          columns[column.columnKey] = {
            select: false,
            order: false
          };
        }
      });
      return columns;
    }

    // Clear filters by name recieved in filterName.
    function removeFilter(vm, filterName) {
      angular.forEach(vm.filters[listName], function(value, key) {
        if (value.field == filterName) {
          vm.filters[listName].splice(key, 1);
        }
      });
    }

    // Add particular filter
    function addFilter(vm, filterName, filterValue, operator, type, isRange, paramTypeInt = true) {

      if (filterName == 'status' && angular.isString(filterValue) && paramTypeInt) {
        filterValue = parseInt(filterValue);
      }

      // If filter exists already, remove it before adding it
      if (!isRange) {
        removeFilter(vm, filterName);
      }

      let filter = {
        field: filterName,
        value: filterValue,
        operator: operator
      }

      if (type) {
        filter.type = type;
      }

      vm.filters[listName].push(filter);
    }

    // Move to relevant service
    function attachTag(vm, tags, api) {
      //API accept array of object.
      //Convert array into Object
      let allTags = [];
      for (var i = 0; i < tags.length; i++) {
        allTags.push({
          name: tags[i]
        });
      }

      if (!allTags.length) {
        messages.simpleToast("No Tags");
        return;
      }
      //calling
      dataServices.post({
        url: serverUrl.main + "tagApi/tags/" + api,
        data: {
          id: angular.toJson(Object.keys(vm.checkedIds[listName])),
          tags: angular.toJson(allTags)
        }
      }).then(function(response) {
        if (response.data && response.data.response_code == 200) {
          if (vm.listCheck[listName].totalEnable) {
            messages.simpleToast("Tags Apply Successfully");
          } else {
            messages.simpleToast("Tags Added Successfully");
          }
          vm.bulkTags = [];
          setFilters(vm, 'new', false, false);
        }
      });
    }

    // Move to relevant service
    function sortObjectKeys(obj) {
      return Object.keys(obj).sort().reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
    }
  }
}