import tipsTemplate from '../../pages/quotes/quote-cycle/payment/popups/tips.html';
import processingPayTemplate from '../../pages/quotes/quote-cycle/payment/popups/processingTemplate.html';
import refundPaymentTemplate from '../../pages/quotes/quote-cycle/payment/popups/refundPayment.html';
import addingCCardTemplate from '../../pages/quotes/quote-cycle/payment/popups/add-card.html';
import deletionCardTemplate from '../../pages/quotes/quote-cycle/payment/popups/deletionCard.html';
import manageAutoCharegeTemplate from '../../pages/quotes/quote-cycle/payment/popups/manage-AutoCharge.html';
import recurringManageAutoCyclesTemplate from '../../pages/quotes/quote-cycle/payment/popups/recurring-manageAutoCharge.html';
import paymentEmailSetup from '../../pages/quotes/quote-cycle/payment/popups/payment-email-popup.html';
export default function (app) {
    app.factory("PaymentService", PaymentService);

    function PaymentService(
        dataServices,
        serverUrl,
        $timeout,
        messages,
        maxlength,
        $mdDialog,
        $filter,
        validatorService,
        TimezoneService,
        FileUploadService,
        errorMessage,
        timeList,
        $rootScope,
        ListService,
        imageService,
        $mdSidenav,
        sharedDataService) {
        "ngInject";
        return {
            init: init,
            regularService: regularService,
            recurringService: recurringService,
            fullPaymentService: fullPaymentService,
            partialPaymentService: partialPaymentService,
            fullPaymentRecurringService: fullPaymentRecurringService,
            partialPaymentRecurringService: partialPaymentRecurringService,
            addingCard: addingCard,
            manageAutoCharege: manageAutoCharege,
            recurringManageAutoCharege: recurringManageAutoCharege,
            processing: processing,
            deletionCard: deletionCard,
            refundPayment: refundPayment,
            addingCCard: addingCCard,
            dynamicAutoCharge: dynamicAutoCharge,
            dynamicRecurringAutoCharge: dynamicRecurringAutoCharge,
            changeTab: changeTab,
            checkStatus: checkStatus,
            selectPayment: selectPayment,
            showTipsPopup: showTipsPopup,
            processQuoteInfo: processQuoteInfo,
            processPaymentInfo: processPaymentInfo,
            processRecurringAutoCycles: processRecurringAutoCycles,
            processAutoCycles: processAutoCycles,
            processQuoteServiceInfo: processQuoteServiceInfo,
            processQuoteRecurringServiceInfo: processQuoteRecurringServiceInfo
        }

        function processing(vm) {
            $mdDialog.show({
                locals: {
                    vm: vm
                },
                controller: ProcessingController,
                templateUrl: processingPayTemplate,
                parent: angular.element(document.body),
                controllerAs: 'pc'
            });
        }

        function ProcessingController($scope, $state, $mdDialog, vm, serverUrl, messages, dataServices) {

            let pc = this;

            pc.amount = vm.count;
            pc.confirm = true;
            pc.process = false;
            pc.success = false;
            pc.failed = false;
            pc.totalAmount = parseFloat(vm.tips) + parseFloat(vm.count);
            vm.totalDed = pc.totalAmount;
            pc.currencySymbol = vm.currencySymbol;
            pc.paymentModes = vm.paymentModes;
            pc.paymentMedium = vm.paymentMedium;
            console.log(vm.paymentModes);

            pc.cancel = cancel;

            pc.confirmation = function () {
                if (angular.isObject(vm.signature) && !vm.signature.isEmpty && vm.signature.dataUrl) {
                    console.log(vm.signature);
                    dataServices.upload({
                        url: serverUrl.main + 'mediaApi/upload/base64',
                        data: {
                            data: angular.toJson([{
                                type: 'png',
                                data: vm.signature.dataUrl
                            }])
                        },
                    })
                        .then(function (response) {
                            let data = response.data;
                            if (data && data.response_code == 200) {
                                vm.data.signatures = data.result.success[0].fileId;
                                confirmation();
                            }
                        });
                } else {
                    confirmation();
                }
            };

            function confirmation() {
                pc.failed = false;
                pc.confirm = false;
                pc.process = true;

                if (vm.serviceType == 'regular' || vm.serviceType == 'recurring') {
                    dataServices.post({
                        url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/' + vm.serviceType,
                        spinner: false,
                        data: {
                            data: angular.toJson(vm.data)
                        }
                    })
                        .then(function (response) {
                            let data = response.data;
                            if (data && (data.response_code == 200 || data.response_code == 201)) {
                                pc.currentTransaction = data.result;
                                const result = data.result;

                                if (result.success == false) {
                                    // messages.simpleToast('Validation Failed');
                                    pc.process = false;
                                    pc.failed = true;
                                    if (result.data && result.data.type == 'error') {
                                        pc.errorMsg = result.data.message;
                                    }
                                } else {
                                    pc.process = false;
                                    pc.success = true;
                                    // $rootScope.selectedFooterMenu = 'main.jobList';
                                    if (result.message) {
                                        messages.simpleToast(data.result.message);
                                    }
                                    if (!vm.quoteList) {
                                        $state.go('main.jobs.payment', {
                                            quoteId: vm.quoteId
                                        });
                                    }
                                    // messages.simpleToast('Quote Accepted');
                                }
                            } else {
                                pc.cancel();
                            }
                        });
                }
            }

            function cancel() {
                vm.getTransactions(vm);
                $mdDialog.cancel();
            }

            pc.successHandler = function () {
                cancel();

                vm.paymentNote = '';
                vm.fixedValue = vm.priceTotal = vm.percentageValue = vm.percentagecount = undefined;

                // vm.getTransactions(); // moved this to cancel
                vm.getQuoteRecurringServiceInfo(vm);
                vm.getQuoteServiceInfo(vm);
                vm.getAutoCycles(vm);
                vm.getRecurringAutoCycles(vm);

                if (pc.currentTransaction.consent) {
                    vm.emailPopup(vm, 'paid', pc.currentTransaction.transaction_id);
                }
            };
        }

        function showTipsPopup(vm) {
            $mdDialog.show({
                locals: {
                    vm: vm
                },
                controller: TipsController,
                templateUrl: tipsTemplate,
                parent: angular.element(document.body),
                multiple: true, 
                controllerAs: 'rm'
            });
        }

        function TipsController($scope, $state, $rootScope, validatorService, dateFactory, errorMessage, vm) {

            "ngInject";

            let rm = this;
            rm.errorMessages = errorMessage;
            rm.transDate = new Date();
            rm.sign = function () {
                console.log(rm.signature);
            };

            rm.payAamout = parseFloat(vm.count);
            rm.amount = parseFloat(vm.count);
            rm.tips = 0;
            rm.currencySymbol = vm.currencySymbol;
            rm.customTip = false;
            rm.percentageValueCal = percentageValueCal

            rm.tenPerTips = (10 / 100) * vm.count;
            rm.fifteenTips = (15 / 100) * vm.count;
            rm.twtyTips = (20 / 100) * vm.count;
            rm.calTips = calTips;
            rm.withTipAndPay = validatorService.validateForms(rm, ['formPAY'], withTipAndPay);
            rm.noTips = false;

            rm.cancel = cancel;

            function percentageValueCal() {
                console.log(rm.payAamout);
                console.log(rm.tips);
                if (rm.tips >= 0) {
                    rm.amount = rm.payAamout + rm.tips;
                    console.log(rm.amount);
                }
            }

            function cancel() {
                $mdDialog.cancel();
            }

            function withTipAndPay() {
                // rm.signature = vm.signature = $scope.accept();
                console.log(angular.copy(vm.signature));
                vm.transDate = dateFactory.getEpoche(rm.transDate);

                if (rm.customTip == false) {
                    console.log(rm.noTips);
                    if (rm.noTips) {
                        rm.tips = 0;
                        vm.tips = rm.tips
                        vm.selectPayment(vm)
                        // selectPayment
                    } else {
                        console.log(rm.tips);
                        vm.tips = rm.tips
                        vm.selectPayment(vm)
                    }
                }
                if (rm.customTip) {
                    vm.tips = rm.tips
                    vm.selectPayment(vm);
                }
            }

            function calTips(val) {
                rm.customTip = false;
                rm.customTipsVal = 0;

                if (val == 10) {
                    rm.tips = (10 / 100) * vm.count
                    rm.amount = rm.payAamout + rm.tips;
                    rm.tenHighlight = true;
                    rm.twlight = false
                    rm.fivelight = false
                    rm.customLit = false
                    rm.customLit = false
                    rm.noLight = false;
                }
                if (val == 15) {
                    rm.tips = (15 / 100) * vm.count
                    rm.amount = rm.payAamout + rm.tips;
                    rm.fivelight = true
                    rm.tenHighlight = false
                    rm.twlight = false
                    rm.customLit = false
                    rm.noLight = false;
                }
                if (val == 20) {
                    rm.tips = (20 / 100) * vm.count
                    rm.amount = rm.payAamout + rm.tips;
                    rm.twlight = true
                    rm.tenHighlight = false
                    rm.customLit = false
                    rm.noLight = false;
                    rm.fivelight = false
                }
            }

            rm.enableCustomTip = enableCustomTip;

            function enableCustomTip(val) {
                rm.amount = rm.payAamout;
                console.log(val);
                if (val == 'yes') {
                    rm.customTip = true;
                    rm.customLit = true
                    rm.twlight = false
                    rm.tenHighlight = false
                    rm.noLight = false;
                    rm.fivelight = false
                } else {
                    rm.customTip = false;
                    rm.tips = 0;
                    rm.customLit = false
                    rm.noLight = true;
                    rm.twlight = false
                    rm.tenHighlight = false
                    rm.fivelight = false
                }
            }

        }

        function init(vm) {
            vm.validatePercentage = 0;
            vm.dueAmount = 0;
            vm.validateCard = false;
            vm.cardValidate = false;
            vm.paymentModes = 'credit';
            vm.paymentFull = true;
            vm.paymentPartial = false;
            vm.paymentTypes = 'fullPayment';
            vm.serviceType = 'regular',
            vm.paymentSubtype = 'fixed';
            vm.autoChargeSelect = 'none';
            vm.selectCard = "addingNewCard";
            vm.saveCard = false;
            vm.paymentMed = $rootScope.rObj.records.medium;
            vm.totalPrice = 0;
            vm.totalTax = 0;
            vm.recurringServicelist = [];
            vm.dynamicautoCycles = [];
            vm.autoCycles = [];
            vm.recurringAutoCycles = [];
            vm.dynamicrecurringAutoCycles = [];
            vm.cardIdAutoCycle = [];
            vm.cycleAutoCard = undefined;
            vm.recurringCardIdAutoCycle = [];
            vm.autoChargeLists = [0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 45, 60, 75]
            vm.year = [];
            vm.month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
            let count = 2020;
            for (var i = 0; i < 50; i++) {
                vm.year[i] = count;
                count = count + 1;
            }

            vm.serviceTab = function (vm, maintab) {
                vm.serviceType = maintab;

                if (vm.serviceType == 'regular') {
                    let keepGoing = true;

                    angular.forEach(vm.quoteSeriveInfo, function (service) {
                        if (keepGoing) {
                            if (service.amtDue > 0) {
                                vm.selectID = service.id;
                                vm.selecttask = [service.id];
                                console.log(service.amtDue);
                                vm.dueAmount = parseFloat(service.amtDue);
                                vm.fullPay = parseFloat(service.amtDue);
                                vm.count = parseFloat(service.amtDue);

                                keepGoing = false
                                vm.totalCal(vm, service.totalPrice, service.amtDue, service.id);
                            } else {
                                vm.fullPay = 0
                            }
                        }
                    })
                } else if (vm.serviceType == 'recurring') {
                    let keepGoingRec = true;

                    angular.forEach(vm.recurringServicelist, function (service) {
                        if (keepGoingRec) {
                            if (service.amtDue > 0 && (service.paymentStatus == 3 || service.paymentStatus == 4)) {
                                // vm.recurringServicelist
                                vm.recurringselectID = service.id;
                                vm.chargeRCode = service.id
                                vm.selectRecurringTask = [service.id];
                                console.log(service.amtDue);
                                vm.dueAmount = parseFloat(service.amtDue);
                                vm.fullPay = parseFloat(service.amtDue);
                                vm.count = parseFloat(service.amtDue);
                                keepGoingRec = false
                            } else {
                                vm.recurringselectID = undefined;
                                vm.fullPay = 0
                                vm.selectRecurringTask = undefined;
                            }
                        }

                    });
                }
                if(!vm.quoteList){
                    vm.displaySave();
                }
            }

            vm.emailPopup = function (vm, type, transactionId) {
                $mdDialog.show({
                    locals: {
                        parentVm: vm,
                        emailType: type,
                        transactionId: transactionId
                    },
                    templateUrl: paymentEmailSetup,
                    controller: paymentEmailSetupController,
                    parent: angular.element(document.body),
                    controllerAs: 'vm'
                });
            };

            vm.totalCal = function (vm, total, due, id) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.percentagecount = undefined;

                if (typeof id == 'object') {
                    vm.selecttask = id;
                } else {
                    vm.chargeCode = id
                    vm.selecttask = [];
                    vm.selecttask = [id];
                }
                vm.fullToalPrice = total
                vm.dueAmount = parseFloat(due);
                vm.count = vm.dueAmount;
                vm.fullPay = vm.dueAmount;
            }

            vm.makePayment = (vm, $event) => {
                 $event.stopPropagation(); 
                const fn = validatorService.validateForms(vm, ['formREGULARCREDIT', 'formREGULARCASH', 'formREGULARCHEQUE', 'formREGULAROTHERS', 'formRECURRINGCREDIT', 'formRECURRINGCASH', 'formRECURRINGCHEQUE', 'formRECURRINGOTHERS'], function () { showTipsPopup(vm); });
                fn();
            }

            vm.getQuoteRecurringServiceInfo = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/recurring/service?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            vm.recurringServicelist = data.result;
                            processQuoteRecurringServiceInfo(vm);
                        }
                    });
            };
            vm.getQuoteInfo = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/regular?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            vm.quoteInfo = data.result;
                            processQuoteInfo(vm);
                        }
                    });
            };
            vm.getQuoteServiceInfo = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/regular/service?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            vm.quoteSeriveInfo = data.result;
                            processQuoteServiceInfo(vm);
                        }
                    });
            };
            vm.getAutoCycles = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/regular/auto/cycles?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            vm.autoCycles = data.result;
                            processAutoCycles(vm);
                        }
                    });
            };
            vm.getTransactions = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/payment/transactions/' + vm.quoteId + '?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200 && data.result) {
                            vm.quotesPaymentTranscation = data.result;
                        }
                    });
            };
            vm.getRecurringAutoCycles = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/recurring/auto/cycles?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            vm.recurringAutoCycles = data.result;
                            processRecurringAutoCycles(vm);
                        }
                    });
            };
            vm.getPaymentInfo = function (vm) {
                dataServices.get({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/regular/payment?'
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            vm.paymentInfo = data.result;
                            processPaymentInfo(vm);
                        }
                    });
            };
            vm.fullPayment = function (vm) {
                vm.percentageValue = undefined;
                vm.fixedValue = undefined;
                vm.priceTotal = undefined;
                console.log(vm.selectID);
                vm.fullPay = vm.dueAmount;
                vm.count = vm.dueAmount

                if (vm.selecttask) {
                    vm.paymentFull = true;
                    vm.paymentPartial = false;
                } else {
                    // messages.simpleToast('Please select a service');
                }
            }

            vm.recurringfullPayment = function (vm) {
                vm.percentageValue = undefined;
                vm.fixedValue = undefined;
                vm.priceTotal = undefined;
                console.log(vm.selectID);
                vm.fullPay = vm.dueAmount;

                if (vm.selectRecurringTask) {
                    vm.paymentFull = true;
                    vm.paymentPartial = false;
                } else {
                    // messages.simpleToast('Please select a service');
                }
            }

            //  Selecting a Partial Payment Mode with fixed Value
            vm.partialPayment = function (vm) {
                // vm.fixedRegularValue = undefined;
                // vm.percentageValue = undefined;
                // vm.fixedRegularValue = vm.count;
                vm.priceTotal = vm.count;
                if (vm.selecttask) {
                    // vm.priceTotal = undefined;
                    vm.paymentFull = false;
                    vm.paymentPartial = true;
                } else {
                    // messages.simpleToast('Please select a service');

                }

            }
            vm.recurringPartialPayment = function (vm) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.fullPay = vm.dueAmount;

                if (vm.recurringselectID) {
                    vm.priceTotal = undefined;
                    vm.paymentFull = false;
                    vm.paymentPartial = true;
                } else {
                    // messages.simpleToast('Please select a service');
                }
            }

            vm.selectionCard = function (vm, card, id) {
                console.log(vm.selectCard);
                console.log(vm.addItems);
                if (vm.addItems == id) {
                    vm.addItems = id;
                } else {
                    vm.addItems = id;
                }

            }

            //  Selecting a Partial Payment Mode with Percentage value
            vm.percentPayment = function (vm) {
                vm.fixedValue = undefined;
                vm.percentagecount = undefined;
            };

            vm.recurringPercentPayment = function (vm) {
                vm.fixedValue = undefined;
                vm.percentagecount = undefined;
            }

            // Cleaing autoCharge Selection
            vm.clearAutoCharge = function (vm) {
                vm.autoChargeDate = undefined
            };

            // Creating TaskIDD and calcuationg due amount
            vm.totalCal = function (vm, total, due, id) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.percentagecount = undefined;

                if (typeof id == 'object') {
                    vm.selecttask = id;
                } else {
                    vm.chargeCode = id
                    vm.selecttask = [];
                    vm.selecttask = [id];
                }
                vm.fullToalPrice = total
                vm.dueAmount = parseFloat(due);
                vm.count = vm.dueAmount;
                vm.fullPay = vm.dueAmount;
            }

            vm.totalRecurringCal = function (vm, total, due, id) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.percentagecount = undefined;

                if (typeof id == 'object') {

                    vm.selectRecurringTask = id;
                } else {
                    vm.selectRecurringTask = [];
                    vm.selectRecurringTask = [id];
                }
                vm.fullToalPrice = total
                vm.dueAmount = parseFloat(due);
                vm.count = vm.dueAmount;
                vm.fullPay = vm.dueAmount;
            }

            // calcuating Fixed value from due amount
            vm.fixedValueCal = function (vm, total) {
                vm.percentageval = undefined;
                vm.priceTotal = parseFloat(total)
                // vm.priceTotal = vm.priceTotal.toString();
                vm.count = vm.priceTotal.toFixed(2);
            }

            // calcuating Percentage amount value from due amount
            vm.percentageValueCal = function (vm, percentage) {
                vm.priceTotal = undefined;
                vm.validatePercentage = percentage;
                vm.percentagecount = (vm.dueAmount / 100) * percentage;
                vm.count = vm.percentagecount;
            }

            vm.fetchDate = function (vm, id, price, due) {
                vm.autoChargeid = id;
                vm.selecttask = [id];
                vm.dueAmount = due;
                vm.count = parseFloat(price)
            }

            vm.fixedPercentageNull = function (vm) {
                vm.percentageval = undefined;
                vm.priceTotal = undefined;
                vm.fixedValue = undefined;
            }

            vm.fullPayment = function (vm) {
                vm.percentageValue = undefined;
                vm.fixedValue = undefined;
                vm.priceTotal = undefined;
                console.log(vm.selectID);
                vm.fullPay = vm.dueAmount;
                vm.count = vm.dueAmount

                if (vm.selecttask) {
                    vm.paymentFull = true;
                    vm.paymentPartial = false;
                } else {
                    // messages.simpleToast('Please select a service');
                }
            }

            vm.recurringfullPayment = function (vm) {
                vm.percentageValue = undefined;
                vm.fixedValue = undefined;
                vm.priceTotal = undefined;
                console.log(vm.selectID);
                vm.fullPay = vm.dueAmount;

                if (vm.selectRecurringTask) {
                    vm.paymentFull = true;
                    vm.paymentPartial = false;
                } else {
                    // messages.simpleToast('Please select a service');
                }
            }

            //  Selecting a Partial Payment Mode with fixed Value
            vm.partialPayment = function (vm) {
                // vm.fixedRegularValue = undefined;
                // vm.percentageValue = undefined;
                // vm.fixedRegularValue = vm.count;
                vm.priceTotal = vm.count;
                if (vm.selecttask) {
                    // vm.priceTotal = undefined;
                    vm.paymentFull = false;
                    vm.paymentPartial = true;
                } else {
                    // messages.simpleToast('Please select a service');

                }

            }
            vm.recurringPartialPayment = function (vm) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.fullPay = vm.dueAmount;

                if (vm.recurringselectID) {
                    vm.priceTotal = undefined;
                    vm.paymentFull = false;
                    vm.paymentPartial = true;
                } else {
                    // messages.simpleToast('Please select a service');
                }
            }

            vm.selectionCard = function (vm, card, id) {
                console.log(vm.selectCard);
                console.log(vm.addItems);
                if (vm.addItems == id) {
                    vm.addItems = id;
                } else {
                    vm.addItems = id;
                }

            }

            //  Selecting a Partial Payment Mode with Percentage value
            vm.percentPayment = function (vm) {
                vm.fixedValue = undefined;
                vm.percentagecount = undefined;
            };

            vm.recurringPercentPayment = function (vm) {
                vm.fixedValue = undefined;
                vm.percentagecount = undefined;
            }

            // Cleaing autoCharge Selection
            vm.clearAutoCharge = function (vm) {
                vm.autoChargeDate = undefined
            };

            // Creating TaskIDD and calcuationg due amount
            vm.totalCal = function (vm, total, due, id) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.percentagecount = undefined;

                if (typeof id == 'object') {
                    vm.selecttask = id;
                } else {
                    vm.chargeCode = id
                    vm.selecttask = [];
                    vm.selecttask = [id];
                }
                vm.fullToalPrice = total
                vm.dueAmount = parseFloat(due);
                vm.count = vm.dueAmount;
                vm.fullPay = vm.dueAmount;
            }

            vm.totalRecurringCal = function (vm, total, due, id) {
                vm.fixedValue = undefined;
                vm.percentageValue = undefined;
                vm.percentagecount = undefined;

                if (typeof id == 'object') {

                    vm.selectRecurringTask = id;
                } else {
                    vm.selectRecurringTask = [];
                    vm.selectRecurringTask = [id];
                }
                vm.fullToalPrice = total
                vm.dueAmount = parseFloat(due);
                vm.count = vm.dueAmount;
                vm.fullPay = vm.dueAmount;
            }

            // calcuating Fixed value from due amount
            vm.fixedValueCal = function (vm, total) {
                vm.percentageval = undefined;
                vm.priceTotal = parseFloat(total)
                // vm.priceTotal = vm.priceTotal.toString();
                vm.count = vm.priceTotal.toFixed(2);
            }

            // calcuating Percentage amount value from due amount
            vm.percentageValueCal = function (vm, percentage) {
                vm.priceTotal = undefined;
                vm.validatePercentage = percentage;
                vm.percentagecount = (vm.dueAmount / 100) * percentage;
                vm.count = vm.percentagecount;
            }

            vm.fetchDate = function (vm, id, price, due) {
                vm.autoChargeid = id;
                vm.selecttask = [id];
                vm.dueAmount = due;
                vm.count = parseFloat(price)
            }

            vm.fixedPercentageNull = function (vm) {
                vm.percentageval = undefined;
                vm.priceTotal = undefined;
                vm.fixedValue = undefined;
            }

            vm.goToService = function(serviceId) {
                $state.go('main.' + (vm.contactDetail.isJob ? 'jobs' : 'quotes') + '.servicesDetail', {
                  quoteId: vm.quoteId,
                  serviceId: serviceId
                });
              };
            

        }

        function selectPayment(vm) {

            console.log(vm.tips);

            if (vm.serviceType === 'regular') {
                vm.regularService(vm);
            }
            if (vm.serviceType === 'recurring') {
                vm.recurringService(vm);
            }
            if (vm.serviceType === 'return') {

            }
        }

        function checkStatus(vm, transaction, index) {
            console.log(transaction);
            // if (transaction.refundTransactions) {
            //   for (var i = 0; i < transaction.refundTransactions.length; i++) {
            //     if (i == index) {
            //       vm.transID = transaction.refundTransactions[i].transactionId
            //     }
            //   }
            // }

            vm.data = {
                "transactionId": transaction
            };

            dataServices.post({
                url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/transaction/detail',
                data: {
                    data: angular.toJson(vm.data)
                }
            })
                .then(function (response) {
                    let data = response.data;
                    if (data && data.response_code == 200) {
                        vm.getTransactions(vm);
                    }
                });
        }

        function regularService(vm) {

            if (vm.selecttask) {
                if (vm.paymentTypes == 'fullPayment') {
                    vm.fullPaymentService(vm);
                }
                if (vm.paymentTypes == 'partialPayment' || vm.paymentTypes == 'percentage') {
                    vm.partialPaymentService(vm);
                }
            } else {
                messages.simpleToast("Please pick a Service.");
            }
        }

        function recurringService(vm) {

            if (vm.recurringselectID) {
                if (vm.paymentTypes == 'fullPayment') {
                    vm.fullPaymentRecurringService(vm);
                }
                if (vm.paymentTypes == 'partialPayment' || vm.paymentTypes == 'percentage') {
                    vm.partialPaymentRecurringService(vm);
                }
            } else {
                messages.simpleToast("Please pick a Service.");
            }
        }


        function addingCard(vm) {
            vm.selectYea = "" + vm.selectYear + "";
            vm.selectMont = "" + vm.selectMonth + "";
            vm.expiry = vm.selectMont + "/" + vm.selectYea;
            vm.cardNumbstr = "" + vm.cardNumb
            vm.postCodestr = "" + vm.postCode
            vm.cvvstr = "" + vm.cvv
            vm.creditCard = {
                "firstName": vm.holderName,
                "number": vm.cardNumbstr,
                "expiry": vm.expiry,
                "cvv": vm.cvvstr,
                "billingPostCode": vm.postCodestr,
                "save": vm.saveCard
            }
        }

        function fullPaymentService(vm) {

            if (vm.selectCard == 'addingNewCard') {
                vm.addingCard(vm);
            } else {
                vm.creditCard = vm.selectCard;
            }

            console.log(vm.count, typeof vm.count);
            if (vm.paymentModes == 'credit') {
                vm.count = "" + vm.count;
            } else {
                vm.count = parseFloat(vm.count).toFixed(2);
            }
            console.log(vm.paymentMedium, vm.paymentModes);

            if (vm.paymentModes != "others") {
                vm.paymentMedium = vm.paymentModes
            }

            vm.data = {
                transDate: vm.transDate,
                taskId: vm.selecttask,
                paymentModes: vm.paymentModes,
                paymentMedium: vm.paymentMedium,
                paymentTypes: vm.paymentTypes,
                subType: "fixed",
                servicePaidAmt: vm.count,
                currency: "USD",
                reference: vm.quoteId,
                description: vm.paymentNote,
                tip: vm.tips
            };

            if (vm.paymentModes == 'credit') {
                vm.data.autoCharge = vm.autoChargeSelect;
                vm.data.creditCard = vm.creditCard;
                if (vm.autoChargeSelect != 'none') {
                    vm.data.autoChargeAfter = parseInt(vm.autoChargeDate);
                }
            }

            if (vm.count > 0) {
                vm.processing(vm);
            } else {
                messages.simpleToast('Please check the amount.');
            }
        }

        function partialPaymentService(vm) {
            let percentagevalidate = true;
            let amountValidate = true;
            let validatePer = true;

            if (vm.selectCard == 'addingNewCard') {
                vm.addingCard(vm);
            } else {
                vm.creditCard = vm.selectCard;
            }

            if (vm.paymentModes == 'credit') {
                vm.count = "" + vm.count;
            } else {
                console.log(vm.count, typeof vm.count, parseFloat(vm.count).toFixed(2));
                vm.count = parseFloat(vm.count).toFixed(2);
            }

            vm.selectYea = "" + vm.selectYear + "";
            vm.selectMont = "" + vm.selectMonth + "";
            vm.expiry = vm.selectMont + "/" + vm.selectYea;

            vm.data = {
                transDate: vm.transDate,
                taskId: vm.selecttask,
                paymentModes: vm.paymentModes,
                paymentMedium: vm.paymentMedium,
                paymentTypes: 'partialPayment',
                subType: vm.paymentSubtype,
                servicePaidAmt: vm.count,
                currency: "USD",
                reference: vm.quoteId,
                tip: vm.tips
            }

            if (vm.paymentModes == 'credit') {
                vm.data.creditCard = vm.creditCard;
            } else {
                vm.data.description = vm.paymentNote;
            }

            if (vm.validatePercentage > 101) {
                // messages.simpleToast('Percentage should be less than 100');
                percentagevalidate = false;
                validatePer = false;
            }

            vm.countValidate = parseFloat(vm.count);

            if (isNaN(vm.countValidate)) {
                amountValidate = false;
                validatePer = false;
            }

            if (vm.countValidate > vm.dueAmount) {
                // messages.simpleToast('Amount is more than Due Amount');
                amountValidate = false;
                validatePer = false;
            }

            if (vm.count <= 0) {
                // messages.simpleToast('Amount is more than Due Amount');
                amountValidate = false;
                validatePer = false;
            }

            if (validatePer) {
                vm.processing(vm);
            } else {
                if (!percentagevalidate) {
                    messages.simpleToast('Percentage should be less than 100.');
                }
                if (!amountValidate) {
                    messages.simpleToast('Amount is more than Due Amount or less than 0.');
                }
            }
        }

        function fullPaymentRecurringService(vm) {

            if (vm.selectCard == 'addingNewCard') {
                vm.addingCard(vm);
            } else {
                vm.creditCard = vm.selectCard;
            }

            if (vm.paymentModes == 'credit') {
                vm.count = "" + vm.count;
            } else {
                vm.count = parseFloat(vm.count).toFixed(2);
            }

            vm.data = {
                transDate: vm.transDate,
                taskId: vm.selectRecurringTask,
                paymentModes: vm.paymentModes,
                paymentTypes: vm.paymentTypes,
                subType: "fixed",
                servicePaidAmt: vm.count,
                currency: "USD",
                reference: vm.quoteId,
                description: vm.paymentNote,
                tip: vm.tips
            };

            if (vm.paymentModes == 'credit') {
                vm.data.autoCharge = vm.autoChargeSelect;
                vm.data.creditCard = vm.creditCard;
                if (vm.autoChargeSelect != 'none') {
                    vm.data.autoChargeAfter = parseInt(vm.autoChargeDate);
                }
            }

            if (vm.count > 0) {
                vm.processing(vm);
            } else {
                messages.simpleToast('Please check the amount.');
            }

        }

        function dynamicAutoCharge(vm, autoDate) {

            let url = serverUrl.main + "quotesPaymentApi/quote/" + vm.quoteId + "/regular/" + vm.chargeCode + "/date?service_period=" + vm.autoChargeSelect + "&days=" + vm.autoChargeDate + "&";

            dataServices.get({
                url: url,
                spinner: false
            })
                .then(function (response) {
                    let data = response.data;
                    if (data && (data.response_code == 200 || data.response_code == 201)) {
                        vm.dynamicautoCycles = data.result;
                    }
                });
        }

        function dynamicRecurringAutoCharge(vm, autoDate) {

            let url = serverUrl.main + "quotesPaymentApi/quote/" + vm.quoteId + "/recurring/" + vm.chargeRCode + "/date?service_period=" + vm.autoChargeSelect + "&days=" + vm.autoChargeDate + "&";

            dataServices.get({
                url: url,
                spinner: false
            })
                .then(function (response) {
                    let data = response.data;
                    if (data && (data.response_code == 200 || data.response_code == 201)) {
                        vm.dynamicrecurringAutoCycles = data.result;
                    }
                });
        }

        function partialPaymentRecurringService(vm) {
            let percentagevalidate = true;
            let amountValidate = true;
            let validatePer = true;

            if (vm.selectCard == 'addingNewCard') {
                vm.addingCard(vm);
            } else {
                vm.creditCard = vm.selectCard;
            }

            if (vm.paymentModes == 'credit') {
                vm.count = "" + vm.count;
            } else {
                vm.count = parseFloat(vm.count).toFixed(2);
            }

            vm.selectYea = "" + vm.selectYear + "";
            vm.selectMont = "" + vm.selectMonth + "";
            vm.expiry = vm.selectMont + "/" + vm.selectYea;

            vm.data = {
                transDate: vm.transDate,
                taskId: vm.selectRecurringTask,
                paymentMedium: vm.paymentMedium,
                paymentModes: vm.paymentModes,
                paymentTypes: 'partialPayment',
                subType: vm.paymentSubtype,
                servicePaidAmt: vm.count,
                currency: "USD",
                reference: vm.quoteId,
                description: vm.paymentNote,
                tip: vm.tips
            };

            if (vm.paymentModes == 'credit') {
                vm.data.creditCard = vm.creditCard;
            } else {
                vm.data.paymentMedium = vm.paymentMedium;
            }

            if (vm.validatePercentage > 101) {
                // messages.simpleToast('Percentage should be less than 100');
                percentagevalidate = false;
                validatePer = false;
            }

            vm.countValidate = parseFloat(vm.count);

            if (vm.countValidate > vm.dueAmount) {
                // messages.simpleToast('Amount is more than Due Amount');
                amountValidate = false;
                validatePer = false;
            }

            if (isNaN(vm.countValidate)) {
                amountValidate = false;
                validatePer = false;
            }

            if (vm.count <= 0) {
                // messages.simpleToast('Amount is more than Due Amount');
                amountValidate = false;
                validatePer = false;
            }

            if (validatePer) {
                vm.processing(vm);
            } else {
                if (!percentagevalidate) {
                    messages.simpleToast('Percentage should be less than 100.');
                }
                if (!amountValidate) {
                    messages.simpleToast('Amount is invalid.');
                }
            }

        }

        function changeTab(vm, tab) {
            vm.paymentModes = tab;
            console.log(vm.paymentModes);
        }

        function processPaymentInfo(vm) {
            vm.gataway = vm.paymentInfo.gateway;
            if (vm.gataway == false) {
                changeTab(vm, 'cash');
            } else if (vm.gataway == true) {
                if (vm.paymentInfo.card.data) {
                    vm.cards = vm.paymentInfo.card.data;
                    if (vm.cards.length) {
                        vm.selectCard = vm.cards[0].id;
                        vm.addItems = vm.cards[0].id;
                        // vm.addItems[vm.cards[1].id] = undefined;
                    }
                    let dateObj = new Date();
                    vm.currentmonth = dateObj.getUTCMonth() + 1; //months from 1-12
                    // let day = dateObj.getUTCDate();
                    vm.currentyear = dateObj.getUTCFullYear();
                }
            }
        }

        function processRecurringAutoCycles(vm) {
            angular.forEach(vm.recurringAutoCycles, function (items) {
                vm.recurringCardIdAutoCycle.push(items.cardId)
            });

            vm.recurringCardIdAutoCycle = vm.recurringCardIdAutoCycle.filter(onlyUnique);
            for (var i = 0; i < vm.recurringCardIdAutoCycle.length; i++) {
                vm.recurringcycleAutoCard = vm.recurringCardIdAutoCycle[i];
            }
        }

        function processAutoCycles(vm) {
            angular.forEach(vm.autoCycles, function (items) {
                vm.cardIdAutoCycle.push(items.cardId)
            })
            vm.cardIdAutoCycle = vm.cardIdAutoCycle.filter(onlyUnique);
            for (var i = 0; i < vm.cardIdAutoCycle.length; i++) {
                vm.cycleAutoCard = vm.cardIdAutoCycle[i];
            }
        }

        function processQuoteServiceInfo(vm) {
            vm.service = vm.quoteSeriveInfo;

            if (vm.service.length) {
                vm.currencySymbol = vm.service[0].currencySymbol;
            }

            console.log(vm.currencySymbol);
            if (vm.quoteSeriveInfo.length) {
                let keepGoing = true;
                angular.forEach(vm.quoteSeriveInfo, function (service) {
                    if (keepGoing) {
                        if (service.amtDue > 0) {
                            vm.selectID = service.id;
                            vm.selecttask = [service.id];
                            vm.chargeCode = service.id;
                            vm.dueAmount = parseFloat(service.amtDue);
                            vm.fullPay = parseFloat(service.amtDue);
                            vm.count = parseFloat(service.amtDue);
                            keepGoing = false
                        }
                    }
                });
            } else {
                vm.selectID = undefined;
                vm.selecttask = undefined;
            }
        }

        function processQuoteInfo(vm) {
            vm.regular_service = 0;
            if (vm.quoteInfo.regular_service) {
                vm.regular_service = vm.quoteInfo.regular_service.service.length;
            }

            if (vm.quoteInfo.quoteStatus == 28 || vm.quoteInfo.quoteStatus == 25) {
                $scope.$parent.vm.statusDisabled = true;
            }
            // console.log(vm.quoteInfo);
            vm.postCodee = vm.quoteInfo.billingPostCode
            vm.postCode = parseInt(vm.postCodee);
        }

        function processQuoteRecurringServiceInfo(vm) {
            let keepGoingRec = true;
            angular.forEach(vm.recurringServicelist, function (service) {
                if (keepGoingRec) {
                    if (service.amtDue > 0 && (service.paymentStatus == 3 || service.paymentStatus == 4)) {
                        // vm.recurringServicelist
                        vm.recurringselectID = service.id;
                        vm.selectRecurringTask = [service.id];

                        vm.dueAmount = parseFloat(service.amtDue);
                        vm.fullPay = parseFloat(service.amtDue);
                        vm.count = parseFloat(service.amtDue);
                        keepGoingRec = false;
                    } else {
                        vm.recurringselectID = undefined;
                        vm.selectRecurringTask = undefined;
                    }
                }
            });

            if (vm.recurringServicelist.length) {
                vm.currencySymbol = vm.recurringServicelist[0].currencySymbol;
            }
        }
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        function refundPayment(vm, transaction) {
            $mdDialog.show({
                locals: {
                    transaction: transaction,
                    vm: vm
                },
                controller: RefundPaymentController,
                templateUrl: refundPaymentTemplate,
                parent: angular.element(document.body),
                controllerAs: 'rp'
            });
        }
    
        function RefundPaymentController($scope, $state, $rootScope, $mdDialog, validatorService, transaction, vm) {
    
            let rp = this;
            rp.transaction = transaction;
            console.log(rp.transaction);
            rp.maxTansAMount = rp.transaction.transactionAmount - rp.transaction.total_refunded;
            rp.refundAmount = rp.maxTansAMount;
            rp.paymentTypes = 'fullPayment';
            rp.paymentFull = true;
            rp.cancel = cancel;
            rp.reasonList = $rootScope.rObj.records.ReturnPayments;
    
            rp.currencySymbol = vm.currencySymbol;
            console.log(rp.currencySymbol);
            rp.transactionId = rp.transaction.transactionId;
    
            if (rp.transaction.refundTransactions) {
                if (rp.transaction.refundTransactions.length) {
                    rp.paymentTypes = 'partialPayment';
                }
            }
    
            function cancel() {
                $mdDialog.cancel();
            }
    
            rp.fullPayment = function () {
                rp.paymentFull = true;
                rp.paymentPartial = false;
            };
    
            rp.partialPayment = function () {
                rp.paymentFull = false;
                rp.paymentPartial = true;
            };
            rp.refund = validatorService.validateForms(rp, ['formREFUND'], refund);
            vm.refunding = refunding;
    
            function refund() {
                console.log(rp.paymentTypes);
                if (rp.paymentTypes == 'fullPayment') {
                    vm.data = {
                        refundType: rp.paymentTypes,
                        refundMode: rp.transaction.paymentModes,
                        reason: rp.reason
                    }
                    vm.refunding();
                } else {
    
                    if (rp.transaction.paymentModes == 'credit') {
                        rp.refundAmounts = '' + rp.refundAmount.toFixed(2);
                        vm.data = {
                            refundType: rp.paymentTypes,
                            amount: rp.refundAmounts,
                            refundMode: rp.transaction.paymentModes,
                            reason: rp.reason
                        }
                    } else {
                        rp.refundAmounts = rp.refundAmount;
                        vm.data = {
                            refundType: rp.paymentTypes,
                            amount: rp.refundAmounts,
                            refundMode: rp.transaction.paymentModes,
                            reason: rp.reason
                        }
                    }
                    if (rp.refundAmounts <= rp.maxTansAMount) {
                        // console.log(rp.refundAmounts, rp.maxTansAMount);
                        // return;
                        vm.refunding();
                    } else {
                        messages.simpleToast('Please check the refund amount.');
                    }
                }
    
            };
    
            function refunding() {
                dataServices.post({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/regular/return/' + rp.transactionId,
                    data: {
                        data: angular.toJson(vm.data)
                    }
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && (data.response_code == 200 || data.response_code == 201)) {
                            // messages.simpleToast('Successfully.');
                            cancel();
                            vm.emailPopup(vm, 'refund', rp.transactionId);
                            vm.getTransactions(vm);
                            vm.getQuoteRecurringServiceInfo(vm);
                            vm.getQuoteServiceInfo(vm);
                        } else {
                            cancel();
                        }
                    });
            }
    
        }
    
        function addingCCard() {
            $mdDialog.show({
                locals: {
                    vm: vm
                },
                controller: AddingCCardController,
                templateUrl: addingCCardTemplate,
                parent: angular.element(document.body),
                controllerAs: 'ac'
            });
        }
    
        function AddingCCardController($scope, $state, $mdDialog, validatorService, errorMessage, vm) {
    
            let ac = this;
    
            ac.errorMessages = errorMessage;
            ac.saveCard = validatorService.validateForms(ac, ['formADDCARD'], saveCard);
            ac.cancel = cancel;
            ac.postCode = parseInt(vm.postCodee)
    
            ac.year = [];
            ac.month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    
            let count = 2019;
            for (var i = 0; i < 50; i++) {
                ac.year[i] = count;
                count = count + 1;
            }
    
            ac.checkCardType = function () {
                if (angular.isDefined(ac.cardNumb) && ac.cardNumb.toString().length > 4) {
                    ac.className = Stripe.card.cardType(ac.cardNumb.toString());
                }
            };
    
            function cancel() {
                $mdDialog.cancel();
            }
    
            function saveCard() {
    
                ac.cardData = {
                    "firstname": ac.holderName,
                    "number": ac.cardNumb + '',
                    "expiry": ac.selectMonth + "/" + ac.selectYear,
                    "cvv": ac.cvv + '',
                    "billingPostCode": ac.postCode + ''
                }
    
                dataServices.post({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/card',
                    data: {
                        data: angular.toJson(ac.cardData)
                    }
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && data.response_code == 200) {
                            let result = data.result;
                            if (result.type == 'success') {
                                messages.simpleToast("Card has been added successfully.");
                                ac.cancel();
                                vm.getPaymentInfo();
                            } else if (result.data && result.data.type == 'error' && result.data.message) {
                                messages.simpleToast(result.data.message, 'danger');
                            }
                        }
                    });
            }
        }
    
        function deletionCard(vm, id) {
            $mdDialog.show({
                locals: {
                    id: id,
                    vm: vm
                },
                controller: DeletionCardController,
                templateUrl: deletionCardTemplate,
                parent: angular.element(document.body),
                controllerAs: 'dc'
            });
        }
    
        function DeletionCardController($scope, $state, $mdDialog, vm, serverUrl, id) {
    
            "ngInject";
    
            let dc = this;
            dc.id = id;
            dc.listofDeps = [];
            dc.confirm = confirm;
            dc.cancel = cancel;
            dc.listofDeps = [];
    
            function cancel() {
                $mdDialog.cancel();
            }
    
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
    
            vm.cardData = {
                "creditCard": id
            }
    
            dataServices.delete({
                url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/card/delete?dependency=true',
                data: {
                    data: angular.toJson(vm.cardData)
                }
            })
                .then(function (response) {
                    let data = response.data;
                    if (data && data.response_code == 200) {
                        if (angular.isArray(data.result)) {
                            angular.forEach(data.result, function (items) {
                                dc.listofDeps.push(items.quote_id)
                            })
                            dc.listofDepsU = dc.listofDeps.filter(onlyUnique);
                        }
                    } else {
                        cancel();
                    }
                });
    
            function confirm() {
                dataServices.delete({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/card/delete',
                    data: {
                        data: angular.toJson(vm.cardData)
                    }
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && (data.response_code == 200 || data.response_code == 201)) {
                            messages.simpleToast('Card deleted successfully.');
                            cancel();
                            vm.getPaymentInfo(vm);
                        } else {
                            cancel();
                        }
                    });
            }
        }
    
        function manageAutoCharege(vm, id) {
            $mdDialog.show({
                locals: {
                    vm: vm
                },
                controller: manageAutoCharegeController,
                templateUrl: manageAutoCharegeTemplate,
                parent: angular.element(document.body),
                controllerAs: 'ma'
            });
        }
    
        function manageAutoCharegeController($scope, $state, $mdDialog, vm, serverUrl) {
            var ma = this;
            ma.autoCycles = vm.autoCycles
    
            ma.currencySymbol = vm.currencySymbol;
            console.log(ma.currencySymbol);
    
            ma.cancel = cancel;
            ma.confirm = confirm;
            ma.autoAction = 'cancel';
            ma.autochargeDate = 'start';
            ma.autoChargeLists = [0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 45, 60, 75]
    
            function cancel() {
                $mdDialog.cancel();
            }
    
            function confirm() {
    
                if (ma.autoAction == 'cancel') {
                    ma.data = {
                        "autoAction": ma.autoAction
                    }
    
                }
                if (ma.autoAction == 'reschedule') {
                    ma.data = {
                        "autoAction": ma.autoAction,
                        "autoCharge": ma.autochargeDate,
                        "autoChargeAfter": parseInt(ma.autoCharge)
                    }
                }
    
                dataServices.post({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/regular/auto/manage',
                    spinner: false,
                    data: {
                        data: angular.toJson(ma.data)
                    }
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && (data.response_code == 200 || data.response_code == 201)) {
                            ma.cancel();
                            // $mdDialog.cancel();
                            vm.getAutoCycles();
                            messages.simpleToast('Successfully canceled auto charge.');
                        } else {
                            // $mdDialog.cancel();
                            ma.cancel();

                        }
                    });
            }
    
        }
    
        function recurringManageAutoCharege(vm, id) {
            $mdDialog.show({
                locals: {
                    vm: vm
                },
                controller: RecurringmanageAutoCharegeController,
                templateUrl: recurringManageAutoCyclesTemplate,
                parent: angular.element(document.body),
                controllerAs: 'rm'
            });
        }
    
        function RecurringmanageAutoCharegeController($scope, $state, $mdDialog, vm, serverUrl) {
            var rm = this;
            rm.autoCycles = vm.recurringAutoCycles
    
            // rm.cycleNumber = rm.autoCycles[0];
    
            rm.currencySymbol = vm.currencySymbol;
            console.log(rm.currencySymbol);
    
            rm.autoAction = 'cancel';
            rm.autochargeDate = 'start';
            rm.autoChargeLists = [0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 45, 60, 75]
            // angular.forEach(rm.autoCycles, function(autoCycles) {
            //   rm.cycleNumber.push(autoCycles.cycleNumber);
            // });
            rm.cycleNumber = rm.autoCycles[0].id;
            rm.cancel = cancel;
            rm.confirm = confirm;
    
            function cancel() {
                $mdDialog.cancel();
            }
    
            function confirm() {
                // vm.recurringCancelID =
                if (rm.autoAction == 'cancel') {
                    rm.data = {
                        'autoAction': rm.autoAction,
                        'taskId': [
                            rm.cycleNumber
                        ]
                    }
                }
                if (rm.autoAction == 'reschedule') {
                    rm.data = {
                        'autoAction': rm.autoAction,
                        'taskId': [
                            rm.cycleNumber
                        ],
                        'autoCharge': rm.autochargeDate,
                        'autoChargeAfter': parseInt(rm.autoCharge)
                    }
                }
    
                dataServices.post({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + vm.quoteId + '/recurring/auto/manage',
                    spinner: false,
                    data: {
                        data: angular.toJson(rm.data)
                    }
                })
                    .then(function (response) {
                        let data = response.data;
                        if (data && (data.response_code == 200 || data.response_code == 201)) {
                            rm.cancel();
                            vm.getRecurringAutoCycles();
                            messages.simpleToast('Successfully canceled auto charge.');
                        } else {
                            rm.cancel();
                        }
    
                    });
            }
        }
    
        function paymentEmailSetupController(FileUploadService, EmailService, messages, $filter, $timeout, $scope, $mdDialog, $mdSidenav, $rootScope, validatorService, maxlength, parentVm, emailType, transactionId) {
    
            'ngInject';
    
            let vm = this;
    
            vm.maxlength = maxlength;
            vm.allToEmail = [];
            vm.detail = {};
    
            EmailService.init(vm);
            FileUploadService.init(vm, 'data');
    
            vm.allToEmail.push({
                name: $filter('getFullName')(parentVm.contactDetail.contact),
                email: parentVm.contactDetail.contact.email[0].id,
                avatar: parentVm.contactDetail.contact.avatar,
                images: parentVm.contactDetail.contact.images,
                type: 'contacts'
            });
    
            vm.allToEmail.push({
                name: $filter('getFullName')(parentVm.contactDetail.salesAgentIdINFO),
                email: parentVm.contactDetail.salesAgentIdINFO.email[0].value,
                avatar: parentVm.contactDetail.salesAgentIdINFO.avatar,
                images: parentVm.contactDetail.salesAgentIdINFO.images,
                type: 'resources'
            });
    
            function fetchTemplate() {
                // START : EmailType can be paid or refund
                let filterCriteria = {
                    filters: [{
                        field: 'isArchived',
                        operator: '=',
                        value: false
                    }, {
                        field: 'modelName',
                        operator: '=',
                        value: 56
                    }, {
                        field: 'type',
                        operator: '=',
                        value: emailType
                    }],
                };
    
                dataServices.get({
                    url: serverUrl.main + 'emailTemplateApi/email/template/parse/render?modelName=quote_payment&modelId=' + transactionId + '&locationId=' + parentVm.contactDetail.addressId + '&filterCriteria=' + angular.toJson(filterCriteria) + '&'
                }).then(function (response) {
                    if (response && response.data && response.data.result) {
                        let selectedTemplate = response.data.result;
    
                        vm.detail.subject = selectedTemplate ? selectedTemplate.emailSubject : '';
                        vm.detail.emailContent = selectedTemplate ? selectedTemplate.emailBody : '';
                    }
                });
            }
    
            $timeout(function () {
                fetchTemplate();
            }, 0);
    
            vm.checkSpamAndSendEmail = function () {
                EmailService.checkSpam(vm, vm.detail.emailContent, FileUploadService.uploadFilesAndSave(vm, sendEmail));
            };
    
            function sendEmail() {
                // If there have dropbox and google drive files then merge into one var.
                let email = [];
                for (let i = 0; i < vm.allToEmail.length; i++) {
                    email.push(vm.allToEmail[i].email);
                    console.log(vm.allToEmail[i])
                }
                if (!vm.allToEmail.length) {
                    messages.simpleToast('Email can not be blank.');
                    return;
    
                }
                if (!vm.detail.subject || vm.detail.subject == '') {
                    messages.simpleToast('Email Subject can not be blank.');
                    return;
                }
    
                let data = {
                    to: email,
                    subject: vm.detail.subject,
                    body: vm.detail.emailContent,
                    type: emailType,
                    transactionId: transactionId
                };
    
                if (vm.data.attachments) {
                    data.attachments = vm.data.attachments;
                }
    
                dataServices.post({
                    url: serverUrl.main + 'quotesPaymentApi/quote/' + parentVm.quoteId + '/transaction/email',
                    data: {
                        data: angular.toJson(data)
                    }
                })
                    .then(function (response) {
                        if (response.data && response.data.response_code == 200) {
                            vm.cancel();
                            messages.simpleToast('Email sent successfully.');
                        }
                    });
            }
    
            EmailService.loadContacts(vm, 'email-payment-spinner');
    
            vm.toggleRight = FileUploadService.buildToggler(vm, 'fileSidebar');
    
            vm.isCloseRight = function () {
                return $mdSidenav('fileSidebar').close();
            };
    
            vm.cancel = function () {
                parentVm.getQuoteInfo(parentVm);
                $mdDialog.cancel();
            };
        }
    }
    
}
