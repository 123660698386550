import NavigationService from './navigation.service';
import FrameService from './frame.service';
import authService from './auth.service';
import authInterceptor from './auth.interceptor';
import ErrorHandler from './error-handler.service';
import ipDataService from './ipData.services';
import sharedDataService from './sharedData.service';
import offlineService from './offline.service';
import unsavedChangesService from './unsaved-changes.service';
import businessConfig from './business.config.services';
import dataServices from './data.services';
// import idleService from './idle.service';
import spinnerLoader from './spinner.loader';
import commonService from './common.services';
import ListService from './list.service';
import messages from './messages.service';
import validator from './validator.service';
import dateService from './date.services';
import contactData from './contactData.services';
import imageService from './image.services';
import objSave from './setGetObj.factory';
import bobjMap from './bobj.map.service';
import locale from './locale.service';
import dynamicLoadModules from './dynamic.load.services';
import searchTag from './searchTag.service';
import catalogService from './catalog.service';
import CustomFormService from './CustomForm.service';
import FreedomFormService from './FreedomForm.service';
import FileUploadService from './FileUpload.service';
import CFFileUploadService from './CFFileUpload.service';
import FFFileUploadService from './FFFileUpload.service';
import EmailService from './Email.service';
import GraphService from './graph.service';
import DashboardService from './dashboard.service';
import DeviceDetectionService from './device-detection.service';
import TourService from './Tour.service';
import AlertService from './Alert.service';
import TemplateService from './template.service';
import ActionService from './action.service';
import CopyService from './copy.service';
import TimezoneService from './Timezone.service';
import ScheduleService from './schedule.service';
import ProtipsService from './Protips.service';
import QuickbookService from './quickbook.service';
import AttachDocumentService from './AttachDocument.service';
import PackageTriggerService from './PackageTrigger.service';
import FirebaseMessagingService from './firebase-messaging.service';
import EmailThrottleService from './emailThrottle.service';
import AddressService from './address.service';
import AttachCompanyAddressService from './attachCompanyAddressService.service';
import QuillService from './quill.service';
import StarterService from './starter.service';
import OpenAIService from './OpenAI.service';
import CartDataService from './cartData.service';
import PaymentService from './payment.service';
import WorkOrderService from './quoteWorkOrder.service';

export default function(app) {
  NavigationService(app);
  FrameService(app);
  authService(app);
  authInterceptor(app);
  ErrorHandler(app);
  ipDataService(app);
  sharedDataService(app);
  offlineService(app);
  unsavedChangesService(app);
  businessConfig(app);
  dataServices(app);
  // idleService(app);
  spinnerLoader(app);
  commonService(app);
  ListService(app);
  messages(app);
  validator(app);
  dateService(app);
  contactData(app);
  imageService(app);
  objSave(app);
  bobjMap(app);
  locale(app);
  dynamicLoadModules(app);
  searchTag(app);
  catalogService(app);
  CustomFormService(app);
  FreedomFormService(app);
  FileUploadService(app);
  CFFileUploadService(app);
  FFFileUploadService(app);
  EmailService(app);
  GraphService(app);
  DashboardService(app);
  DeviceDetectionService(app);
  TourService(app);
  AlertService(app);
  TemplateService(app);
  ActionService(app);
  CopyService(app);
  TimezoneService(app);
  ScheduleService(app);
  ProtipsService(app);
  QuickbookService(app);
  AttachDocumentService(app);
  PackageTriggerService(app);
  FirebaseMessagingService(app);
  EmailThrottleService(app);
  AddressService(app);
  AttachCompanyAddressService(app);
  QuillService(app);
  StarterService(app);
  OpenAIService(app);
  CartDataService(app);
  PaymentService(app);
  WorkOrderService(app)
}