import * as markerjs2 from "markerjs2";
import findIndex from 'lodash/findIndex';
import emailResourcesTemplate from '../../components/update-workorder/email-resources-popup.html';
import appendixUpdateTemplate from '../../components/update-workorder/wo-appendix-update-popup.html';
import confirmDialogTemplate from '../../components/popup-view/confirm-dialog.html';
import signatureTemplate from '../../components/update-workorder/signature-wo.html';
export default function (app) {
    app.factory("WorkOrderService", WorkOrderService);

    function WorkOrderService(
        dataServices,
        serverUrl,
        $timeout,
        messages,
        maxlength,
        $mdDialog,
        $filter,
        validatorService,
        TimezoneService,
        FileUploadService,
        errorMessage,
        timeList,
        $rootScope,
        $state,
        ListService,
        imageService,
        $mdSidenav,
        sharedDataService,
        AttachDocumentService, 
        FreedomFormService,
        ScheduleService, 
        statusIcons) {
        "ngInject";
        return {
            init: init,
            processService: processService,
            getAppendix: getAppendix,
            saveFreedomForm: saveFreedomForm,
            confirmRemoveFF: confirmRemoveFF,
            editComment: editComment,
            cancelEdit: cancelEdit,
            saveComment: saveComment,
            addComment: addComment,
            deleteComment: deleteComment,
            saveComments: saveComments,
            signaturePanel: signaturePanel,
            attachAppendix: attachAppendix,
            appendixUpdatePopup: appendixUpdatePopup,
            confirmRemoveAppendix: confirmRemoveAppendix,
          }

          function confirmRemoveAppendix(vm, ev, id, isSidenav) {
            $mdDialog.show({
              locals: {
                vm: vm,
                id: id,
                isSidenav: isSidenav
              },
              controller: RemoveAppendixController,
              templateUrl: confirmDialogTemplate,
              multiple: true,
              parent: angular.element(document.body),
              targetEvent: ev,
              controllerAs: 'cd'
            });
          };
        
          function RemoveAppendixController($scope, $mdDialog, vm, id, isSidenav) {
        
            'ngInject';
        
            let cd = this;
            $scope.vm = vm;
        
            cd.question = 'Do you want to continue?'
            cd.description = 'You are removing instructions. All your current data, if any, will be lost permanently.';
        
            cd.confirm = confirm;
        
            function confirm() {
              delete vm.selectedAppendix[id];
              vm.attachAppendix(vm, isSidenav);
              cd.cancel();
            }
        
            cd.cancel = function() {
              $mdDialog.cancel();
            };
          }

          function appendixUpdatePopup(vm, ev, appendix) {
            $mdDialog.show({
              controller: updateAppendixController,
              templateUrl: appendixUpdateTemplate,
              parent: angular.element(document.body),
              controllerAs: 'ua',
              targetEvent: ev,
              multiple: true,
              clickOutsideToClose: false,
              locals: {
                vm: vm,
                appendix: appendix
              }
            });
          };
        
          function updateAppendixController($scope, $mdDialog, errorMessage, validatorService, vm, appendix) {
        
            'ngInject';
        
            let ua = this;
            ua.appendix = angular.copy(appendix);
            ua.cancel = cancel;
            ua.errorMessages = errorMessage;
            ua.confirm = validatorService.validateForm(ua, confirm);
        
            function confirm() {
              vm.selectedAppendix[appendix.id] = ua.appendix;
              vm.attachAppendix(vm);
              ua.cancel();
            }
        
            function cancel() {
              $mdDialog.cancel();
            }
          }

          function signaturePanel(vm, ev, signFor) {
            console.log("signature panel");
            if (!vm.hasWriteAccess) {
              return;
            }
            console.log(vm)
            const type = signFor == 1 ? 'primary' : 'secondary';
        
            $mdDialog.show({
              templateUrl: signatureTemplate,
              controller: SignatureController,
              controllerAs: 'cd',
              parent: angular.element(document.body),
              targetEvent: ev,
              multiple: true,
              clickOutsideToClose: false,
              locals: {
                vm: vm,
                type: type,
                imageData: vm.service.workOrderSign && vm.service.workOrderSign[type] || undefined
              }
            });
          }
          function SignatureController($scope, serverUrl, messages, Upload, $mdDialog, dataServices, vm, type, imageData) {

            "ngInject";
        
            let cd = this;
            console.log(type);
        
            cd.cancel = cancel;
            cd.save = save;
            cd.reset = reset;
            cd.parentVm = vm;
        
            function assignedData() {
              if (imageData && imageData.webUrl) {
                Upload.urlToBlob(imageData.webUrl).then(function(blob) {
                  Upload.base64DataUrl(blob).then(function(base64) {
                    cd.dataUrl = $scope.dataUrl = base64;
                  });
                });
              }
            }
        
            assignedData();
        
            function save() {
              let signature = $scope.accept();
              console.log(signature);
        
              if (!cd.dataUrl && signature.isEmpty) {
                messages.simpleToast('Signature is not available for update.');
                return;
              }
        
              dataServices.upload({
                  url: serverUrl.main + 'mediaApi/upload/base64',
                  data: {
                    data: angular.toJson([{
                      type: 'png',
                      data: $scope.dataUrl
                    }])
                  }
                })
                .then(function(response) {
                  let data = response.data;
                  if (data && data.response_code == 200) {
                    let dataToSend = {
                      serviceId: vm.serviceId,
                      taskScheduleType: vm.service.taskScheduleType
                    };
        
                    if (signature.isEmpty) {
                      dataToSend['remove'] = [type];
                    } else {
                      dataToSend[type] = data.result.success[0].fileId;
                    }
        
                    dataServices.patch({
                      url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/workOrder/sign',
                      data: {
                        data: angular.toJson(dataToSend)
                      }
                    }).then(function(result) {
                      vm.getService(vm);
                      cd.cancel();
                      messages.simpleToast('Signature Updated Successfully!');
                    });
                  }
                });
            }
        
            function cancel() {
              $mdDialog.cancel();
            }
        
            function reset() {
              $scope.dataUrl = angular.copy(cd.dataUrl);
            }
          }

          function init(vm) {
            const currentStateName = vm.isJob ? "main.jobs.servicesWorkorders" : "main.quotes.servicesWorkorder";           
            console.log(currentStateName)
            vm.activeTabIndex = vm.tabIndex || 4;
            vm.isDisabledFF = true;
            FreedomFormService.init(vm);
            let imgDataUrl;

            vm.getService = function(vm, sendEmail, refreshImages) {
                dataServices.get({
                    url: serverUrl.main + 'taskApi/services/' + vm.serviceId + '/quotes/' + vm.quoteId + '/calendar?type=detail&'
                  })
                  .then(function(response) {
                    let data = response.data;
                    if (data && data.response_code == 200) {
                      if (refreshImages) {
                        imageService.initImageUpload(vm, 'service');
                      }
                      vm.service = data.result;
                      processService(vm);
                      if(vm.quoteList) {
                        angular.forEach(vm.service.workOrderResources, (resource, i) => {
                            vm.selectedResources[resource.id] = resource;
                          });
                      
                          angular.forEach(vm.service.workOrderCustomers, (customer, i) => {
                            vm.selectedCustomers[customer.id] = customer;
                          });
                      }
                      if (sendEmail) {
                        vm.sendEmail(vm, true);
                      }
                    }
                  });
            };

            vm.saveAndSendUpdate = (vm) => {
                saveDetails(vm, true, true);
            }; 

            vm.save = (vm) => {
              if(vm.activeTabIndex == 0) {
                saveDetails(vm);
              } else if(vm.activeTabIndex == 5 || vm.activeTabIndex == 6) {
                saveComments(vm)
              } 
            }

            vm.getFreedomForms = function() {
                const filterCriteria = angular.toJson({
                  "filters": [{
                    "field": "isFreedomForm",
                    "operator": "=",
                    "value": true
                  }, {
                    "field": "status",
                    "value": 2,
                    "operator": "="
                  }]
                });
            
                dataServices.get({
                    url: serverUrl.main + 'customFieldsApi/customFields?filterCriteria=' + filterCriteria + '&'
                  })
                  .then(function(response) {
                    let data = response.data;
                    if (data && data.response_code == 200) {
                      vm.freedomFormsList = data.result;
                    }
                  });
            };
            vm.getFreedomForms();

            function getCbacPermissions(screenPermission) {
                return {
                  details: screenPermission,
                  instructions: screenPermission + '_instruction',
                  documentList: screenPermission + '_documents',
                  form: screenPermission + '_form',
                  removeForm: screenPermission + '_remove_form',
                  comments: screenPermission,
                  media: screenPermission
                };
            }
            
              const cbacMap = {
                'main.calendar.workorder': getCbacPermissions('calendar_personal_work_order'),
                'main.quotes.servicesWorkorder': getCbacPermissions('quote_service_work_order'),
                'main.jobs.servicesWorkorder': getCbacPermissions('job_service_work_order'),
                'main.resources.massignmentAllassignedWoAssignments': getCbacPermissions('team_my_assignments_work_order'),
                'main.resources.compareSquoteWoAssignments': getCbacPermissions('team_compare_work_order'),
                'main.resources.compareSjobWoAssignments': getCbacPermissions('team_compare_work_order'),
                'main.business.fulfillmentServiceorderviewWoAssignments': getCbacPermissions('operation_fulfillment_work_order')
              };
            
              vm.noAccess = {
                instructions: $rootScope.accessData.cbac[cbacMap[currentStateName].instructions] === 'no_access',
                documentList: $rootScope.accessData.cbac[cbacMap[currentStateName].documentList] === 'no_access',
                form: $rootScope.accessData.cbac[cbacMap[currentStateName].form] === 'no_access',
                removeForm: $rootScope.accessData.cbac[cbacMap[currentStateName].removeForm] !== 'full_access'
              };
            
              vm.formTab = {
                tabName: 'form',
                formName: 'myForm'
              };
            
              vm.tabNames = [{
                tabName: 'details',
                formName: 'formDetails',
              }, {
                tabName: 'details'
              }];
            
              if (!vm.noAccess.instructions) {
                vm.tabNames.push({
                  tabName: 'instructions'
                });
              }
            
              if (!vm.noAccess.documentList) {
                vm.tabNames.push({
                  tabName: 'documentList'
                });
              }
            
              if (!vm.noAccess.form) {
                vm.tabNames.push(vm.formTab);
              }
            
              vm.tabNames.push({
                tabName: 'comments',
                formName: 'formComments',
              }, {
                tabName: 'media',
                formName: 'formMedia',
              });
            
              vm.hasWriteAccess = {};
              angular.forEach(vm.tabNames, (tab, i) => {
                vm.hasWriteAccess[tab.tabName] = $rootScope.accessData.cbac[cbacMap[currentStateName][tab.tabName]] === 'full_access';
              });

              vm.editFreedomForm = (vm) => {
                vm.isFormEdit = true;
                 vm.save = function(vm) {
                    let isOpen = false;
                    for (let form in vm.status) {
                        if (vm.status[form]) {
                        isOpen = true;
                        }
                    }
                    if (isOpen) {
                        vm.saveForm = true;
                        validatorService.validateForm(vm, function() { saveFreedomForm(vm); })();
                    } else {
                        saveFreedomForm(vm);
                    }
                 }
                 vm.isDisabledFF = false;
              }
              vm.selectOrUnselectFF = function(vm, ev, form) {
                if (vm.selectedFF[form.formTarget]) {
                  const index = findIndex(vm.service.workOrderFreedomForm, item => item.formTarget === form.formTarget);
                  if (index === -1) {
                    delete vm.selectedFF[form.formTarget];
                  } else {
                    confirmRemoveFF(vm, ev, form.formTarget, true);
                  }
                } else {
                  vm.selectedFF[form.formTarget] = dataServices.toSave(form, ['id', 'formTitle', 'formTarget', 'formDescription', 'customFields', 'sign', 'helpStatement']);
                }
              };
              vm.generatePDF = (vm, index) => {
                let offset = new Date().getTimezoneOffset();
                let minutes = Math.abs(offset);
                let hours = Math.floor(minutes / 60);
                hours = hours + '' + Math.floor(minutes % 60);
                let timeOffset = ((offset < 0 ? "+" : "-") + hours).toString();
            
                // http://localhost:82/jacktrade/api/invoiceApi/quote/10015/invoice/regular?serviceId=65687b5b279e326e480336a2&action=generate&showPrice=true&template=7&isWorkOrder=true&timeOffset=-330&isInstructions=true&index=1
                let url = serverUrl.main + "invoiceApi/quote/" + vm.quoteId + "/invoice/" + (vm.service.taskScheduleType == 3 ? "recurring" : "regular") + "?serviceId=" + vm.serviceId + "&action=generate&showPrice=true&template=7&isWorkOrder=true&timeOffset=" + timeOffset + "&isInstructions=true&index=" + index + "&";
            
                // vm.serviceType == 'recurring'
                // if (vm.service.taskScheduleType == 3) {
                //   url = url + "&serviceId=" + vm.serviceId + "&";
                // }
            
                dataServices.get({
                  url: url
                }).then(function(response) {
                  let data = response.data;
                  if (data.result && data.result.filePath) {
                    window.open(data.result.filePath);
                  }
                });
              };
              
            vm.sendEmail = (vm, isCompleted) => {
                if (!vm.service.workOrderStatus) {
                messages.simpleToast('Select work order status!', 'warning');
                return;
                }
            
                vm.emailDetail = {
                to: []
                };
                console.log(vm.service);
                if (vm.service.salesAgent) {
                vm.emailDetail.to.push({
                    email: vm.service.salesAgent.email,
                    images: vm.service.salesAgent.images,
                    avatar: vm.service.salesAgent.avatar,
                    type: 'resources'
                });
                }
            
                if (vm.service.resources && vm.service.resources.length) {
                angular.forEach(vm.service.resources, (resource, i) => {
                    vm.emailDetail.to.push({
                    email: resource.email,
                    images: resource.images,
                    avatar: resource.avatar,
                    type: 'resources'
                    });
                });
                }
            
                if (vm.service.workOrderResources && vm.service.workOrderResources.length) {
                angular.forEach(vm.service.workOrderResources, (resource, i) => {
                    vm.emailDetail.to.push({
                    email: resource.email,
                    images: resource.images,
                    avatar: resource.avatar,
                    type: 'resources'
                    });
                });
                }
            
                // EmailService.showEmailPopup(vm, {
                //   moduleName: 'workOrderUpdate',
                //   isCompleted
                // });
            
                $mdDialog.show({
                locals: {
                    mv: vm,
                    isCompleted: isCompleted,
                    emailDetail: vm.emailDetail
                },
                controller: SendEmailController,
                templateUrl: emailResourcesTemplate,
                multiple: true,
                parent: angular.element(document.body),
                controllerAs: 'vm'
                });
            };
              vm.selectOrUnselectAppendix = function(vm, ev, appendix) {
                if (vm.selectedAppendix[appendix.id]) {
                  const index = findIndex(vm.service.workOrderAppendix, item => item.id === appendix.id);
                  if (index === -1) {
                    delete vm.selectedAppendix[appendix.id];
                  } else {
                    confirmRemoveAppendix(vm, ev, appendix.id, true);
                  }
                } else {
                  vm.selectedAppendix[appendix.id] = dataServices.toSave(appendix, ['id', 'status', 'appendixTitle', 'appendix']);
                }
              };

              vm.insertFreedomForm = (vm, isSidenav) => {
                vm.service.workOrderFreedomForm = vm.service.workOrderFreedomForm.filter(form => vm.selectedFF[form.formTarget]);
            
                angular.forEach(vm.selectedFF, (form, formTarget) => {
                  const index = findIndex(vm.service.workOrderFreedomForm, item => item.formTarget === form.formTarget);
                  if (index === -1) {
                    vm.service.workOrderFreedomForm.push(form);
                  }
                });
                console.log(vm.service.workOrderFreedomForm);
                sectionIndexing(vm.service.workOrderFreedomForm);
                FreedomFormService.initForUpdate(vm, vm.service.workOrderFreedomForm);
            
                if (isSidenav) {
                  vm.toggleFFSidenav();
                }
              };
              vm.showMarkerArea = (vm, index, obj, img) => {
                let target = document.getElementById("image-" + index);
            
                const markerArea = new markerjs2.MarkerArea(target);
                markerArea.settings.displayMode = 'popup';
            
                markerArea.addEventListener("render", (event) => {
                  imgDataUrl = target.src = event.dataUrl;
            
                  dataServices.upload({
                      url: serverUrl.main + 'mediaApi/upload/base64',
                      data: {
                        data: angular.toJson([{
                          type: 'png',
                          data: imgDataUrl
                        }])
                      }
                    })
                    .then(function(response) {
                      let data = response.data;
                      if (data && data.response_code == 200) {
                        vm[obj][img][index] = data.result.success[0].fileId;
                        // vm.croppedImg[index] = imgDataUrl;
                        vm.croppedImg[index] = data.result.success[0].webUrl;
            
                        if (vm.initImg && vm.initImg[index] && vm.initImg[index].webUrl) {
                          vm.removedImage[index] = true;
                        }
            
                        messages.simpleToast("Image markings saved.");
                      }
                    });
                });
            
                markerArea.addEventListener("close", (event) => {
                  imgDataUrl = undefined;
                });
            
                markerArea.show();
              };

              vm.downloadFF = (vm, id) => {
                dataServices.get({
                    url: serverUrl.main + 'taskApi/services/' + vm.quoteId + '/' + vm.serviceId + '/' + id + '/download?'
                  })
                  .then(function(response) {
                    let data = response.data;
                    if (data && data.response_code == 200) {
                      if (data.result && data.result.web_url) {
                        $window.open(data.result.web_url, '_blank');
                      } else {
                        messages.simpleToast('Download failed!', 'danger');
                      }
                    }
                  });
              };

              vm.openPDF = function(vm, addInstructions) {
                dataServices.get({
                    url: serverUrl.main + 'invoiceApi/quote/' + vm.quoteId + '/invoice/' + (vm.service.taskScheduleType == 3 ? 'recurring' : 'regular') +
                      '?serviceId=' + vm.serviceId + (addInstructions ? '&addInstructions=true' : '') + '&action=generate&showPrice=true&template=6&isWorkOrder=true&timeOffset=' + new Date().getTimezoneOffset() + '&'
                  })
                  .then(function(response) {
                    let data = response.data;
                    if (data && data.response_code == 200) {
                      window.open(data.result.filePath);
                    }
                  });
              };

              vm.goToAddDocument = (vm) => {
                const params = {
                  serviceId: vm.serviceId
                };
            
                if (vm.quoteId) {
                  params.quoteId = vm.quoteId;
                }
            
                let stateName = 'addWoDocument';
                if (currentStateName === 'main.resources.compareSquoteWoAssignments') {
                  stateName = 'addQuoteWoDocument';
                } else if (currentStateName === 'main.resources.compareSjobWoAssignments') {
                  stateName = 'addJobWoDocument';
                }
                $state.go('main.' + (vm.isJob ? 'jobs' : 'quotes') + '.' + stateName, params);
              }
          
              vm.getOrganizations = function(vm) {
                let filterCriteria = angular.toJson({
                  sort: [{
                    field: 'orgName',
                    order: 1
                  }]
                });
                return vm.organizationsList || dataServices.get({
                    url: serverUrl.main + 'resourceApi/organizations?filterCriteria=' + filterCriteria + '&',
                    spinner: false
                  })
                  .then(function(response) {
                    if (response.data && response.data.result) {
                      vm.organizationsList = response.data.result;
                    }
                  });
              };
            
              vm.assignResources = (vm) => {
                ListService.closeSidenav('resources');
                saveDetails(vm, false);
              };

              vm.assignOwnerResource = (vm) => {
                dataServices.patch({
                  url: serverUrl.main + 'taskApi/service/quotes/ordOwner/' + vm.quoteId + '/' + vm.serviceId,
                  data: {
                    data: angular.toJson({
                      orderOwner: vm.selectedOwnerResource || null,
                      taskScheduleType: vm.service.taskScheduleType
                    })
                  }
                }).then(function(response) {
                  if (response && response.data && response.data.result) {
                    messages.simpleToast('Order owner updated successfully!');
                    ListService.closeSidenav('ownerResources');
                    vm.getService(vm);
                  }
                });
              };

              vm.assignCustomers = (vm) => {
                ListService.closeSidenav('customers');
                saveDetails(vm, false);
              };
            
              vm.selectCustomer = function(vm, customer) {
                if (vm.selectedCustomers[customer.id]) {
                  delete vm.selectedCustomers[customer.id];
                } else {
                  vm.selectedCustomers[customer.id] = customer;
                }
              };
              vm.selectOrUnselectResource = (vm, resource) => {
                if (vm.selectedResources[resource.id]) {
                  delete vm.selectedResources[resource.id];
                } else {
                  vm.selectedResources[resource.id] = resource;
                }
              };
            
            
          }
          function processService(vm) {
            vm.service.timeZone = vm.service.timeZone || $rootScope.bObj.bAddsMapped[vm.service.addressId].timeZone;
        
            if (vm.service.orderOwnerDetails) {
              vm.selectedOwnerResource = vm.service.orderOwnerDetails.id;
            }
        
            vm.selectedAppendix = {};
            angular.forEach(vm.service.workOrderAppendix, (appendix, i) => {
              vm.selectedAppendix[appendix.id] = appendix;
            });
        
            vm.selectedFF = vm.selectedFF || {};
            vm.service.workOrderFreedomForm = vm.service.workOrderFreedomForm || [];
            angular.forEach(vm.service.workOrderFreedomForm, (form, i) => {
              vm.selectedFF[form.formTarget] = form;
            });
        
            sectionIndexing(vm, vm.service.workOrderFreedomForm);
            console.log(vm.service.workOrderFreedomForm)
            FreedomFormService.initForUpdate(vm, vm.service.workOrderFreedomForm);
        
            vm.service.comments = vm.service.comments || [];
        
            vm.service.images = vm.service.images || [];
        
            vm.initImg = angular.copy(vm.service.images);
        
            // Initialise comments for edit screen
            vm.comments = {
              add: [],
              update: [],
              delete: []
            };
          }
          function getAppendix(vm) {
            let entityId = $rootScope.bObj.bAddsMapped[vm.service.addressId].entityId;
        
            dataServices.get({
              url: serverUrl.main + 'workOrdersApi/business/workOrder/appendix/' + entityId + '?',
              spinner: false
            }).then(function(response) {
              if (response.data && response.data.result) {
                vm.allAppendix = response.data.result;
              }
            });
          }
          function sectionIndexing(vm, formArray) {
            vm.formSections = {};
            vm.isOpened = {};
            vm.fieldHasValue = {}
            let i = 0;
            angular.forEach(formArray, (form) => {
              let index = 0;
              let sectionIndex = 0;
              let mandatoryField = 0;
              let dataFilled = 0;
              vm.formSections[i] = {};
              vm.isOpened[i] = {};
              vm.fieldHasValue[i] = {};
        
              vm.fieldHasValue[i][sectionIndex] = vm.fieldHasValue[i][sectionIndex] || {};
              angular.forEach(form.customFields, (x) => {
                if (x.fieldType != 'Section Header') {
                  index++;
                  x.index = index;
                  x.sectionIndex = sectionIndex;
                  if (x.fieldValue) {
                    if (x.fieldType != "Checkboxes") {
                      dataFilled++;
                      vm.fieldHasValue[i][sectionIndex][index] = true;
                      // console.log("Not checkbox");
                    } else if (x.fieldType == "Checkboxes" && x.fieldValue.length) {
                      dataFilled++;
                      vm.fieldHasValue[i][sectionIndex][index] = true;
                      // console.log("checkbox");
                    }
                  }
        
                  if (x.fieldType == 'Upload Files / Images') {
                    dataFilled++;
                    vm.fieldHasValue[i][sectionIndex][index] = true;
                    // console.log("attachments");
                  }
        
                  if (x.responseType == "mandatory") {
                    mandatoryField++;
                  }
                } else {
                  sectionIndex++;
                  x.remainingFields = index - dataFilled;
                  index = 0;
                  mandatoryField = 0;
                  dataFilled = 0;
                  vm.isOpened[i][sectionIndex] = false;
                  vm.fieldHasValue[i][sectionIndex] = {};
                  x.sectionIndex = sectionIndex;
                }
                vm.formSections[i][sectionIndex] = {
                  totalField: index,
                  mandatoryFields: mandatoryField,
                  dataFilled: dataFilled,
                  remainingFields: index - dataFilled
                };
              });
              i++;
            });
          }
          function saveDetails(vm, isForm = true, sendEmail) {
            const data = {
              workOrderStatus: vm.service.workOrderStatus,
              workOrderNotes: vm.service.workOrderNotes,
              serviceId: vm.serviceId,
              taskScheduleType: vm.service.taskScheduleType,
              workOrderResources: [],
              workOrderCustomers: []
            };
        
            angular.forEach(vm.selectedResources, (resource, id) => {
              data.workOrderResources.push(id);
            });
        
            angular.forEach(vm.selectedCustomers, (customer, id) => {
              data.workOrderCustomers.push(id);
            });
            dataServices.patch({
                url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/woAttributes',
                data: {
                  data: angular.toJson(data)
                },
              })
              .then(function(response) {
                let data = response.data;
                if (data && data.response_code == 200) {
                  messages.simpleToast('Updated Successfully.');
                  if (isForm) {
                    vm.formDetails.$setPristine();
                  }
                  vm.getService(vm, vm.service.completed_email_flag || sendEmail);
                }
              });
          }
          function saveComments(vm) {
            const data = {
              serviceId: vm.serviceId,
              taskScheduleType: vm.service.taskScheduleType,
              images: [],
              removeImages: []
            };
        
            angular.forEach(vm.service.images, function(img, index) {
              if (vm.croppedImg[index]) {
                data.images.push(vm.service.images[index]);
              }
        
              if (vm.removedImage[index]) {
                data.removeImages.push(vm.initImg[index].id);
              }
            });
            return
            let comments = angular.copy(vm.comments);
            angular.forEach(comments.add, function(comment) {
              delete comment.uniqueId;
            });
            data.comments = comments;
        
            dataServices.patch({
                url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/commentsAndMedia',
                data: {
                  data: angular.toJson(data)
                }
              })
              .then(function(response) {
                let data = response.data;
                if (data && data.response_code == 200) {
                  messages.simpleToast("Updated Successfully");
                  vm.formComments.$setPristine();
                  vm.getService(false, true);
                }
              });
          }
          function saveFreedomForm(vm, callback) {
            const data = {
              freedomForm: true,
              serviceId: vm.serviceId,
              workOrderFreedomForm: [],
              taskScheduleType: vm.service.taskScheduleType
            };
        
            FreedomFormService.beforeSave(vm, data.workOrderFreedomForm).then(function(_data) {
              console.log(angular.copy(data.workOrderFreedomForm), _data);
              if (!_data) {
                return;
              }
        
              dataServices.patch({
                  url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/woAttributes',
                  data: {
                    data: angular.toJson(data)
                  },
                  spinner: false
                })
                .then(function(response) {
                  let data = response.data;
                  if (data && data.response_code == 200 && vm.saveForm) {
                    vm.saveForm = false;
                    messages.simpleToast('Updated Successfully.');
                    vm.myForm.$setPristine();
                    vm.getService(vm);
                    if(vm.quoteList) {
                        editFreedmForm(vm);
                    }
                    // $scope.$parent.vm.save = vm.save = undefined;
                    // $scope.$parent.vm.updateScreen = editForm;
                    vm.isDisabledFF = true;
                  }
        
                  if (angular.isFunction(callback)) {
                    callback();
                  }
                });
            }, function(error) {
              console.log(error);
            });
        
          }
        
          function confirmRemoveFF(vm, ev, id, isSidenav) {
            $mdDialog.show({
              locals: {
                vm: vm,
                id: id,
                isSidenav: isSidenav
              },
              controller: RemoveFFController,
              templateUrl: confirmDialogTemplate,
              parent: angular.element(document.body),
              multiple: true,
              targetEvent: ev,
              controllerAs: 'cd'
            });
          };
        
          function RemoveFFController($scope, $mdDialog, vm, id, isSidenav) {
        
            'ngInject';
        
            let cd = this;
            $scope.vm = vm;
        
            cd.question = 'Do you want to continue?'
            cd.description = 'You are removing current form. All your current data, if any, will be lost permanently.';
        
            cd.confirm = confirm;
        
            function confirm() {
              delete vm.selectedFF[id];
              delete vm.customFormFiles[id];
              vm.insertFreedomForm(vm, isSidenav);
              cd.cancel();
            }
        
            cd.cancel = function() {
              $mdDialog.cancel();
            };
          }
        

          //comments

          function deleteComment(vm, ev, index) {
            messages.mdconfirm(ev, "Are you sure to remove the comment?", 'YES', 'NO')
              .then(function(result) {
                if (result) {
                  if (vm.service.comments[index].id) {
                    angular.forEach(vm.comments.update, function(comment, ind) {
                      if (vm.service.comments[index].id == comment.id) {
                        vm.comments.update.splice(ind, 1);
                      }
                    });
                    vm.comments.delete.push(vm.service.comments[index].id);
                  } else {
                    angular.forEach(vm.comments.add, function(comment, ind) {
                      if (vm.service.comments[index].uniqueId == comment.uniqueId) {
                        vm.comments.add.splice(ind, 1);
                      }
                    });
                  }
                  vm.service.comments.splice(index, 1);
                  messages.simpleToast("Comment deleted!");
                }
              });
          }
        
          function addComment(vm) {
            if (vm.commentEditor && vm.commentEditor !== '') {
              let comment = {
                content: angular.copy(vm.commentEditor)
              };
              vm.service.comments.unshift(comment);
        
              comment.uniqueId = Math.random().toString(36) + Date.now();
              vm.comments.add.push(comment);
        
              vm.commentEditor = '';
            } else {
              messages.simpleToast('PLEASE_ENTER_VALID_COMMENT.');
            }
          }
        
          function editComment(vm, index) {
            if (vm.activeIndex >= 0) {
              vm.service.comments[vm.activeIndex] = vm.editingComment;
            }
            vm.commentEdits = {};
            vm.commentEdits[index] = true;
            vm.editingComment = angular.copy(vm.service.comments[index]);
            vm.activeIndex = index;
          }
        
          function cancelEdit(vm, index) {
            vm.activeIndex = -1;
            vm.commentEdits = {};
            vm.service.comments[index] = vm.editingComment;
          }
        
          function saveComment(vm, index) {
            vm.activeIndex = -1;
            vm.commentEdits = {};
            if (vm.service.comments[index].id) {
              vm.comments.update.push(dataServices.toSave(vm.service.comments[index], ['content', 'id', 'modelId']));
            }
          }
        
          function saveComments(vm) {
            const data = {
              serviceId: vm.serviceId,
              taskScheduleType: vm.service.taskScheduleType,
              images: [],
              removeImages: []
            };
        
            angular.forEach(vm.service.images, function(img, index) {
              if (vm.croppedImg[index]) {
                data.images.push(vm.service.images[index]);
              }
        
              if (vm.removedImage[index]) {
                data.removeImages.push(vm.initImg[index].id);
              }
            });
        
            let comments = angular.copy(vm.comments);
            angular.forEach(comments.add, function(comment) {
              delete comment.uniqueId;
            });
            data.comments = comments;
        
            dataServices.patch({
                url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/commentsAndMedia',
                data: {
                  data: angular.toJson(data)
                }
              })
              .then(function(response) {
                let data = response.data;
                if (data && data.response_code == 200) {
                  messages.simpleToast("Updated Successfully");
                  vm.formComments.$setPristine();
                  vm.getService(vm, false, true);
                }
              });
          }
        
          //appendix

          function attachAppendix(vm, isSidenav) {
            let data = {
              serviceId: vm.serviceId,
              taskScheduleType: vm.service.taskScheduleType,
              workOrderAppendix: []
            };
        
            angular.forEach(vm.selectedAppendix, function(item, key) {
              data.workOrderAppendix.push(item);
            });
        
            dataServices.patch({
              url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/workOrder/appendix',
              data: {
                data: angular.toJson(data)
              }
            }).then(function(response) {
              if (response && response.data && response.data.result) {
                messages.simpleToast('Instructions updated successfully!');
                if (isSidenav) {
                  vm.toggleSidenav();
                }
                vm.getService(vm);
              }
            });
          }

       //send email

      function SendEmailController($scope, $rootScope, $state, $mdDialog, $mdSidenav, $timeout, FileUploadService, EmailService, maxlength, mv, isCompleted, emailDetail) {

        'ngInject';
    
        let vm = this;
    
        vm.maxlength = maxlength;
        vm.emailDetail = emailDetail;
    
        EmailService.init(vm);
        FileUploadService.init(vm, 'data', 'fileUploadSidebarPopup');
    
        function renderEmail() {
          const filterCriteria = angular.toJson({
            filters: [{
              field: "isArchived",
              operator: "=",
              value: false
            }, {
              field: "modelName",
              operator: "=",
              value: 77
            }, {
              field: "type",
              operator: "=",
              value: isCompleted ? "complete" : "status"
            }]
          });
    
          dataServices.get({
              url: serverUrl.main + 'emailTemplateApi/email/template/parse/render?modelName=work_orders&modelId=' + mv.serviceId + '&locationId=' + mv.service.addressId + '&filterCriteria=' + filterCriteria + '&',
              spinnerName: 'email-resources-spinner'
            })
            .then(function(response) {
              let data = response.data;
              if (data && data.response_code == 200) {
                vm.emailDetail.subject = data.result.emailSubject;
                // checkemailbe
                vm.emailDetail.body = data.result.emailBody;
              }
            });
        }
    
        $timeout(function() {
          renderEmail();
        }, 0);
    
        vm.checkSpamAndSendEmail = function() {
          EmailService.checkSpam(vm, vm.emailDetail.body, FileUploadService.uploadFilesAndSave(vm, send));
        };
    
        function send() {
          if (vm.data.attachments) {
            vm.emailDetail.attachments = vm.data.attachments;
          }
    
          let data = angular.copy(vm.emailDetail);
          // checkemailbe
          data.to = data.to.map(resource => resource.email);
    
          dataServices.post({
              url: serverUrl.main + 'workOrdersApi/business/workOrder/email/' + mv.quoteId + '/' + mv.serviceId,
              data: {
                data: angular.toJson(data)
              }
            })
            .then(function(response) {
              let data = response.data;
              if (data && data.response_code == 200) {
                messages.simpleToast('Email sent successfully.');
                $mdDialog.cancel();
              }
            });
        }
    
        EmailService.loadContacts(vm, 'email-resources-spinner');
    
        vm.toggleRight = FileUploadService.buildToggler(vm, 'fileUploadSidebarPopup');
    
        vm.isCloseRight = function() {
          return $mdSidenav('fileUploadSidebarPopup').close();
        };
    
        vm.cancel = function() {
          $mdDialog.cancel();
        };
      }
    }
}
