import businessFrameTemplate from './business-frame/business-frame.html';

import catalogListTemplate from './inventory/cataloging/catalog-list/catalogList.html';
import manageCatalogTemplate from './inventory/cataloging/manage-catalog/manageCatalog.html';
import updateCatalogTemplate from './inventory/cataloging/update-catalog/updateCatalog.html';
import updateAttachedServiceTemplate from './inventory/cataloging/update-attached-service/updateAttachedService.html';
import updateProductInstructionTemplate from './inventory/cataloging/update-product-instruction/updateProductInstruction.html';
import productListTemplate from './inventory/products/product-list/productList.html';
import productDetailTemplate from './inventory/products/view-product/productDetail.html';
// import productViewTemplate from './inventory/products/view-product/productView.html';
import productUpdateTemplate from './inventory/products/update-product/productUpdate.html';
import skuDetailTemplate from './inventory/products/sku-detail/skuDetail.html';
import skuUpdateTemplate from './inventory/products/update-sku/skuUpdate.html';
import updatePackageTemplate from './inventory/products/update-package/packageUpdate.html';
import packageViewTemplate from './inventory/products/view-package/packageView.html';
import updateBundleTemplate from './inventory/products/update-bundle/bundleUpdate.html';
import bundleViewTemplate from './inventory/products/view-bundle/bundleView.html';
import attachedServiceDetailTempate from './inventory/cataloging/attachedService-details/attachService-details.html';

// import mediaView from './media-upload/media-manipulation/media-view.html';
import mediaUpload from './media-upload/media-upload.html';
import mediaListGridView from './media-upload/media-list-grid/media-list-grid.html';
import manageMediaTags from './media-upload/manage-tags/media-tag-management.html'
import editMediaTags from './media-upload/edit-media-tag/edit-tag.html';

import addSupplierTemplate from './procurements/update-suppliers/update-supplier.html';
import listSupplierTemplate from './procurements/supplier-list.html';
import supplierDetailTemplate from './procurements/supplier-details/supplier-detailes.html';
import listCatalogTemplate from './procurements/catalog-list.html';
import addCatalogItemTemplate from './procurements/update-catalog/update-catalog-item.html';
import purchaseOrderListTemplate from './procurements/purchase-orderList.html';
import addPurchaseOrderTemplate from './procurements/update-purchaseOrder/update-purchase-order.html';
import purchaseOrderdetailsTemplate from './procurements/purchaseorder-details/purchaseorder-details.html';
import catalogDetailTemplate from './procurements/catalog-details/catalog-details.html';
import relatedOrderTemplate from './procurements/related-order/related-order.html';
import autoOrderListTemplate from './procurements/auto-order/autoOrder-list.html';
import manageItemsTemplate from './procurements/manage-items/manage-items.html';
import publishItemsTemplate from './procurements/publish/publish.html';
import paymentPOTemplate from './procurements/pay-po/pay-po.html';
import submitPoTemplate from './procurements/submit/submitPo.html';

import currentStockReviewTemplate from './inventory/products/stock-review/stock-review.html';
import fullOrderViewTemplate from './fulfillment/active-full-order-view/active-full-order-view.html';
import fullOrderPipelineViewTemplate from './fulfillment/active-full-order-view/pipeline/active-full-order-pipeline-view.html';

import welcomeImportTemplate from './inventory/products/inventory-import/welcome-import.html';
import uploadinginventoryTemplate from './inventory/products/inventory-import/uploadingimport.html';
import summaryImportTemplate from './inventory/products/inventory-import/inventory-import-summary/inventory-summary.html';

import inventoryImportAllRecordsSummaryTemplate from './inventory/products/inventory-import/inventory-import-summary/inventory-import-success-warning-error/inventoryImportAllRecordsSummary.html';

import payinTempate from './operation/payin/payin.html';
import payOutTempate from './operation/payout/payOut.html';
import inventoryImportBulkUpdateTemplate from './inventory/products/inventory-bulk-sku-update/inventoryImportBulkUpdate.html';
import inventoryImportBulkUpdateSummaryTemplate from './inventory/products/inventory-bulk-sku-update/show-import-summary/showImportBulkUpdateSummary.html';

import inventoryImportBulkSKUSummaryTemplate from './inventory/products/inventory-bulk-sku-update/show-import-summary/inventory-success-warning-error/inventory-bulk-sku-summary.html';

import inventoryImportProductUpdateTemplate from './inventory/products/inventory-bulk-product-update/inventoryImportBulkProductUpdate.html';
import inventoryImportProductBulkUpdateFullSummaryTemplate from './inventory/products/inventory-bulk-product-update/show-import-summary/showImportBulkProductUpdateSummary.html';

import inventoryImportBulkProductSummaryTemplate from './inventory/products/inventory-bulk-product-update/show-import-summary/inventory-success-warning-error/inventory-bulk-update-summary.html';

import materialReturnTemplate from './fulfillment/material-return/materialList.html';
import materialReturnDetailTemplate from './fulfillment/material-return/material-return-detail/materialReturnDetail.html';
import quickbookTransactionTemplate from './operation/quickbook-transaction/quickbookTransaction.html';
import deliveryUsageTemplate from './fulfillment/delivery-usage/delivery-usage.html';
import returnedProductTemplate from './fulfillment/returned-product/returned-product.html';
import fullOrderItemViewTemplate from './fulfillment/full-order-item-view/active-full-order-item-view.html';

import materialsSalesTemplate from './inventory/products/materials-sales/materials-sales.html';
import supplierDocumentListTemplate from './procurements/supplier-documents/supplierDocumentList.html';
import updateDocumentTemplate from './../../components/associate-documents/updateDocumentTemplate.html';
import documentDetailTemplate from './../../components/associate-documents/documentDetailTemplate.html';
import purchaseOrderDocumentListTemplate from './procurements/po-documents/PODocumentList.html';

import warrantyTrackingListTemplate from './warranty-tracking/warrantyTrackingList.html';
import warrantyTrackingDetailTemplate from './warranty-tracking/detail-warranty-tracking/warrantyTrackingDetail.html';
import warrantyWorkboardTemplate from './warranty-tracking/warrantyWorkboard.html';
import welcomeProductUpdateTemplate from './inventory/products/inventory-bulk-product-update/welcome-product-update.html';
import welcomeSKUUpdateTemplate from './inventory/products/inventory-bulk-sku-update/welcome-sku-update.html';

import diagramTemplate from './../../components/diagram/diagram.html';
import diagramListTemplate from './../../components/diagram/diagram-list/diagram-list.html';
import manageDrawingsTemplate from './../../components/diagram/manage-templates/manage-templates.html';
import serialImportWelcomeTemplate from './inventory/products/sku-detail/serial-welcome-import/serialImportWelcome.html';
//added
import bulkSerialsTemplate from './inventory/products/bulk-scan-serials/bulkScanSerials.html';
import serialImportTemplate from './inventory/products/sku-detail/serial-welcome-import/serialImportTemplate.html';
import serialSummaryImportTemplate from './inventory/products/sku-detail/serial-welcome-import/serial-import-summary/serialImportSummary.html';

import JobOrderingCartTemplate from '../jobs/job-cycle/service/products/JobOrderingCart.html';
import ProductFulfillmentTemplate from '../jobs/job-cycle/service/products/ProductFulfillment/regularFulfillment/regularFulfillment.html';
import BundleProductFulfillmentTemplate from '../jobs/job-cycle/service/products/ProductFulfillment/bundleProductFulfillment/bundleProductFulfillment.html';
import PackageFulfillmentTemplate from '../jobs/job-cycle/service/products/ProductFulfillment/packageFulfillment/packageFulfillment.html';
import PackageDetailAllFulfillmentTemplate from '../jobs/job-cycle/service/products/ProductFulfillment/package-detail-all-fulfill/packageDetailAllFulfill.html';

import updateWorkorderCommonTemplate from './../../components/update-workorder/update-workorder-common.html';

import {
  CATALOGS,
  ATTACHED_SERVICES,
  PRODUCT_INSTRUCTIONS,
  listCatalogs,
  CATALOG_PRODUCTS,
  showCatalog,
  showParentCatalogs,
  showService,
  showInstruction,
  showInstructionCategories,
  products,
  materials,
  showProduct,
  showServices,
  showServicesMaterial,
  showBundle,
  showPackage,
  showSku,
  showSkus,
  mediaRepoList,
  mediaTagsList,
  regions,
  locationsByRegionId,
  fetchAttributePolicy,
  suppliers,
  supplierss,
  supplierssMaterial,
  supplierDetails,
  catalogList,
  catalogDetails,
  purchaseOrders,
  purchaseOrder,
  paymentPolicy,
  relatedOrder,
  autoRelatedOrder,
  receivePO,
  poReturn,
  receiveSku,
  stockInfo,
  publishItem,
  paymentList,
  paymentDetails,
  supplierHistory,
  poHistory,
  suppliersList,
  autoPoId,
  INVENTORY_VIEW,
  SCRAPED_VIEW, PRODUCT_SERIAL_VIEW, STOCK_VIEW,
  FULL_ORDER_VIEW,
  PRODUCT_CUSTOM_FORM,
  MATERIAL_CUSTOM_FORM,
  PURCHASE_ORDER_CUSTOM_FORM,
  CUSTOM_FORM_SUPPLIER,
  attributePoliciesProduct,
  attributePoliciesMaterial,
  isValidRowProductImport,
  hasWarningsProductImport,
  hasErrorsProductImport,
  isValidRowMaterialImport,
  hasWarningsMaterialImport,
  hasErrorsMaterialImport,
  getInventoryProductImport,
  getInventoryMaterialImport,
  QUOTE_LIST,
  CUSTOMER_LIST,
  SKU_SUPPLIER,
  //added
  serialScan,
  //serialScanHistory,
  manufacturerList,
  suppliersListOnly,
  fetchEmailTemplate,
  reasonlistPo,
  reasonlistPoCancel,
  promotionListNotArchived,
  allTransaction,
  payOut,
  poPdf,
  poPreview,
  MATERIAL_STOCK_HISTORY,
  PRODUCT_STOCK_HISTORY,
  getInventoryImportPriceProduct,
  getInventoryImportPriceMaterial,
  getInventoryBulkSkuErrorDataSummaryProduct,
  getInventoryBulkSkuSuccessDataSummaryProduct,
  getInventoryBulkSkuWarningDataSummaryProduct,
  getInventoryBulkSkuErrorDataSummaryMaterial,
  getInventoryBulkSkuSuccessDataSummaryMaterial,
  getInventoryBulkSkuWarningDataSummaryMaterial,
  getInventoryImportProductUpdate,
  getInventoryImportMaterialUpdate,
  getInventoryBulkProductUpdateErrorDataSummary,
  getInventoryBulkMaterialUpdateErrorDataSummary,
  getInventoryBulkProductUpdateSuccessDataSummary,
  getInventoryBulkMaterialUpdateSuccessDataSummary,
  getInventoryBulkProductUpdateWarningDataSummary,
  getInventoryBulkMaterialUpdateWarningDataSummary,
  PO_INVOICE_TEMPLATE_LIST,
  PRICE_TYPE_DEPENDANCY_PACKAGE,
  MATERIAL_RETURN,
  MATERIAL_RETURN_DETAIL,
  MATERIAL_TAX_CLASSIFICATION,
  PRODUCT_TAX_CLASSIFICATION,
  PACKAGE_PRODUCT_DEPENDANCY,
  accountListQB,
  ACCOUNT_ISSUES,
  ITEM_ORDER_VIEW,
  DELIVERY_USAGE,
  RETURNED_PRODUCTS,
  supplierDocument,
  poDocument,
  supplierDocumentDetail,
  isInventoryImportDataAvailableMaterial,
  isInventoryImportDataAvailableProduct,
  WARRANTY_LIST,
  warrantyTrackingDetail,
  ALL_ORGANIZATION,
  // serialPolicies
  getInventoryProductSerial,
  orderPipelineSettings
} from './business.resolve.obj';

import {
  AVAILABLE_TEMPLATES,
  SAVED_TEMPLATES,
  DIAGRAM_CATEGORIES,
  DRAWING_TEMPLATE,
  DRAWING
} from '../../components/diagram/diagram.resolve.obj';

import {
  QUOTE_DETAIL
} from '_quotes/quote.resolve.obj';

import {
  DOCUMENT_LIST_WO,
  SERVICE_DETAIL_WO
} from '../../components/update-workorder/wo.resolve.obj';

import {
  SERVICE_DETAIL,
  JOBS_PRODUCT_CART_VIEW,
  JOBS_BUNDLE_PRODUCT_CART_VIEW,
  JOBS_PACKAGE_PRODUCT_CART_VIEW,
  JOBS_PRODUCT_VIEW,
  CART_TOTAL,
  STOCK_VIEW_DATA_PRODUCT,
  DELIVERY_USAGE_PRODUCT,
  FULFILLMENT_TABS_COUNT_PRODUCT,
  PACKAGE_DETAIL,
  PACKAGE_DETAIL_CART
} from '_quotes/quote-cycle/service/service.resolver.obj';

export default function businessRoutes($urlRouterProvider, $stateProvider, businessResolverProvider, resolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.business', resolver('business', businessFrameTemplate, 'BusinessFrameController', {
      lazyModule: [businessResolverProvider.loadBusinessModule]
    }, {
      cbac: 'operations'
    }))

    .state('main.business.addDiagram', resolver('add-diagram/:docId/:templateId/:fromState', diagramTemplate, 'DiagramController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.business.updateDiagram', resolver('update-diagram/:docId/:drawingId', diagramTemplate, 'DiagramController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.business.addCopyDiagram', resolver('copy-diagram/:docId/:drawingId/:isClone', diagramTemplate, 'DiagramController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.business.editTemplate', resolver('edit-template/:templateId/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.business.addCopyTemplate', resolver('copy-template/:templateId/:isClone/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.business.addViewTemplate', resolver('view-template/:templateId/:isViewOnly', diagramTemplate, 'DiagramController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.business.addTemplatesList', resolver('diagram-list/:docId/:fromState', diagramListTemplate, 'DiagramListController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'operations'
    }))

    .state('main.business.addManageTemplates', resolver('manage-templates', manageDrawingsTemplate, 'ManageTemplatesController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'operations'
    }))

    .state('main.business.inventoryCataloging', resolver('catalog-list', catalogListTemplate, 'CatalogListController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [CATALOGS, ATTACHED_SERVICES, PRODUCT_INSTRUCTIONS]
    }, {
      cbac: 'operation_inventory_catalogs'
    }))

    .state('main.business.inventoryCatalogingManagement', resolver('manage-catalog/:id', manageCatalogTemplate, 'ManageCatalogController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showCatalog, CATALOG_PRODUCTS]
    }, {
      cbac: 'operation_inventory_catalogs'
    }))

    .state('main.business.addCatalog', resolver('add-catalog', updateCatalogTemplate, 'UpdateCatalogController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showCatalog.name],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_catalogs',
      isWrite: true
    }))

    .state('main.business.updateCatalog', resolver('update-catalog/:id', updateCatalogTemplate, 'UpdateCatalogController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showCatalog],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_catalogs',
      isWrite: true
    }))

    .state('main.business.addAttachedService', resolver('add-attached-service', updateAttachedServiceTemplate, 'UpdateAttachedServiceController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showService.name, regions]
    }, {
      cbac: 'operation_inventory_catalogs',
      isWrite: true
    }))

    .state('main.business.updateAttachedService', resolver('update-attached-service/:id', updateAttachedServiceTemplate, 'UpdateAttachedServiceController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showService, regions]
    }, {
      cbac: 'operation_inventory_catalogs',
      isWrite: true
    }))

    .state('main.business.addProductInstruction', resolver('add-product-instruction', updateProductInstructionTemplate, 'UpdateProductInstructionController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showInstruction.name, showInstructionCategories]
    }, {
      cbac: 'operation_inventory_catalogs',
      isWrite: true
    }))

    .state('main.business.updateProductInstruction', resolver('update-product-instruction/:id', updateProductInstructionTemplate, 'UpdateProductInstructionController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showInstruction, showInstructionCategories]
    }, {
      cbac: 'operation_inventory_catalogs',
      isWrite: true
    }))

    .state('main.business.inventoryMaterials', resolver('material-list', productListTemplate, 'ProductListController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [materials]
    }, {
      cbac: 'operation_inventory_materials'
    }))

    // sales page - no cbac
    .state('main.business.inventoryMaterialsSales', resolver('upgrade-materials', materialsSalesTemplate, 'MaterialsSalesController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }))

    .state('main.business.fulfillmentDeliveryusage', resolver('delivery-usage-list', deliveryUsageTemplate, 'DeliveryUsageContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [DELIVERY_USAGE]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.fulfillmentReturnproduct', resolver('returned-product-list', returnedProductTemplate, 'ReturnedProductContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [RETURNED_PRODUCTS]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.fulfillmentRentalreturn', resolver('material-return-list', materialReturnTemplate, 'MaterialReturnController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [MATERIAL_RETURN]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.fulfillmentRentalreturnDetail', resolver('material-return-detail/:id', materialReturnDetailTemplate, 'MaterialReturnDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [MATERIAL_RETURN_DETAIL]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.inventoryProducts', resolver('product-list', productListTemplate, 'ProductListController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [products]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.business.inventoryProductsDetail', resolver('product-detail/:id?tabIndex', productDetailTemplate, 'ProductDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct, showServices, PRODUCT_STOCK_HISTORY]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.business.inventoryMaterialsDetail', resolver('material-detail/:id?tabIndex', productDetailTemplate, 'ProductDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct, showServicesMaterial, MATERIAL_STOCK_HISTORY]
    }, {
      cbac: 'operation_inventory_materials'
    }))

    .state('main.business.updateProduct', resolver('update-product/:id/:regionId', productUpdateTemplate, 'ProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct, showServices, regions, PRODUCT_CUSTOM_FORM, PRODUCT_TAX_CLASSIFICATION, PACKAGE_PRODUCT_DEPENDANCY],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.addProduct', resolver('add-product', productUpdateTemplate, 'ProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct.name, showServices.name, regions, PRODUCT_CUSTOM_FORM, PRODUCT_TAX_CLASSIFICATION, PACKAGE_PRODUCT_DEPENDANCY.name],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.updateMaterial', resolver('update-material/:id/:regionId', productUpdateTemplate, 'ProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct, showServicesMaterial, regions, MATERIAL_CUSTOM_FORM, MATERIAL_TAX_CLASSIFICATION, PACKAGE_PRODUCT_DEPENDANCY.name],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.addMaterial', resolver('add-material', productUpdateTemplate, 'ProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct.name, showServicesMaterial.name, regions, MATERIAL_CUSTOM_FORM, MATERIAL_TAX_CLASSIFICATION, PACKAGE_PRODUCT_DEPENDANCY.name],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsSkuDetail', resolver(':id/product-sku-detail/:skuId/:regionId', skuDetailTemplate, 'SkuDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku, showSkus, showProduct, SKU_SUPPLIER, regions, ALL_ORGANIZATION]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.business.inventoryProductSkuImportSummary', resolver('import-serial-summary/:tabId', serialSummaryImportTemplate, 'SerialSummaryImportContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryProductSerial]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsSkuSerialImport', resolver(':id/product-sku-detail/:skuId/:regionId/serial-import/:tabId', serialImportTemplate, 'SerialImportController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showProduct]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.business.inventoryProductsSkuSerialImportWelcome', resolver(':id/product-sku-detail/:skuId/:regionId/welcome-serial-import/:tabId', serialImportWelcomeTemplate, 'SerialImportWelcomeContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule]
    }, {
      cbac: 'operation_inventory_products'
    }))
    //added
    .state('main.business.inventoryProductsBulkserials', resolver(':id/product-sku-detail/:skuId/:regionId/bulk-serial', bulkSerialsTemplate, 'BulkScanSerialController', {
      preData: [showSku, showSkus, showProduct, ALL_ORGANIZATION, serialScan, regions, SKU_SUPPLIER],
      loadOnDemand: [resolverProvider.loadClipboard]
    },
      {
        cbac: 'operation_inventory_products'
      }))

    .state('main.business.inventoryMaterialsSkuDetail', resolver(':id/material-sku-detail/:skuId/:regionId', skuDetailTemplate, 'SkuDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku, showSkus, showProduct, SKU_SUPPLIER, regions, ALL_ORGANIZATION]
    }, {
      cbac: 'operation_inventory_materials'
    }))

    .state('main.business.addProductSku', resolver(':id/product-add-sku/:regionId', skuUpdateTemplate, 'SkuUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku.name, showSkus.name, showProduct, suppliersListOnly, SKU_SUPPLIER.name, manufacturerList, regions],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.addMaterialSku', resolver(':id/material-add-sku/:regionId', skuUpdateTemplate, 'SkuUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku.name, showSkus.name, showProduct, suppliersListOnly, SKU_SUPPLIER.name, manufacturerList, regions],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.updateProductSku', resolver(':id/product-update-sku/:skuId/:regionId', skuUpdateTemplate, 'SkuUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku, showSkus, showProduct, suppliersListOnly, SKU_SUPPLIER, manufacturerList, regions],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.updateMaterialSku', resolver(':id/materal-update-sku/:skuId/:regionId', skuUpdateTemplate, 'SkuUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku, showSkus, showProduct, suppliersListOnly, SKU_SUPPLIER, manufacturerList, regions],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.addCloneProductSku', resolver(':id/add-clone-product-sku/:skuId/:regionId', skuUpdateTemplate, 'SkuUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku, showSkus, showProduct, suppliersListOnly, SKU_SUPPLIER, manufacturerList, regions],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.addCloneMaterialSku', resolver(':id/add-clone-material-sku/:skuId/:regionId', skuUpdateTemplate, 'SkuUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showSku, showSkus, showProduct, suppliersListOnly, SKU_SUPPLIER, manufacturerList, regions],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.addPackage', resolver('add-package', updatePackageTemplate, 'UpdatePackageController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showPackage.name, listCatalogs, PRICE_TYPE_DEPENDANCY_PACKAGE.name, PRODUCT_TAX_CLASSIFICATION],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.updatePackage', resolver('update-package/:id', updatePackageTemplate, 'UpdatePackageController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showPackage, listCatalogs, PRICE_TYPE_DEPENDANCY_PACKAGE, PRODUCT_TAX_CLASSIFICATION],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryProductsPackageView', resolver('view-package/:id', packageViewTemplate, 'PackageViewController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showPackage]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.business.addBundle', resolver('add-bundle', updateBundleTemplate, 'UpdateBundleController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showBundle.name, promotionListNotArchived, PRODUCT_TAX_CLASSIFICATION],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.updateBundle', resolver('update-bundle/:id', updateBundleTemplate, 'UpdateBundleController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showBundle, promotionListNotArchived, PRODUCT_TAX_CLASSIFICATION],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBundleView', resolver('view-bundle/:id?tabIndex', bundleViewTemplate, 'BundleViewController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showBundle]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.business.mediaUpload', resolver('media-upload', mediaUpload, 'MediaUploadController', {
      // , resolverProvider.loadImageCompression
      lazyModule: [businessResolverProvider.loadBusinessModule]
    }, {
      cbac: 'operation_media_upload',
      isWrite: true
    }))

    .state('main.business.mediaAllMedia', resolver('media-repo', mediaListGridView, 'MediaListGridViewController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadJsZip, resolverProvider.loadJsZipUtil, resolverProvider.loadCroppie, resolverProvider.loadFiltrr, resolverProvider.loadClipboard],
      preData: [mediaRepoList]
    }, {
      cbac: 'operation_media'
    }))

    .state('main.business.mediaAssociation', resolver('manage-tags/:category', manageMediaTags, 'ManageMediaTags', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [mediaTagsList]
    }, {
      cbac: 'operation_media'
    }))

    .state('main.business.mediaEditTags', resolver('edit-media-tags/:category', editMediaTags, 'EditMediaTags', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [mediaTagsList]
    }, {
      cbac: 'operation_media',
      isWrite: true
    }))

    .state('main.business.addSuppliers', resolver('add-supplier', addSupplierTemplate, 'AddSupplierController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride, resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor],
      preData: [supplierDetails.name, regions, CUSTOM_FORM_SUPPLIER]
    }, {
      cbac: 'operation_suppliers',
      isWrite: true
    }))

    .state('main.business.updateSuppliers', resolver('update-supplier/:supplierId', addSupplierTemplate, 'AddSupplierController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride, resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor],
      preData: [supplierDetails, regions, CUSTOM_FORM_SUPPLIER]
    }, {
      cbac: 'operation_suppliers',
      isWrite: true
    }))

    .state('main.business.suppliers', resolver('supplier-list', listSupplierTemplate, 'ListSupplierController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [suppliersList]
    }, {
      cbac: 'operation_suppliers'
    }))

    .state('main.business.suppliersDetail', resolver('supplier-detail/:supplierId', supplierDetailTemplate, 'supplierDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDetails, supplierHistory]
    }, {
      cbac: 'operation_suppliers'
    }))

    .state('main.business.suppliersDocuments', resolver('supplier/:supplierId/document-list', supplierDocumentListTemplate, 'SupplierDocumentListController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocument]
    }, {
      cbac: 'operation_suppliers_documents'
    }))

    .state('main.business.suppliersDocumentsAdd', resolver('supplier/:supplierId/document-list/add', updateDocumentTemplate, 'UpdateSupplierDocumentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocumentDetail.name]
    }, {
      cbac: 'operation_suppliers_documents',
      isWrite: true
    }))

    .state('main.business.suppliersDocumentsUpdate', resolver('supplier/:supplierId/document-list/update/:docId', updateDocumentTemplate, 'UpdateSupplierDocumentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocumentDetail]
    }, {
      cbac: 'operation_suppliers_documents',
      isWrite: true
    }))

    .state('main.business.suppliersDocumentsDetail', resolver('supplier/:supplierId/document-detail/:docId', documentDetailTemplate, 'SupplierDocumentDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocumentDetail]
    }, {
      cbac: 'operation_suppliers_documents'
    }))

    .state('main.business.suppliersCatalog', resolver('supplier-catalog-list/:supplierId', listCatalogTemplate, 'listCatalogController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [catalogList]
    }, {
      cbac: 'operation_suppliers'
    }))

    .state('main.business.addCatalogItem', resolver('add-catalog-item/:supplierId', addCatalogItemTemplate, 'AddCatalogController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [catalogDetails.name, supplierDetails, catalogList]
    }, {
      cbac: 'operation_suppliers',
      isWrite: true
    }))

    .state('main.business.updateCatalogItem', resolver('update-catalog-item/:catalogId/:supplierId', addCatalogItemTemplate, 'AddCatalogController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [catalogDetails, supplierDetails, catalogList]
    }, {
      cbac: 'operation_suppliers',
      isWrite: true
    }))

    .state('main.business.suppliersCatalogDetail', resolver('catalog-detail/:catalogId/:supplierId', catalogDetailTemplate, 'CatalogsDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [catalogDetails]
    }, {
      cbac: 'operation_suppliers'
    }))

    .state('main.business.purchaseorder', resolver('purchase-order', purchaseOrderListTemplate, 'PurchaseOrderListContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [purchaseOrders]
    }, {
      cbac: 'operation_purchase_orders'
    }))

    .state('main.business.addPurchaseOrder', resolver('add-purchaseOrder', addPurchaseOrderTemplate, 'AddPurchaseOrderContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [supplierss, supplierssMaterial, paymentPolicy, autoPoId, purchaseOrder.name, PURCHASE_ORDER_CUSTOM_FORM]
    }, {
      cbac: 'operation_purchase_orders',
      isWrite: true
    }))

    .state('main.business.updatePurchaseOrder', resolver('update-purchaseOrder/:id', addPurchaseOrderTemplate, 'AddPurchaseOrderContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [supplierss, supplierssMaterial, paymentPolicy, autoPoId, purchaseOrder, PURCHASE_ORDER_CUSTOM_FORM]
    }, {
      cbac: 'operation_purchase_orders',
      isWrite: true
    }))

    .state('main.business.purchaseorderDetails', resolver('purchase-order-details/:id', purchaseOrderdetailsTemplate, 'PurchaseOrderdetailsContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [purchaseOrder, poHistory, poPreview]
    }, {
      cbac: 'operation_purchase_orders'
    }))

    .state('main.business.purchaseorderDocuments', resolver('purchase-order-document/:id', purchaseOrderDocumentListTemplate, 'PurchaseOrderDocumentListContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [poDocument]
    }, {
      cbac: 'operation_purchase_document'
    }))

    .state('main.business.purchaseorderDocumentsDetail', resolver('purchase-order-document/:id/detail/:docId', documentDetailTemplate, 'PODocumentDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocumentDetail]
    }, {
      cbac: 'operation_purchase_document'
    }))

    .state('main.business.purchaseorderDocumentsAdd', resolver('purchase-order-document/:id/add', updateDocumentTemplate, 'PurchaseOrderUpdateDocumentContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocumentDetail.name]
    }, {
      cbac: 'operation_purchase_document',
      isWrite: true
    }))

    .state('main.business.purchaseorderDocumentsUpdate', resolver('purchase-order-document/:id/update/:docId', updateDocumentTemplate, 'PurchaseOrderUpdateDocumentContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [supplierDocumentDetail]
    }, {
      cbac: 'operation_purchase_document',
      isWrite: true
    }))

    .state('main.business.suppliersRelatedOrders', resolver('related-Order/:supplierId', relatedOrderTemplate, 'RelatedOrderContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [relatedOrder]
    }, {
      cbac: 'operation_supplier_related_orders'
    }))

    // TODO: next release
    .state('main.business.suppliersAutoOrders', resolver('auto-Order/:supplierId', autoOrderListTemplate, 'AutoOrderContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [autoRelatedOrder, purchaseOrders, supplierDetails, relatedOrder]
    }))

    .state('main.business.purchaseorderManageItems', resolver('manage-Items/:id', manageItemsTemplate, 'ManageItemsContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [receivePO, poReturn, receiveSku, purchaseOrder]
    }, {
      cbac: 'operation_purchase_order_manage_items'
    }))

    .state('main.business.purchaseorderPublish', resolver('publish-Items/:id', publishItemsTemplate, 'PublishItemsContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [publishItem, purchaseOrder]
    }, {
      cbac: 'operation_purchase_order_publish_items'
    }))

    .state('main.business.purchaseorderPaymentpo', resolver('payment-order/:id', paymentPOTemplate, 'PaymentPOContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [paymentList, paymentDetails, purchaseOrder]
    }, {
      cbac: 'operation_purchase_order_payments'
    }))

    .state('main.business.purchaseorderSubmitpo', resolver('submit-po/:id', submitPoTemplate, 'SubmitPOContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [purchaseOrder, fetchEmailTemplate, PO_INVOICE_TEMPLATE_LIST]
    }, {
      cbac: 'operation_purchase_order_submit'
    }))

    .state('main.business.inventoryCurrentstock', resolver('stock-review', currentStockReviewTemplate, 'CurrentStockReviewContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      // preData: [INVENTORY_VIEW, SCRAPED_VIEW, STOCK_VIEW, PRODUCT_SERIAL_VIEW]
    }, {
      cbac: 'operation_inventory_stock_review'
    }))

    .state('main.business.fulfillmentServiceorderview', resolver('active-full-order-view', fullOrderViewTemplate, 'ActiveFullOrderViewContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [FULL_ORDER_VIEW]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.fulfillmentServiceorderviewPipeline', resolver('active-full-order-pipeline-view', fullOrderPipelineViewTemplate, 'ActiveFullOrderPipelineViewContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [FULL_ORDER_VIEW, orderPipelineSettings],
      loadOnDemand: [resolverProvider.loadAutoScroll, resolverProvider.loadClipboard]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.fulfillmentServiceorderviewProductDashboard', resolver('service-cart-dashboard/:quoteId/:locationId/:serviceId?pipeline', JobOrderingCartTemplate, 'JobOrderingCartController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [JOBS_PRODUCT_VIEW, CART_TOTAL, STOCK_VIEW_DATA_PRODUCT, DELIVERY_USAGE_PRODUCT, QUOTE_DETAIL, FULFILLMENT_TABS_COUNT_PRODUCT, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow'
    }))

    .state('main.business.fulfillmentServiceorderviewProductPackageDetailAllFulfill', resolver('jobs-product-package-detail-all-fulfill/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', PackageDetailAllFulfillmentTemplate, 'PackageDetailAllFulfillmentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow',
      isWrite: true
    }))

    .state('main.business.fulfillmentServiceorderviewWoAssignments', resolver('manage-workorder/:quoteId/:serviceId', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_my_assignments_work_order'
    }))

    .state('main.business.fulfillmentItemorderview', resolver('active-full-order-item-view', fullOrderItemViewTemplate, 'ActiveFullOrderItemViewController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [ITEM_ORDER_VIEW]
    }, {
      cbac: 'operation_fulfillment'
    }))

    .state('main.business.fulfillmentItemorderviewProductDashboard', resolver('item-cart-dashboard/:quoteId/:locationId/:serviceId/', JobOrderingCartTemplate, 'JobOrderingCartController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [JOBS_PRODUCT_VIEW, CART_TOTAL, STOCK_VIEW_DATA_PRODUCT, DELIVERY_USAGE_PRODUCT, QUOTE_DETAIL, FULFILLMENT_TABS_COUNT_PRODUCT, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow'
    }))

    .state('main.business.fulfillmentItemorderviewProductOrderingCartAdd', resolver('product-ordering-cart/:quoteId/:serviceId/:productId/:itemId/:lineItemId?pipeline', ProductFulfillmentTemplate, 'RegularFulfillmentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [JOBS_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow',
      isWrite: true
    }))

    .state('main.business.fulfillmentItemorderviewMaterialOrderingCartAdd', resolver('material-ordering-cart/:quoteId/:serviceId/:productId/:itemId/:lineItemId?pipeline', ProductFulfillmentTemplate, 'RegularFulfillmentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [JOBS_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow',
      isWrite: true
    }))

    .state('main.business.fulfillmentItemorderviewProductBundleOrderingCartAdd', resolver('product-bundle-ordering-cart/:quoteId/:serviceId/:slugId/:productId/:itemId/:lineItemId?pipeline', BundleProductFulfillmentTemplate, 'BundleProductFulfillmentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [JOBS_BUNDLE_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow',
      isWrite: true
    }))

    .state('main.business.fulfillmentItemorderviewProductPackageOrderingCart', resolver('product-package-ordering-cart/:quoteId/:serviceId/:slugId/:catalogId/:productId/:itemId/:lineItemId?pipeline', PackageFulfillmentTemplate, 'PackageFulfillmentController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [JOBS_PACKAGE_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'operation_fulfillment_allow',
      isWrite: true
    }))

    // Inventory Product update summary view
    .state('main.business.inventoryProductsBulkProductImport', resolver('inventory-bulk-product-update', inventoryImportProductUpdateTemplate, 'InventoryImportBulkProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkProductImport', resolver('inventory-bulk-material-update', inventoryImportProductUpdateTemplate, 'InventoryImportBulkProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkFullSummary', resolver('inventory-update-product-summary', inventoryImportProductBulkUpdateFullSummaryTemplate, 'InventoryImportBulkProductUpdateFullSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryImportProductUpdate]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkFullSummary', resolver('inventory-update-material-summary', inventoryImportProductBulkUpdateFullSummaryTemplate, 'InventoryImportBulkProductUpdateFullSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryImportMaterialUpdate]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkProductErrorSummary', resolver('inventory-product-update-error', inventoryImportBulkProductSummaryTemplate, 'InventoryImportBulkProductSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkProductUpdateErrorDataSummary]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkProductErrorSummary', resolver('inventory-material-update-error', inventoryImportBulkProductSummaryTemplate, 'InventoryImportBulkProductSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkMaterialUpdateErrorDataSummary]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkProductSuccessSummary', resolver('inventory-product-update-success', inventoryImportBulkProductSummaryTemplate, 'InventoryImportBulkProductSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkProductUpdateSuccessDataSummary]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkProductSuccessSummary', resolver('inventory-material-update-success', inventoryImportBulkProductSummaryTemplate, 'InventoryImportBulkProductSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkMaterialUpdateSuccessDataSummary]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkProductWarningSummary', resolver('inventory-product-update-warning', inventoryImportBulkProductSummaryTemplate, 'InventoryImportBulkProductSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkProductUpdateWarningDataSummary]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkProductWarningSummary', resolver('inventory-material-update-warning', inventoryImportBulkProductSummaryTemplate, 'InventoryImportBulkProductSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkMaterialUpdateWarningDataSummary]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    // Inventory Update Summary View
    .state('main.business.inventoryProductsWelcomeSkuBulk', resolver('welcome-bulk-product-sku-update', welcomeSKUUpdateTemplate, 'WelcomeSKUUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsWelcomeSkuBulk', resolver('welcome-bulk-material-sku-update', welcomeSKUUpdateTemplate, 'WelcomeSKUUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryProductsSkuBulkImport', resolver('inventory-bulk-sku-product-update', inventoryImportBulkUpdateTemplate, 'InventoryImportBulkUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsSkuBulkImport', resolver('inventory-bulk-sku-material-update', inventoryImportBulkUpdateTemplate, 'InventoryImportBulkUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsSkuBulkImportSummary', resolver('inventory-sku-update-product-summary', inventoryImportBulkUpdateSummaryTemplate, 'ShowImportSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryImportPriceProduct]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsSkuBulkImportSummary', resolver('inventory-sku-update-material-summary', inventoryImportBulkUpdateSummaryTemplate, 'ShowImportSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryImportPriceMaterial]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkSkuErrorSummary', resolver('inventory-sku-product-update-error', inventoryImportBulkSKUSummaryTemplate, 'InventoryImportBulkSKUSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkSkuErrorDataSummaryProduct]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkSkuErrorSummary', resolver('inventory-sku-material-update-error', inventoryImportBulkSKUSummaryTemplate, 'InventoryImportBulkSKUSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkSkuErrorDataSummaryMaterial]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkSkuSuccessSummary', resolver('inventory-sku-product-update-success', inventoryImportBulkSKUSummaryTemplate, 'InventoryImportBulkSKUSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkSkuSuccessDataSummaryProduct]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkSkuSuccessSummary', resolver('inventory-sku-material-update-success', inventoryImportBulkSKUSummaryTemplate, 'InventoryImportBulkSKUSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkSkuSuccessDataSummaryMaterial]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsBulkSkuWarningSummary', resolver('inventory-sku-product-update-warning', inventoryImportBulkSKUSummaryTemplate, 'InventoryImportBulkSKUSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkSkuWarningDataSummaryProduct]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsBulkSkuWarningSummary', resolver('inventory-sku-material-update-warning', inventoryImportBulkSKUSummaryTemplate, 'InventoryImportBulkSKUSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryBulkSkuWarningDataSummaryMaterial]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    // Inventory Update Summary View

    // START : Inventory Import code

    .state('main.business.inventoryProductsUpWelcome', resolver('welcome-product-update', welcomeProductUpdateTemplate, 'WelcomeProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsUpWelcome', resolver('welcome-material-update', welcomeProductUpdateTemplate, 'WelcomeProductUpdateController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryProductsWelcomeImport', resolver('welcome-product-import', welcomeImportTemplate, 'WelcomeImportContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsWelcomeImport', resolver('welcome-material-import', welcomeImportTemplate, 'WelcomeImportContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsImportInventory', resolver('import-product-inventory', uploadinginventoryTemplate, 'UploadinginventoryContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [attributePoliciesProduct, isInventoryImportDataAvailableProduct]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsImportInventory', resolver('import-material-inventory', uploadinginventoryTemplate, 'UploadinginventoryContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [attributePoliciesMaterial, isInventoryImportDataAvailableMaterial]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsImportSummary', resolver('import-product-summary', summaryImportTemplate, 'SummaryImportContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryProductImport]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsImportSummary', resolver('import-material-summary', summaryImportTemplate, 'SummaryImportContoller', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [getInventoryMaterialImport]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsSuccessRecords', resolver('product-success-records', inventoryImportAllRecordsSummaryTemplate, 'InventoryImportAllRecordsSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [isValidRowProductImport]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsSuccessRecords', resolver('material-success-records', inventoryImportAllRecordsSummaryTemplate, 'InventoryImportAllRecordsSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [isValidRowMaterialImport]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsErrorRecords', resolver('product-error-records', inventoryImportAllRecordsSummaryTemplate, 'InventoryImportAllRecordsSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [hasErrorsProductImport]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsErrorRecords', resolver('material-error-records', inventoryImportAllRecordsSummaryTemplate, 'InventoryImportAllRecordsSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [hasErrorsMaterialImport]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    .state('main.business.inventoryProductsWarningsRecords', resolver('product-warning-records', inventoryImportAllRecordsSummaryTemplate, 'InventoryImportAllRecordsSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [hasWarningsProductImport]
    }, {
      cbac: 'operation_inventory_products',
      isWrite: true
    }))

    .state('main.business.inventoryMaterialsWarningsRecords', resolver('material-warning-records', inventoryImportAllRecordsSummaryTemplate, 'InventoryImportAllRecordsSummaryController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [hasWarningsMaterialImport]
    }, {
      cbac: 'operation_inventory_materials',
      isWrite: true
    }))

    // END : Inventory Import code

    .state('main.business.inventoryCatalogingAttachedServiceDetails', resolver('attached-service-details/:id', attachedServiceDetailTempate, 'attachedServiceDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [showService]
    }, {
      cbac: 'operation_inventory_catalogs'
    }))

    .state('main.business.operationsPayin', resolver('allpayment-transactions', payinTempate, 'PayinController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [allTransaction, regions]
    }, {
      cbac: 'operation_financial_pay_in'
    }))

    .state('main.business.operationsPayout', resolver('po-payments', payOutTempate, 'PayOutController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [payOut, regions]
    }, {
      cbac: 'operation_financial_pay_out'
    }))

    .state('main.business.operationsQuickbook', resolver('quickbook-transactions', quickbookTransactionTemplate, 'QuickbookTransactionController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [accountListQB, ACCOUNT_ISSUES]
    }, {
      cbac: 'operation_financial_quick_book'
    }))
    .state('main.business.warranty', resolver('warranty-tracking', warrantyTrackingListTemplate, 'WarrantyTrackingListController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [WARRANTY_LIST]
    }, {
      cbac: 'operation_financial_quick_book'
    }))
    .state('main.business.warrantyDetail', resolver('warranty-tracking-detail/:id', warrantyTrackingDetailTemplate, 'WarrantyTrackingDetailController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: [warrantyTrackingDetail],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'operation_financial_quick_book'
    }))
    .state('main.business.warrantyWorkboard', resolver('warranty-workboard', warrantyWorkboardTemplate, 'WarrantyWorkboardController', {
      lazyModule: [businessResolverProvider.loadBusinessModule],
      preData: []
    }, {
      cbac: 'operation_financial_quick_book'
    }));

}