// import businessFrameTemplate from './business-frame/business-frame.html';
import customerFrameTemplate from './customer-frame/customer-frame.html';
import customerListTemplate from './customers/customerList.html';
// import customerDashboardTemplate from './customers/dashboard/customer-dashboard.html';
import customerDetailTemplate from './customers/customer-details/customer-details.html';
import customerNurturingTemplate from './customers/nurturing/customer-nurturing.html';
import customerAssociatesTemplate from './customers/associates/customer-associates.html';
import customerHistoryTemplate from './customers/history/customer-history.html';
import CustomersProductListTemplate from './customers/products/customer-product-list.html';
import CustomerCommunicateTemplate from './customers/communicate/customer-communicate.html';
import OpportunitiesCommunicateTemplate from './opportunity/communicate/opportunity-communicate.html';
import EditContactTemplate from '../quotes/quote-cycle/contact/editContact.html';
import editCustomerProfile from './customers/customer-profile/editCustomersProfile.html';
import customerImportTemplate from './customers/customer-import/customerImport.html';
import customerUploadListTemplate from './customers/customer-import/customerUploadList.html';
import opportunityBoardsTemplate from './opportunity/opportunity-boards.html';
import opportunityDashboardTemplate from '../../components/dashboard-template/opportunity-dashboard.html';
import opportunitiesPipelineTemplate from './opportunity/pipeline/opportunity-pipeline.html';
import addCardTemplate from './opportunity/addCard/addCard.html';
import cardDetailTemplate from './opportunity/card-detail/card-detail.html';
import cardHistoryTemplate from './opportunity/history/card-history.html';
import opportunityListTemplate from './opportunity/list/opportunity-list.html';
import conversationTemplate from '../../components/notes/notes.html';
import actionListTemplate from '../../components/actionItem/action.html';
import notesTemplatesTemplate from '../../components/notes/notes-templates.html';
import addNotesTemplatesTemplate from '../../components/notes/add-notes-template.html';
import editCustomerTagsTemplate from './customers/customer-tag/editCustomerTag.html';
import segmentationTemplate from './segmentation/grid/segmentation-grid.html';
import segmentationListTemplate from './segmentation/list/segmentation-list.html';
import segmentationUpdateTemplate from './segmentation/update/update-segmentation.html';
import segmentationDetailTemplate from './segmentation/view/segmentation-view.html';
import segmentationManageTemplate from './segmentation/manage/segmentation-manage.html';
import dashboardListTemplate from '../../components/dashboard-template/dashboardList.html';
import businessCardTemplate from './customers/business-card-scanner/business-card-scanner.html';

import manageIndicatorTemplate from '../../components/dashboard-template/manageIndicator.html'
import addReportBlockTemplate from '../../components/dashboard-template/addReportBlock.html';

import leadgenerationTemplate from './lead-generation/leadGeneration-search.html';
import recordsSearchTemplate from './lead-generation/records-search/recordsSearch.html';
import smartDisplayListTemplate from './lead-generation/smart-display/smart-display-list.html';
import updateSmartDisplayTemplate from './lead-generation/smart-display/update-smart-display/update-smart-display.html';
import welcomeCustomerTemplate from './lead-generation/smart-display/welcome-customer/welcome-customer.html';

import campaignsTemplate from './campaigns/campaigns.html';
import designsTemplate from './nurturing/designs/designs.html';
import categoriesTemplate from './nurturing/designs/categories/categories.html';
import nurturingNoAccessTemplate from './nurturing/designs/nurturing-no-access/nurturing-no-access.html';

import addSegmentedConversationTemplate from './channels/add-segmented-conversation/add-segmented-conversation.html';
import segmentedConversationsListTemplate from './channels/segmented-conversations-list/segmented-conversations-list.html';
import segmentedConversationsTemplate from './channels/segmented-conversations/segmented-conversations.html';
import segmentedConversationCustomersTemplate from './channels/segmented-conversation-customers/segmented-conversation-customers.html';
import segmentedReportsTemplate from './channels/segmented-conversations-list/segment-report/segment-report.html';
import companyListTemplate from './companies/companiesList.html';
import updateCompanyTemplate from './companies/update-company/updateCompany.html';
import companyProfileTemplate from './companies/company-profile/companyProfile.html';
import companyAssociateTemplate from './companies/company-associate/companyAssociate.html';
import companyHistoryTemplate from './companies/company-history/companyHistory.html';
import campaignCategoryTemplate from './campaigns/campaign-category/campaignCategory.html';
import updateCampaignTemplate from './campaigns/update-campaign/updateCampaign.html';
import campaignDetailTemplate from './campaigns/campaign-detail/campaignDetail.html';
import updateDripTemplate from './campaigns/campaign-detail/update-drip/updateDrip.html';
import campaignDripListTemplate from './campaigns/campaign-detail/campaign-drip-list/campaignDripList.html';
import campaignDripDetailTemplate from './campaigns/campaign-detail/campaign-drip-list/campaign-drip-detail/campaignDripDetailTemplate.html';
import unsubscriberFormTemplate from './campaigns/unsubscriber-form/unsubscriberForm.html';
import campaignSubscriberListTemplate from './campaigns/campaign-subscriber-list/campaignSubscriberList.html';
import campaignReportTemplate from './campaigns/campaign-report/campaignReport.html';
import customerDocumentListTemplate from './customers/associate-documents/customerDocumentList.html'
import updateDocumentTemplate from './../../components/associate-documents/updateDocumentTemplate.html';
import cardDocumentListTemplate from './opportunity/associate-documents/cardDocumentList.html';
import documentDetailTemplate from './../../components/associate-documents/documentDetailTemplate.html';
import welcomeCustomerImportTemplate from './customers/customer-import/welcome-customer-import.html';
import companyAddressTemplate from './companies/company-address/companyAddress.html';

import diagramTemplate from './../../components/diagram/diagram.html';
import diagramListTemplate from './../../components/diagram/diagram-list/diagram-list.html';
import manageDrawingsTemplate from './../../components/diagram/manage-templates/manage-templates.html';

import editProposalTemplate from './../../components/proposal/edit-proposal.html';

import {
  customerHistory,
  CUSTOMER_LIST,
  CUSTOMER_PROFILE,
  CUSTOMER_DETAIL,
  CUSTOMER_DETAIL_SEGMENTS,
  CUSTOMER_DETAIL_DRAFT,
  boards,
  boardCategories,
  priorityStatus,
  prioritySetup,
  ageVisuals,
  amountVisuals,
  oppPipelineSettings,
  OPPORTUNITIES_LIST,
  cardDetail,
  boardDetail,
  labels,
  cards,
  OPPORTUNITY_HISTORY,
  customerCommunicate,
  customerCommunicationList,
  customerEmailTemplates,
  opportunityCommunicate,
  opportunityEmailTemplates,
  CUSTOMER_TAGS,
  SYSTEM_TAGS,
  CUSTOMER_CARDS,
  ASSOCIATE_MEMBERS,
  ASSOCIATE_ACCOUNTS,
  ASSOCIATE_NURTURING,
  CONVERSATION_NAVIGATION,
  CONVERSATION_NAVIGATION_OPPORTUNITIES,
  SEGMENTATION_LIST,
  SEGMENTATION,
  products,
  regions,
  exposeKeys,
  DYNAMIC_CUSTOMERS,
  ADDED_CUSTOMERS,
  RESTRICTED_CUSTOMERS,
  SEGMENTATION_CUSTOMERS_SIDENAV,
  segmentationHistory,
  OPP_ACTION_LIST,
  CUSTOMER_ACTION_LIST,
  CUSTOM_FORM_CUSTOMER,
  CUSTOM_FORM_QUOTE,
  OPP_CARD_CUSTOM_FORM,
  ACTION_LIST_COUNT,
  CUSTOMER_ACTION_LIST_COUNT,
  RESOURCE_LIST_F,
  QUOTE_DETAIL,
  CUSTOMER_SERVICE_HISTORY,
  SALES_ORGANIZATION,
  ASSIGNED_DASHBOARD,
  DASHBOARD_LIST,
  BLOCK_DETAIL,
  SALES_AGENT_LIST,
  leadStats,
  SMART_CARDS,
  SMART_CARD,
  BUSINESSSEARCH_LIST,
  BUSINESSSEARCH_DETAILS,
  RECORDSFROM_SEARCH,
  SALESAGENT,
  CUSTOMER_ASSIGNED_DASHBOARD,
  CUSTOMER_DASHBOARD_LIST,
  DESIGNS_DRAFT,
  // DESIGNS_FREE,
  DESIGNS_PREMIUM,
  CATEGORIES,
  SYSTEM_CATEGORIES,
  ALL_ORGANIZATION,
  entities,
  CUSTOMER_HISTORY,
  OPPORTUNITY_CARD_COMMUNICATION,
  SEGMENTATION_FOR_CONVERSATION_LIST,
  SEGMENTED_CONVERSATION_LIST,
  SEGMENTED_CONVERSATION,
  SEGMENTED_CONVERSATION_NAVIGATION,
  SEGMENTED_CONVERSATION_CUSTOMER_LIST,
  SEGMENTED_CONVERSATION_REPORT_POST,
  SEGMENTED_CONVERSATION_REPORT_CUSTOMER,
  SEGMENTED_CONVERSATION_REPORT_CHANNEL,
  COMPANIES_LIST,
  COMPANY_DETAIL,
  COMPANY_ASSOCIATES,
  COMPANY_NURTURING,
  CAMPAIGN_CATEGORY,
  ALL_CAMPAIGN_CATEGORY,
  CAMPAIGN_LIST,
  ALL_SEGMENTATION,
  CAMPAIGN_DETAIL,
  EXPOSED_KEYS_TOKEN,
  ALL_CAMPAIGN_DRIP,
  CAMPAIGN_DRIP_DETAIL,
  ALL_TEMPLATE_CATEGORIES,
  RESOURCE_LIST,
  CAMPAIGN_ACTION_LIST,
  CAMPAIGN_ACTION_LIST_COUNT,
  UNSUBSCRIBER_FORM_DETAIL,
  campaignSubscriptionList,
  CAMPAIGN_REPORT_PEFORMANCE,
  CAMPAIGN_REPORT_STATUS,
  CAMPAIGN_REPORT_ENAGAGEMENT,
  CAMPAIGN_REPORT_USAGE,
  CAMPAIGN_REPORT_SENT_ACTIVITES,
  customerDocument,
  documentDetail,
  cardDocument,
  customerAddressList,
  product,
  companyAddressList,
  CUSTOMER_PRODUCTS_LIST,
  CUSTOMER_MATERIALS_LIST
} from './customers.resolve.obj';

import {
  AVAILABLE_TEMPLATES,
  SAVED_TEMPLATES,
  DIAGRAM_CATEGORIES,
  DRAWING_TEMPLATE,
  DRAWING
} from '../../components/diagram/diagram.resolve.obj';

import {
  PROPOSAL
} from '../../components/proposal/proposal.resolve.obj';

import {
  serialNumberDetail
} from '../business/business.resolve.obj';

export default function customerRoute($urlRouterProvider, $stateProvider, customerResolverProvider, resolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.customers', resolver('customers', customerFrameTemplate, 'CustomerFrameController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: []
    }, {
      cbac: 'customers'
    }))

    .state('main.customers.addProposal', resolver('add-proposal/:docId', editProposalTemplate, 'EditProposalController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [PROPOSAL.name]
    }))

    // .state('main.customers.addProposalTemplates', resolver('add-proposal?product&at', editProposalTemplate, 'EditProposalController', {
    //   lazyModule: [customerResolverProvider.loadCustomerModule],
    //   preData: [PROPOSAL.name]
    // }))

    // .state('main.customers.updateProposalTemplates', resolver('update-proposal?product&proposalId&at', editProposalTemplate, 'EditProposalController', {
    //   lazyModule: [customerResolverProvider.loadCustomerModule],
    //   preData: [PROPOSAL.name]
    // }))

    .state('main.customers.updateProposal', resolver('update-proposal/:docId/:proposalId', editProposalTemplate, 'EditProposalController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [PROPOSAL]
    }))

    .state('main.customers.addCopyProposal', resolver('copy-proposal/:docId/:proposalId/:isClone', editProposalTemplate, 'EditProposalController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [PROPOSAL]
    }))

    .state('main.customers.addDiagram', resolver('add-diagram/:docId/:templateId/:fromState', diagramTemplate, 'DiagramController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.customers.updateDiagram', resolver('update-diagram/:docId/:drawingId', diagramTemplate, 'DiagramController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.customers.addCopyDiagram', resolver('copy-diagram/:docId/:drawingId/:isClone', diagramTemplate, 'DiagramController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.customers.editTemplate', resolver('edit-template/:templateId/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.customers.addCopyTemplate', resolver('copy-template/:templateId/:isClone/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.customers.addViewTemplate', resolver('view-template/:templateId/:isViewOnly', diagramTemplate, 'DiagramController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.customers.addTemplatesList', resolver('diagram-list/:docId/:fromState', diagramListTemplate, 'DiagramListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'customer_list'
    }))

    .state('main.customers.addManageTemplates', resolver('manage-templates', manageDrawingsTemplate, 'ManageTemplatesController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'customer_list'
    }))

    // .state('main.customers.customersDashboard', resolver('customers-dashboard', customerDashboardTemplate, 'CustomerDashboardController', {
    //   lazyModule: [customerResolverProvider.loadCustomerModule],
    //   loadOnDemand: [resolverProvider.loadNVD3]
    // }))

    .state('main.customers.customers', resolver('customer-list', customerListTemplate, 'CustomerListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CUSTOMER_LIST],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'customer_list'
    }))

    .state('main.customers.customersDetail', resolver('contact/:customerId', customerDetailTemplate, 'CustomerDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      // loadOnDemand: [resolverProvider.loadVideogular],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [CUSTOMER_DETAIL_SEGMENTS, CUSTOMER_CARDS, customerAddressList]
    }, {
      cbac: 'customer_list'
    }))

    .state('main.customers.customersDraftDetail', resolver('contact/:draft/:customerId', customerDetailTemplate, 'CustomerDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      // loadOnDemand: [resolverProvider.loadVideogular],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [CUSTOMER_DETAIL_DRAFT, CUSTOMER_CARDS, customerAddressList]
    }, {
      cbac: 'customer_list'
    }))

    .state('main.customers.customersNurturing', resolver('nurturing/:customerId', customerNurturingTemplate, 'CustomerNurturingController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CUSTOMER_DETAIL_SEGMENTS]
    }, {
      cbac: 'customer_list'
    }))

    .state('main.customers.customersAssociates', resolver('associates/:customerId', customerAssociatesTemplate, 'CustomersAssociatesController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [ASSOCIATE_MEMBERS, ASSOCIATE_NURTURING, ASSOCIATE_ACCOUNTS]
    }, {
      cbac: 'customer_associates'
    }))

    .state('main.customers.customersHistory', resolver('history/:customerId', customerHistoryTemplate, 'CustomerHistoryController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: []
    }, {
      cbac: 'customer_history'
    }))

    .state('main.customers.customersProducts', resolver('products/:customerId', CustomersProductListTemplate, 'CustomersProductListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CUSTOMER_PRODUCTS_LIST, CUSTOMER_DETAIL]
    }, {
      cbac: 'operation_inventory_products'
    }))

    .state('main.customers.customersMaterials', resolver('materials/:customerId', CustomersProductListTemplate, 'CustomersProductListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CUSTOMER_MATERIALS_LIST, CUSTOMER_DETAIL]
    }, {
      cbac: 'operation_inventory_materials'
    }))

    .state('main.customers.customersDocument', resolver('document-list/:customerId', customerDocumentListTemplate, 'CustomerDocumentListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [customerDocument, CUSTOMER_DETAIL]
    }, {
      cbac: 'customer_documents'
    }))

    .state('main.customers.customersDocumentDetail', resolver('document-list/:customerId/document/:id', documentDetailTemplate, 'CustomerDocumentDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [documentDetail]
    }, {
      cbac: 'customer_documents'
    }))

    .state('main.customers.customersDocumentAdd', resolver('document-list/:customerId/add', updateDocumentTemplate, 'UpdateCustomerDocumentController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'customer_documents',
      isWrite: true
    }))

    .state('main.customers.customersDocumentUpdate', resolver('document-list/:customerId/update/:id', updateDocumentTemplate, 'UpdateCustomerDocumentController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [documentDetail]
    }, {
      cbac: 'customer_documents',
      isWrite: true
    }))

    .state('main.customers.customersCommunicate', resolver('customers-communicate/:customerId', CustomerCommunicateTemplate, 'CustomerCommunicateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [customerCommunicate, customerEmailTemplates, entities, customerCommunicationList]
    }, {
      cbac: 'customer_communications'
    }))

    .state('main.customers.companies', resolver('company-list', companyListTemplate, 'CompaniesListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [COMPANIES_LIST]
    }, {
      cbac: 'customer_companies'
    }))

    .state('main.customers.companiesAssociates', resolver('company-associate/:id/:categoryId', companyAssociateTemplate, 'CompanyAssociateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [COMPANY_DETAIL, COMPANY_ASSOCIATES, COMPANY_NURTURING]
    }, {
      cbac: 'customer_company_associates'
    }))

    .state('main.customers.companiesAccount', resolver('company-account/:id/:categoryId', companyHistoryTemplate, 'CompanyHistoryController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [COMPANY_DETAIL]
    }, {
      cbac: 'customer_company_history'
    }))

    .state('main.customers.companiesAdresses', resolver('company-address/:id/:categoryId', companyAddressTemplate, 'CompanyAddressController', {
      lazyModule: [customerResolverProvider.loadCustomerModule, resolverProvider.loadAutoCompleteOverride],
      preData: [COMPANY_DETAIL, companyAddressList]
    }, {
      cbac: 'customer_company_history'
    }))

    .state('main.customers.companiesCprofile', resolver('company-profile/:id/:categoryId', companyProfileTemplate, 'CompanyProfileController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [COMPANY_DETAIL]
    }, {
      cbac: 'customer_companies'
    }))

    .state('main.customers.addCompany', resolver('add-company', updateCompanyTemplate, 'UpdateCompanyController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [COMPANY_DETAIL.name]
    }, {
      cbac: 'customer_companies',
      isWrite: true
    }))

    .state('main.customers.updateCompany', resolver('update-company/:id/:categoryId', updateCompanyTemplate, 'UpdateCompanyController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [COMPANY_DETAIL]
    }, {
      cbac: 'customer_companies',
      isWrite: true
    }))

    .state('main.customers.addContact', resolver('add-contact', EditContactTemplate, 'EditContactController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_CUSTOMER, CUSTOM_FORM_QUOTE.name, QUOTE_DETAIL.name]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.editContact', resolver('edit-contact/:customerId', EditContactTemplate, 'EditContactController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_CUSTOMER, CUSTOM_FORM_QUOTE.name, QUOTE_DETAIL.name]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.editDraftContact', resolver('edit-contact/:draft/:customerId', EditContactTemplate, 'EditContactController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_CUSTOMER, CUSTOM_FORM_QUOTE.name, QUOTE_DETAIL.name]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.editProfile', resolver('edit-profile/:customerId', editCustomerProfile, 'EditCustomerProfileController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CUSTOMER_PROFILE, SALES_ORGANIZATION]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.customerUploadw', resolver('customer-import-welcome', welcomeCustomerImportTemplate, 'WelcomeCustomerImportContoller', {
      lazyModule: [customerResolverProvider.loadCustomerModule]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.customerUpload', resolver('customer-upload', customerImportTemplate, 'UploadSettingController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SALES_ORGANIZATION]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.customerUploadList', resolver('customer-upload-list', customerUploadListTemplate, 'CustomerUploadController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.opportunities', resolver('opportunity-boards', opportunityBoardsTemplate, 'OpportunityBoardsController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [boardCategories]
    }, {
      cbac: 'customer_opportunity_boards'
    }))

    .state('main.customers.opportunitiesPipeline', resolver('opportunity-pipeline/:boardId/:locationId', opportunitiesPipelineTemplate, 'OpportunityPipelineController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadAutoScroll, resolverProvider.loadQuillEditor, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [cards, oppPipelineSettings, labels, ageVisuals, amountVisuals, priorityStatus, prioritySetup]
    }, {
      cbac: 'customer_opportunity_cards'
    }))

    .state('main.customers.opportunitiesList', resolver('opportunity-list/:boardId/:locationId', opportunityListTemplate, 'OpportunityListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [OPPORTUNITIES_LIST, priorityStatus, prioritySetup, ageVisuals, amountVisuals, labels]
    }, {
      cbac: 'customer_opportunity_cards'
    }))

    .state('main.customers.addCard', resolver('add-card/:boardId/:locationId?fill', addCardTemplate, 'AddCardController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [cardDetail.name, labels, priorityStatus, prioritySetup, OPP_CARD_CUSTOM_FORM]
    }, {
      cbac: 'customer_opportunity_cards',
      isWrite: true
    }))

    .state('main.customers.editCard', resolver('edit-card/:boardId/:cardId/:locationId', addCardTemplate, 'AddCardController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs],
      preData: [cardDetail, labels, priorityStatus, prioritySetup, OPP_CARD_CUSTOM_FORM]
    }, {
      cbac: 'customer_opportunity_cards',
      isWrite: true
    }))

    .state('main.customers.opportunitiesDetail', resolver('opportunity-detail/:boardId/:cardId/:locationId', cardDetailTemplate, 'CardDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [cardDetail, ageVisuals, amountVisuals, priorityStatus, prioritySetup]
    }, {
      cbac: 'customer_opportunity_cards'
    }))

    .state('main.customers.opportunitiesDocument', resolver('opportunity-detail/:boardId/:cardId/:locationId/document-list', cardDocumentListTemplate, 'CardDocumentListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [cardDetail, cardDocument]
    }, {
      cbac: 'customer_opportunity_documents'
    }))

    .state('main.customers.opportunitiesDocumentDetail', resolver('opportunity-detail/:boardId/:cardId/:locationId/:customerId/document/:id', documentDetailTemplate, 'CardDocumentDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [documentDetail]
    }, {
      cbac: 'customer_opportunity_documents'
    }))

    .state('main.customers.opportunitiesDocumentAdd', resolver('opportunity-detail/:boardId/:cardId/:locationId/document-list/add', updateDocumentTemplate, 'CardDocumentUpdateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'customer_opportunity_documents',
      isWrite: true
    }))

    .state('main.customers.opportunitiesDocumentUpdate', resolver('opportunity-detail/:boardId/:cardId/:locationId/document-list/update/:id', updateDocumentTemplate, 'CardDocumentUpdateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [documentDetail]
    }, {
      cbac: 'customer_opportunity_documents',
      isWrite: true
    }))

    .state('main.customers.opportunitiesHistory', resolver('opportunity-history/:boardId/:cardId/:locationId', cardHistoryTemplate, 'CardHistoryController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [cardDetail, OPPORTUNITY_HISTORY, OPPORTUNITY_CARD_COMMUNICATION]
    }, {
      cbac: 'customer_opportunity_history'
    }))

    .state('main.customers.opportunitiesCommunicate', resolver('opportunity-communicate/:boardId/:cardId/:locationId', OpportunitiesCommunicateTemplate, 'OpportunitiesCommunicateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [opportunityCommunicate, opportunityEmailTemplates]
    }, {
      cbac: 'customer_opportunity_communicate'
    }))

    .state('main.customers.opportunitiesActionList', resolver('action-list/:boardId/:cardId/:locationId', actionListTemplate, 'OppActionListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie],
      preData: [OPP_ACTION_LIST, cardDetail, ACTION_LIST_COUNT, ALL_ORGANIZATION]
    }, {
      cbac: 'customer_opportunity_action_items'
    }))

    .state('main.customers.opportunitiesNotes', resolver('notes/:boardId/:cardId/:locationId/:customerId', conversationTemplate, 'ConversationController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CONVERSATION_NAVIGATION_OPPORTUNITIES, entities]
    }, {
      cbac: 'customer_opportunity_action_items'
    }))

    .state('main.customers.campaigns', resolver('campaigns', campaignsTemplate, 'CampaignsController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [ALL_CAMPAIGN_CATEGORY, CAMPAIGN_LIST, RESOURCE_LIST]
    }, {
      cbac: 'customer_campaigns'
    }))

    .state('main.customers.campaignsCategory', resolver('campaigns-category', campaignCategoryTemplate, 'CampaignCategoryController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CAMPAIGN_CATEGORY]
    }, {
      cbac: 'customer_campaigns'
    }))

    .state('main.customers.campaignsAdd', resolver('add-campaign', updateCampaignTemplate, 'UpdateCampaignController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [ALL_CAMPAIGN_CATEGORY, CAMPAIGN_DETAIL.name, EXPOSED_KEYS_TOKEN]
    }, {
      cbac: 'customer_campaigns',
      isWrite: true
    }))

    .state('main.customers.campaignsUpdate', resolver('update-campaign/:id', updateCampaignTemplate, 'UpdateCampaignController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [ALL_CAMPAIGN_CATEGORY, CAMPAIGN_DETAIL, EXPOSED_KEYS_TOKEN]
    }, {
      cbac: 'customer_campaigns',
      isWrite: true
    }))

    .state('main.customers.campaignsDetail', resolver('campaign-detail/:id', campaignDetailTemplate, 'CampaignDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CAMPAIGN_DETAIL]
    }, {
      cbac: 'customer_campaigns'
    }))

    .state('main.customers.campaignsEmailReport', resolver('campaign-report/:id', campaignReportTemplate, 'CampaignReportController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNVD3],
      preData: [CAMPAIGN_REPORT_PEFORMANCE, CAMPAIGN_REPORT_STATUS, CAMPAIGN_REPORT_ENAGAGEMENT, CAMPAIGN_REPORT_USAGE, CAMPAIGN_REPORT_SENT_ACTIVITES]
    }, {
      cbac: 'customer_campaigns'
    }))

    .state('main.customers.campaignsSubscriberList', resolver('subscriber-list', campaignSubscriberListTemplate, 'CampaignSubscriberListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [campaignSubscriptionList]
    }, {
      cbac: 'customer_campaigns'
    }))

    .state('main.customers.campaignsUnsubscriberFormUpdate', resolver('unsubscriber-form', unsubscriberFormTemplate, 'UnsubscriberFormController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [UNSUBSCRIBER_FORM_DETAIL]
    }, {
      cbac: 'customer_campaigns',
      isWrite: true
    }))

    .state('main.customers.campaignsActionList', resolver('campaign-action/:id', actionListTemplate, 'CampaignActionListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie],
      preData: [CAMPAIGN_ACTION_LIST, CAMPAIGN_DETAIL, CAMPAIGN_ACTION_LIST_COUNT, ALL_ORGANIZATION]
    }, {
      cbac: 'customer_campaign_action_items'
    }))

    .state('main.customers.campaignsDrip', resolver('campaign-drip-list/:id', campaignDripListTemplate, 'CampaignDripListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CAMPAIGN_DETAIL, ALL_CAMPAIGN_DRIP]
    }, {
      cbac: 'customer_campaigns'
    }))

    .state('main.customers.campaignsDripDetail', resolver('campaign-detail/:id/drip-detail/:dripId', campaignDripDetailTemplate, 'CampaignDripDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CAMPAIGN_DETAIL, CAMPAIGN_DRIP_DETAIL]
    }, {
      cbac: 'customer_campaigns',
      isWrite: true
    }))

    .state('main.customers.campaignsDetailDripUpdate', resolver('campaign-detail/:id/update-drip/:dripId', updateDripTemplate, 'UpdateDripController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CAMPAIGN_DETAIL, EXPOSED_KEYS_TOKEN, DESIGNS_DRAFT, CAMPAIGN_DRIP_DETAIL, ALL_TEMPLATE_CATEGORIES]
    }, {
      cbac: 'customer_campaigns',
      isWrite: true
    }))

    .state('main.customers.campaignsDetailDripAdd', resolver('campaign-detail/:id/add-drip', updateDripTemplate, 'UpdateDripController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [CAMPAIGN_DETAIL, EXPOSED_KEYS_TOKEN, DESIGNS_DRAFT, ALL_TEMPLATE_CATEGORIES, CAMPAIGN_DRIP_DETAIL.name]
    }, {
      cbac: 'customer_campaigns',
      isWrite: true
    }))

    // .state('main.customers.campaignsDripDetail', resolver('campaign-detail/:id/drip-detail/:dripId', dripDetailTemplate, 'DripDetailController', {
    //   lazyModule: [customerResolverProvider.loadCustomerModule],
    //   loadOnDemand:[resolverProvider.loadQuillEditor],
    //   preData:[CAMPAIGN_DETAIL, EXPOSED_KEYS_TOKEN, DESIGNS_DRAFT]
    // }))

    .state('main.customers.nurturingDesigns', resolver('designs', designsTemplate, 'DesignsController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      // DESIGNS_FREE
      preData: [DESIGNS_DRAFT, DESIGNS_PREMIUM, CATEGORIES, SYSTEM_CATEGORIES]
    }, {
      cbac: 'customer_nurturing'
    }))

    .state('main.customers.nurturingDesignsCategories', resolver('categories', categoriesTemplate, 'CategoriesController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CATEGORIES]
    }, {
      cbac: 'customer_nurturing'
    }))

    // sales page - no cbac
    .state('main.customers.nurturingDesignsNoAccess', resolver('designs-no-access', nurturingNoAccessTemplate, 'NurturingNoAccessController', {
      lazyModule: [customerResolverProvider.loadCustomerModule]
    }))

    .state('main.customers.customersActionList', resolver('action-list/:customerId', actionListTemplate, 'CustomerActionListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie],
      preData: [CUSTOMER_ACTION_LIST, CUSTOMER_DETAIL, CUSTOMER_ACTION_LIST_COUNT, ALL_ORGANIZATION]
    }, {
      cbac: 'customer_opportunity_action_items'
    }))

    .state('main.customers.customersConversation', resolver('conversations/:customerId', conversationTemplate, 'ConversationController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadClipboard],
      preData: [CONVERSATION_NAVIGATION, entities]
    }, {
      cbac: 'customer_conversations'
    }))

    .state('main.customers.notesTemplates', resolver('conversations/notes-templates', notesTemplatesTemplate, 'NotesTemplatesController', {
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadClipboard],
      preData: [EXPOSED_KEYS_TOKEN]
    }))

    .state('main.customers.addNotesTemplates', resolver('conversations/add-note-template', addNotesTemplatesTemplate, 'AddNotesTemplatesController', {
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadClipboard],
      preData: [EXPOSED_KEYS_TOKEN]
    }))

    .state('main.customers.updateNotesTemplates', resolver('conversations/update-note-template/:id', addNotesTemplatesTemplate, 'AddNotesTemplatesController', {
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadClipboard],
      preData: [EXPOSED_KEYS_TOKEN]
    }))

    .state('main.customers.editCustomerTags', resolver('edit-customer-tags/:customerId', editCustomerTagsTemplate, 'EditCustomerTagController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [CUSTOMER_TAGS]
    }, {
      cbac: 'customer_list',
      isWrite: true
    }))

    .state('main.customers.segmentation', resolver('segmentations', segmentationTemplate, 'SegmentationController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION_LIST]
    }, {
      cbac: 'customer_segmentations'
    }))

    .state('main.customers.segmentationList', resolver('segmentation-list', segmentationListTemplate, 'SegmentationListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION_LIST]
    }, {
      cbac: 'customer_segmentations'
    }))

    .state('main.customers.segmentationDetail', resolver('view-segmentation/:id', segmentationDetailTemplate, 'segmentationDetailController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION, exposeKeys, segmentationHistory]
    }, {
      cbac: 'customer_segmentations'
    }))

    .state('main.customers.updateSegmentation', resolver('update-segmentation/:id', segmentationUpdateTemplate, 'SegmentationUpdateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION, exposeKeys]
    }, {
      cbac: 'customer_segmentations',
      isWrite: true
    }))

    .state('main.customers.addSegmentation', resolver('add-segmentation', segmentationUpdateTemplate, 'SegmentationUpdateController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION.name, exposeKeys]
    }, {
      cbac: 'customer_segmentations',
      isWrite: true
    }))

    .state('main.customers.segmentationManage', resolver('manage-segmentation/:id', segmentationManageTemplate, 'SegmentationManageController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION, DYNAMIC_CUSTOMERS, ADDED_CUSTOMERS, RESTRICTED_CUSTOMERS, SEGMENTATION_CUSTOMERS_SIDENAV]
    }, {
      cbac: 'customer_segmentation_manage'
    }))

    .state('main.customers.segmentationManageType', resolver('manage-segmentation/:id/:type', segmentationManageTemplate, 'SegmentationManageController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION, DYNAMIC_CUSTOMERS, ADDED_CUSTOMERS, RESTRICTED_CUSTOMERS, SEGMENTATION_CUSTOMERS_SIDENAV]
    }, {
      cbac: 'customer_segmentation_manage'
    }))

    // START : Opportunity dashboard
    .state('main.customers.dashboardList', resolver('dashboard-list', dashboardListTemplate, 'DashboardListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [DASHBOARD_LIST]
    }, {
      cbac: 'customer_dashboards'
    }))

    .state('main.customers.opportunitiesDashboard', resolver('opportunity-dashboard?boardId&locationId', opportunityDashboardTemplate, 'OpportunityDashboardController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNVD3, resolverProvider.loadDragDrop],
      preData: [ASSIGNED_DASHBOARD, SALESAGENT]
    }, {
      cbac: 'customer_opportunity_dashboards'
    }))

    .state('main.customers.addManageIndicator', resolver('manage-indicator/:dashboardId/:KBIBlockId', manageIndicatorTemplate, 'ManageIndicatorController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [BLOCK_DETAIL]
    }, {
      cbac: 'customer_dashboards',
      isWrite: true
    }))

    .state('main.customers.addReportBlock', resolver('report-block/:dashboardId', addReportBlockTemplate, 'AddReportBlockController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: []
    }, {
      cbac: 'customer_dashboards',
      isWrite: true
    }))

    // END : Opportunity dashboard
    // START : Customer dashboard
    .state('main.customers.customerDashboardList', resolver('customer-dashboard-list', dashboardListTemplate, 'CustomerDashboardListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [CUSTOMER_DASHBOARD_LIST]
    }, {
      cbac: 'customer_dashboards'
    }))

    .state('main.customers.customersDashboard', resolver('customer-dashboard', opportunityDashboardTemplate, 'CustomerDashboardController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNVD3, resolverProvider.loadDragDrop],
      preData: [CUSTOMER_ASSIGNED_DASHBOARD, SALESAGENT]
    }, {
      cbac: 'customer_dashboards'
    }))

    .state('main.customers.customerAddManageIndicator', resolver('customer-manage-indicator/:dashboardId/:KBIBlockId', manageIndicatorTemplate, 'CustomerManageIndicatorController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [BLOCK_DETAIL]
    }, {
      cbac: 'customer_dashboards',
      isWrite: true
    }))

    .state('main.customers.customerAddReportBlock', resolver('customer-report-block/:dashboardId', addReportBlockTemplate, 'CustomerAddReportBlockController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: []
    }, {
      cbac: 'customer_dashboards',
      isWrite: true
    }))

    // END : Customer dashboard
    .state('main.customers.leadgeneration', resolver('lead-generation', leadgenerationTemplate, 'LeadgenerationController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [leadStats, BUSINESSSEARCH_LIST]
    }, {
      cbac: 'customer_lead_generation'
    }))

    .state('main.customers.leadgenerationBusinesssearchdetails', resolver('business-search/:searchId', recordsSearchTemplate, 'RecordsSearchController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [BUSINESSSEARCH_DETAILS, regions, RECORDSFROM_SEARCH]
    }, {
      cbac: 'customer_lead_generation'
    }))

    .state('main.customers.leadgenerationSmartDisplay', resolver('smart-display-list', smartDisplayListTemplate, 'SmartDisplayListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [SMART_CARDS]
    }, {
      cbac: 'customer_lead_generation_smart_display'
    }))

    .state('main.customers.updateSmartCard', resolver('update-smart-display/:id', updateSmartDisplayTemplate, 'UpdateSmartDisplayController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor],
      preData: [SMART_CARD]
    }, {
      cbac: 'customer_lead_generation_smart_display',
      isWrite: true
    }))

    .state('main.customers.addSmartCard', resolver('add-smart-display', updateSmartDisplayTemplate, 'UpdateSmartDisplayController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadQuillEditor],
      preData: [SMART_CARD.name]
    }, {
      cbac: 'customer_lead_generation_smart_display',
      isWrite: true
    }))

    .state('main.customers.welcomeCustomerCreate', resolver('welcome-customer/:id', welcomeCustomerTemplate, 'WelcomeCustomerController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadVideogular, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride],
      preData: [SMART_CARD]
    }, {
      cbac: 'customer_lead_generation_smart_display',
      isWrite: true
    }))

    .state('main.customers.businessCardScanner', resolver('welcome-customer', businessCardTemplate, 'BusinessCardController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadVideogular, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride],
      preData: []
    }, {
      cbac: 'customer_lead_generation_smart_display',
      isWrite: true
    }))

    .state('main.customers.channels', resolver('segmented-conversations-list', segmentedConversationsListTemplate, 'SegmentedConversationsListController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTED_CONVERSATION_LIST]
    }, {
      cbac: 'customer_conversation_channels'
    }))

    .state('main.customers.addConversations', resolver('add-segmented-conversations', addSegmentedConversationTemplate, 'AddSegmentedConversationsController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTATION_FOR_CONVERSATION_LIST]
    }, {
      cbac: 'customer_conversation_channels',
      isWrite: true
    }))

    .state('main.customers.channelsPosts', resolver('segmented-conversations/:id/:segmentId', segmentedConversationsTemplate, 'SegmentedConversationsController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [SEGMENTED_CONVERSATION, SEGMENTED_CONVERSATION_NAVIGATION]
    }, {
      cbac: 'customer_conversation_channels'
    }))

    .state('main.customers.channelsReports', resolver('segmented-reports/:id/:segmentId', segmentedReportsTemplate, 'SegmentReportController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      loadOnDemand: [resolverProvider.loadNVD3],
      preData: [SEGMENTED_CONVERSATION, SEGMENTED_CONVERSATION_REPORT_POST, SEGMENTED_CONVERSATION_REPORT_CUSTOMER, SEGMENTED_CONVERSATION_REPORT_CHANNEL]
    }, {
      cbac: 'customer_conversation_channels'
    }))

    .state('main.customers.channelsCustomers', resolver('segmented-conversation-customers/:id/:segmentId', segmentedConversationCustomersTemplate, 'SegmentedConversationCustomersController', {
      lazyModule: [customerResolverProvider.loadCustomerModule],
      preData: [SEGMENTED_CONVERSATION, SEGMENTED_CONVERSATION_CUSTOMER_LIST],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'customer_conversation_channels'
    }));

}