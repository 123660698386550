// import LeftSideBarDirective from './left-sidenav/left-sidenav';
// import quoteHeader from './quote-header/quote-header';
// import editQuoteHeader from './edit-quote-header/edit-quote-header';

import footerMenuDirective from './footer-nav/footer-nav.directive';
import fileAttachment from './file-attachment-sidebar/file-attachment.directive';
import contactCard from './contact-card/contactCard.directive';
import listCheck from './list-check/list-check.directive';
import taggingMachine from "./tagging-machine/tagging-machine.directive";
import searchToolbar from './search-toolbar/search-toolbar.directive';
import searchToolbarSidenav from './search-toolbar-sidenav/search-toolbar-sidenav.directive';
import emptyState from './empty-state/emptyState.directive';
import updateInfo from './update-info/updateInfo.directive';
import customForm from './custom-form/custom-form.directive';
import customFormSignature from './custom-form-signature/custom-form-signature.directive';
import customFormFiles from './custom-form-files/custom-form-files.directive';
import freedomForm from './freedom-form/freedom-form.directive';
import futureEvent from './future-event/futureEvent.directive';
import bulkActionOperations from './bulk-action-operations/bulk-action-operations.directive';
import scheduleNowDirective from './schedule-sidebar/schedule.directive';
import communicationHistory from './communication-history/communication-history.directive';
import proTips from './pro-tips/pro-tips.directive';
import propAddress from './service-prop-address/propAddress.directive';
import assignedQuoteJob from './assigned-quote-job-sidenav/assigned-quote-job.directive';
import assignedOpportunity from './assigned-opportunity-sidenav/assigned-opportunity.directive';
import assignedServices from './assigned-services-sidenav/assigned-services.directive';
import assignedVisits from './assigned-visits-sidenav/assigned-visits.directive';
import companyCard from './company-card/companyCard.directive';
import businessCard from './business-contact-card/businessContactCard.directive';
import emailStatus from './email-status/email-status.directive';

import sidenavBootomMenu from './sidenav-bottom-menu/sidenav-bottom-menu.directive';
import updateAddress from './address-template/updateAddress.directive';
import detailAddress from './address-template/detailAddress.directive';
import companyList from './address-template/companyList.directive';
import contactAddressList from './address-template/contacAddresstList.directive';
import contactList from './address-template/contactList.directive';
import companyAddressList from './address-template/companyAddressList.directive';
import clockTimePerService from './clock-time-per-service/clock-time-per-service.directive';
import clockTimePerResource from './clock-time-per-resource/clock-time-per-resource.directive';

let componentModule = angular.module('index.module', []);

// LeftSideBarDirective(componentModule);
// quoteHeader(componentModule);
// editQuoteHeader(componentModule);

footerMenuDirective(componentModule);
fileAttachment(componentModule);
contactCard(componentModule);
listCheck(componentModule);
taggingMachine(componentModule);
searchToolbar(componentModule);
searchToolbarSidenav(componentModule);
emptyState(componentModule);
updateInfo(componentModule);
customForm(componentModule);
customFormSignature(componentModule);
customFormFiles(componentModule);
freedomForm(componentModule);
futureEvent(componentModule);
bulkActionOperations(componentModule);
scheduleNowDirective(componentModule);
communicationHistory(componentModule);
proTips(componentModule);
propAddress(componentModule);
assignedQuoteJob(componentModule);
assignedOpportunity(componentModule);
assignedServices(componentModule);
assignedVisits(componentModule);
companyCard(componentModule);
businessCard(componentModule);
emailStatus(componentModule);
updateAddress(componentModule);
detailAddress(componentModule);
companyList(componentModule);
contactAddressList(componentModule);
contactList(componentModule);
sidenavBootomMenu(componentModule);
companyAddressList(componentModule);
clockTimePerService(componentModule);
clockTimePerResource(componentModule);

export default componentModule;