import quoteFrameTemplate from '_quotes/quote-frame/quote-frame.html';
import quoteListTemplate from '_quotes/quoteList/quoteList.html';
import scheduledTaskDetailsTemplate from '_quotes/quoteList/scheduled-task-details/scheduled-task-details.html';
import guiderTemplate from '_quotes/quote-cycle/guider/guider.html';
import notesTemplate from '../../components/notes/notes.html';
import actionTemplate from '../../components/actionItem/action.html';
import editGuiderTemplate from '_quotes/quote-cycle/guider/editGuider.html';
import visitListTemplate from '_quotes/quote-cycle/visit/visit-list.html';
import visitDetailTemplate from '_quotes/quote-cycle/visit/visit-detail.html';
import editVisitTemplate from '_quotes/quote-cycle/visit/editVisit.html';

import contactTemplate from '_quotes/quote-cycle/contact/contact.html';
import EditContactTemplate from '_quotes/quote-cycle/contact/editContact.html';
import SummaryTemplate from '_quotes/quote-cycle/summary/summary.html';
import HistoryTemplate from '_quotes/quote-cycle/history/history.html';
import SubmitTemplate from '_quotes/quote-cycle/submit/submit.html';
import MapsTemplate from '_quotes/quoteList/quote-map/quote-map.html'

import PaymentTemplate from '_quotes/quote-cycle/payment/payment.html';

import quoteDashboardTemplate from '../../components/dashboard-template/opportunity-dashboard.html';
import dashboardListTemplate from '../../components/dashboard-template/dashboardList.html';
import addReportBlockTemplate from '../../components/dashboard-template/addReportBlock.html';
import manageIndicatorTemplate from '../../components/dashboard-template/manageIndicator.html';

import jobsSalesTemplate from './jobs-sales/jobs-sales.html';
import updateDocumentTemplate from './../../components/associate-documents/updateDocumentTemplate.html';
import jobDocumentListTemplate from '_quotes/quote-cycle/associate-documents/quoteDocumentList.html';
import documentDetailTemplate from './../../components/associate-documents/documentDetailTemplate.html';

import diagramTemplate from '../../components/diagram/diagram.html';
import diagramListTemplate from '../../components/diagram/diagram-list/diagram-list.html';
import manageDrawingsTemplate from '../../components/diagram/manage-templates/manage-templates.html';

import {
  JOB_LIST,
  GUIDER,
  CONVERSATION_NAVIGATION,
  entities,
  SALES_AGENT,
  VISIT_LIST,
  VISIT_DETAIL,
  QUOTE_DETAIL,
  CONTACT,
  SUMMARY,
  SUBMIT,
  FETCH_TEMPLATES,
  jobHistory,
  RESOURCE_LIST_F,
  QUOTE_MAP_LIST,
  JOB_MAP_LIST,
  QUOTE_ACTION_LIST,
  CUSTOM_FORM_CUSTOMER,
  CUSTOM_FORM_QUOTE,
  ACTION_LIST_COUNT,
  quoteInfo,
  quoteSeriveInfo,
  paymentInfo,
  autoCycles,
  recurringAutoCycles,
  quoteRecurringSeriveInfo,
  quotesPaymentTranscation,
  RESOURCE_LIST,
  SALES_ORGANIZATION,
  RECURRING_SERVICE_LIST,
  ASSIGNED_DASHBOARD,
  DASHBOARD_LIST,
  ASSIGNED_DASHBOARD_JOBS,
  DASHBOARD_LIST_JOBS,
  BLOCK_DETAIL,
  SALES_AGENT_LIST,
  ALL_ORGANIZATION,
  INVOICE_TEMPLATE_LIST,
  GET_ALL_RESOURCE_LIST_F,
  JOB_ACTION_LIST,
  SYSTEM_TAGS,
  QUOTE_TRANSACTION_HISTORY,
  RECURRING_SERVICE_LIST_INVOICE,
  jobDocument,
  documentDetail,
  QUOTE_FLAG
} from '_quotes/quote.resolve.obj';

import {
  AVAILABLE_TEMPLATES,
  SAVED_TEMPLATES,
  DIAGRAM_CATEGORIES,
  DRAWING_TEMPLATE,
  DRAWING
} from '../../components/diagram/diagram.resolve.obj';

export default function jobRoute($urlRouterProvider, $stateProvider, jobsResolverProvider, resolverProvider, serviceResolverProvider) {
  "ngInject";
  let resolver = resolverProvider.stateResolve;
  $stateProvider
    .state('main.jobs', resolver('jobs', quoteFrameTemplate, 'QuoteFrameController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie]
    }, {
      cbac: 'jobs'
    }))

    .state('main.jobs.addDiagram', resolver('add-diagram/:docId/:templateId/:fromState', diagramTemplate, 'DiagramController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.jobs.updateDiagram', resolver('update-diagram/:docId/:drawingId', diagramTemplate, 'DiagramController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.jobs.addCopyDiagram', resolver('copy-diagram/:docId/:drawingId/:isClone', diagramTemplate, 'DiagramController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.jobs.editTemplate', resolver('edit-template/:templateId/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.jobs.addCopyTemplate', resolver('copy-template/:templateId/:isClone/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.jobs.addViewTemplate', resolver('view-template/:templateId/:isViewOnly', diagramTemplate, 'DiagramController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.jobs.addTemplatesList', resolver('diagram-list/:docId/:fromState', diagramListTemplate, 'DiagramListController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'jobs'
    }))

    .state('main.jobs.addManageTemplates', resolver('manage-templates', manageDrawingsTemplate, 'ManageTemplatesController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'jobs'
    }))

    .state('main.jobsSales', resolver('upgrade-jobs', jobsSalesTemplate, 'JobsSalesController', {
      lazyModule: [jobsResolverProvider.loadJobsModule]
    }, {
      cbac: 'jobs'
    }))

    .state('main.jobList', resolver('jobs-list/?flow', quoteListTemplate, 'QuoteListController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie]
    }, {
      cbac: 'job_list'
    }))
    .state('main.scheduledJobServiceDetail', resolver('job-service-details/:tasks/:taskId/:quoteId', scheduledTaskDetailsTemplate, 'ScheduledTaskDetailsController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [QUOTE_DETAIL, ALL_ORGANIZATION]
    }, {
      cbac: 'job_service_list'
    }))
    .state('main.jobs.guider', resolver('jobs-guider/:quoteId', guiderTemplate, 'GuiderController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [GUIDER, SYSTEM_TAGS, QUOTE_TRANSACTION_HISTORY],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'job_guider'
    }))

    .state('main.jobs.document', resolver('job-document-list/:quoteId', jobDocumentListTemplate, 'JobDocumentListController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [jobDocument],
    }, {
      cbac: 'job_guider'
    }))

    .state('main.jobs.documentDetail', resolver('job-document-list/:quoteId/detail/:id', documentDetailTemplate, 'JobDocumentDetailController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [documentDetail],
    }, {
      cbac: 'job_guider'
    }))

    .state('main.jobs.documentAdd', resolver('job-document-list/:quoteId/add-document', updateDocumentTemplate, 'JobUpdateDocumentController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [documentDetail.name],
    }, {
      cbac: 'job_guider'
    }))

    .state('main.jobs.documentUpdate', resolver('job-document-list/:quoteId/update-document/:id', updateDocumentTemplate, 'JobUpdateDocumentController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [documentDetail],
    }, {
      cbac: 'job_guider'
    }))

    .state('main.jobs.notes', resolver('notes/:quoteId/:locationId/:customerId', notesTemplate, 'NotesController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [CONVERSATION_NAVIGATION, entities],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'job_guider'
    }))

    .state('main.jobs.action', resolver('jobs-action/:quoteId', actionTemplate, 'ActionController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [QUOTE_DETAIL, JOB_ACTION_LIST, ACTION_LIST_COUNT, ALL_ORGANIZATION, GET_ALL_RESOURCE_LIST_F],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie],
    }, {
      cbac: 'job_action_items'
    }))

    .state('main.jobs.editGuider', resolver('jobs-edit-guider/:quoteId', editGuiderTemplate, 'EditGuiderController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [SALES_AGENT, GUIDER, CUSTOM_FORM_QUOTE, QUOTE_DETAIL, SALES_ORGANIZATION]
    }, {
      cbac: 'job_guider',
      isWrite: true
    }))

    .state('main.jobs.createQuoteGuider', resolver('jobs-create-quote/?customerId1/:customerId2', editGuiderTemplate, 'EditGuiderController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [SALES_AGENT, GUIDER.name, CUSTOM_FORM_QUOTE.name, SALES_ORGANIZATION]
    }, {
      cbac: 'job_guider',
      isWrite: true
    }))

    .state('main.jobs.meetings', resolver('jobs-visit-list/:quoteId', visitListTemplate, 'VisitListController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [VISIT_LIST, QUOTE_DETAIL]
    }, {
      cbac: 'job_meetings'
    }))

    .state('main.jobs.visitDetail', resolver('jobs-visit-detail/:quoteId/:visitId', visitDetailTemplate, 'VisitDetailController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [VISIT_DETAIL, QUOTE_DETAIL, RESOURCE_LIST_F]
    }, {
      cbac: 'job_meetings'
    }))

    .state('main.jobs.editVisit', resolver('jobs-edit-visit/:quoteId/:visitId', editVisitTemplate, 'SiteVisitEditController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [VISIT_DETAIL, QUOTE_DETAIL, RESOURCE_LIST_F]
    }, {
      cbac: 'job_meetings',
      isWrite: true
    }))

    .state('main.jobs.addVisit', resolver('jobs-add-visit/:quoteId', editVisitTemplate, 'SiteVisitEditController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, VISIT_DETAIL.name, RESOURCE_LIST_F]
    }, {
      cbac: 'job_meetings',
      isWrite: true
    }))

    .state('main.jobs.contact', resolver('jobs-contact/:quoteId', contactTemplate, 'ContactController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [CONTACT, QUOTE_DETAIL]
    }, {
      cbac: 'job_contact'
    }))

    .state('main.jobs.editQuote', resolver('jobs-edit-quote/:quoteId?list', EditContactTemplate, 'EditContactController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride],
      preData: [CUSTOM_FORM_CUSTOMER.name, QUOTE_DETAIL]
    }, {
      cbac: 'jobs',
      isWrite: true
    }))

    .state('main.jobs.addQuote', resolver('jobs-add-quote', EditContactTemplate, 'EditContactController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride],
      preData: [CUSTOM_FORM_CUSTOMER.name, QUOTE_DETAIL.name]
    }, {
      cbac: 'jobs',
      isWrite: true
    }))

    .state('main.jobs.summary', resolver('jobs-summary/:quoteId', SummaryTemplate, 'SummaryController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [SUMMARY, QUOTE_DETAIL, RECURRING_SERVICE_LIST_INVOICE],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'job_summary'
    }))

    .state('main.jobs.history', resolver('jobs-history/:quoteId', HistoryTemplate, 'HistoryController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [jobHistory, QUOTE_DETAIL]
    }, {
      cbac: 'job_history'
    }))

    .state('main.jobListMap', resolver('jobs-maps', MapsTemplate, 'QuoteMapController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadNgMap],
      preData: [JOB_MAP_LIST]
    }, {
      cbac: 'job_maps'
    }))

    .state('main.jobs.submit', resolver('jobs-submit/:quoteId', SubmitTemplate, 'SubmitController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [SUBMIT, FETCH_TEMPLATES, QUOTE_DETAIL, INVOICE_TEMPLATE_LIST, QUOTE_FLAG]
    }, {
      cbac: 'job_submit'
    }))

    .state('main.jobs.payment', resolver('jobs-payment/:quoteId?list', PaymentTemplate, 'PaymentController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadAngularPayment, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [quoteRecurringSeriveInfo, quoteSeriveInfo, quoteInfo, paymentInfo, QUOTE_DETAIL, autoCycles, recurringAutoCycles, quotesPaymentTranscation]
    }, {
      cbac: 'job_payments'
    }))

    .state('main.jobs.editQuoteDashboard', resolver('jobs-dashboard', quoteDashboardTemplate, 'JobDashboardController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadNVD3, resolverProvider.loadDragDrop],
      preData: [ASSIGNED_DASHBOARD.name, ASSIGNED_DASHBOARD_JOBS, SALES_AGENT_LIST]
    }, {
      cbac: 'job_dashboards'
    }))

    .state('main.jobs.editDashboardList', resolver('jobs-dashboard-list', dashboardListTemplate, 'JobDashboardListController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [DASHBOARD_LIST.name, DASHBOARD_LIST_JOBS]
    }, {
      cbac: 'job_dashboards'
    }))

    .state('main.jobs.addManageIndicator', resolver('jobs-manage-indicator/:dashboardId/:KBIBlockId', manageIndicatorTemplate, 'JobManageIndicatorController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: [BLOCK_DETAIL]
    }, {
      cbac: 'job_dashboards',
      isWrite: true
    }))

    .state('main.jobs.addReportBlock', resolver('jobs-report-block/:dashboardId', addReportBlockTemplate, 'JobAddReportBlockController', {
      lazyModule: [jobsResolverProvider.loadJobsModule],
      preData: []
    }, {
      cbac: 'job_dashboards',
      isWrite: true
    }));
}