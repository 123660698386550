import confirmTemplate from './../../components/popup-view/confirm-dialog.html';

export default function(app) {
  app.factory('AddressService', AddressService);

  function AddressService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage, $rootScope, $mdSidenav, $mdComponentRegistry) {
    "ngInject";

    return {
      init: init,
      updateAddress: updateAddress,
      deletePopup: deletePopup
    }

    function init(vm) {
      vm.address = {
        address: {}
      };
    }

    function updateAddress(vm, type = 1) {
      console.log(vm);
      let data = {
        ...vm.address
      };

      let id = vm.address.id;

      if (vm.address.id) {
        delete data.id;
      }
      data[type == 1 ? 'customer_id' : 'company_id'] = (type == 1 ? parseInt(vm.customerId) : vm.id);

      dataServices.post({
        url: serverUrl.main + 'additionalAddressApi/address' + (id ? '/' + id : ''),
        method: id ? 'PUT' : 'POST',
        spinnerName: 'add-address-spinner',
        data: {
          data: angular.toJson(data),
          type: type
        }
      }).then(response => {
        if (response && response.data) {
          $mdSidenav('add-address').toggle();
          vm.myForm.$setPristine();
          vm.myForm.$setUntouched();
          vm.setFilters();
          vm.address = {
            address: {}
          };
        }
      });
    }

    function deletePopup(ev, vm, address, type = 1) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: DeleteAddressController,
        controllerAs: 'cd',
        templateUrl: confirmTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        locals: {
          parentVm: vm,
          address: address,
          type: type
        }
      });
    }

    function DeleteAddressController($scope, $mdDialog, $rootScope, $timeout, parentVm, address, type) {

      "ngInject";

      let cd = this;
      cd.parentVm = parentVm;
      cd.address = address;
      cd.type = type;
      cd.description = 'Deleting address will permanently remove the address from the system. Functions previously referenced this information will continue to retain them.';
      cd.question = 'Do you want to delete this address?';

      cd.cancel = cancel;
      cd.confirm = confirm;

      function cancel() {
        $mdDialog.cancel();
      }

      function confirm() {
        dataServices.delete({
          url: serverUrl.main + 'additionalAddressApi/address/' + cd.address.id,
          spinnerName: 'confirm-dialog',
          data: {
            type: cd.type
          }
        }).then(response => {
          if (response && response.data && response.data.result) {
            messages.simpleToast('Address removed successfully!');
            cd.cancel();
            cd.parentVm.setFilters();
          }
        });
      }

    }
  }
}