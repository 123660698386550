import propAddressTemplate from './prop-address.html'

export default function(app) {
  app.directive('propAddress', propAddress);

  function propAddress() {
    return {
      restrict: 'E',
      templateUrl: propAddressTemplate
    };
  }
}